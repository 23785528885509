import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { PageWrapper } from "../../components/shared/PageWrapper/PageWrapper";
import { galleryContent } from "./content/galleryContent";
import {
  CurrentImage,
  ImagesScrollWrapper,
  HugeScreenImagesWrapper,
  SmallScreenImagesWrapper,
} from "./GalleryStyle";

export const GalleryContent = () => {
  const { id } = useParams();
  const { title, currentMaxValue } = galleryContent.filter(
    (el) => el.id === Number(id)
  )[0];
  const array = Array.from(Array(currentMaxValue + 1).keys()).reverse();
  const [number, setNumber] = useState<number>(currentMaxValue);

  const [isScreenSmall, setIsScreenSmall] = useState<boolean>(
    window.innerWidth > 1240
  );

  useEffect(() => {
    const handleResize = () => {
      setIsScreenSmall(window.innerWidth > 1240);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const allImages = (
    <>
      {array.map((num) => (
        <img
          onClick={() => setNumber(num)}
          style={{
            cursor: "pointer",
            objectFit: "cover",
            content: `url(/gallery/${id}/${id}-${num}.JPG)`,
          }}
        />
      ))}
    </>
  );

  return (
    <PageWrapper title={`Галерея: ${title}`}>
      {isScreenSmall ? (
        <HugeScreenImagesWrapper>
          <CurrentImage>
            <img
              style={{
                content: `url(/gallery/${id}/${id}-${number}.JPG)`,
                objectFit: "cover",
              }}
            />
          </CurrentImage>
          <ImagesScrollWrapper>{allImages}</ImagesScrollWrapper>
        </HugeScreenImagesWrapper>
      ) : (
        <SmallScreenImagesWrapper>{allImages}</SmallScreenImagesWrapper>
      )}
    </PageWrapper>
  );
};
