import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { articlesContent } from "./content/articlesContent";
import { PageWrapper } from "../../components/shared/PageWrapper/PageWrapper";

export const ArticlesContent = () => {
  const { id } = useParams();
  const { title, content } = articlesContent.filter(
    (el) => el.id === Number(id)
  )[0];

  const [isImageSmall, setIsImageSmall] = useState<boolean>(
    window.innerWidth > 700
  );

  useEffect(() => {
    const handleResize = () => {
      setIsImageSmall(window.innerWidth > 700);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return <PageWrapper title={title}>{content(isImageSmall)}</PageWrapper>;
};
