import React from "react";
import { Typography } from "components/shared/Typography/Typography";
import { TypographyVariant } from "components/shared/Typography/TypographyTypes";
import {
  ButtonSvg,
  getButtonClassName,
  getButtonTypographyColor,
} from "lib/helpers/button";
import { ButtonType, ButtonSize, IButtonProps } from "./ButtonTypes";
import { ButtonInner, ButtonWrapper } from "./ButtonStyle";

export const Button: React.FC<IButtonProps> = ({
  title,
  variation,
  typographyVariant = TypographyVariant.BASIC_BOLD_TEXT,
  containerWidth,
  iconStart,
  iconEnd,
  type = ButtonType.FILLED,
  size = ButtonSize.M,
  isDisabled = false,
  isSubmit = false,
  onClick,
}) => {
  const buttonClassName = getButtonClassName({
    variation,
    title,
    isDisabled,
    type,
    size,
  });
  const buttonTypographyColor = getButtonTypographyColor({ variation });
  const buttonType = isSubmit ? "submit" : "button";

  return (
    <ButtonWrapper>
      <ButtonInner
        className={buttonClassName}
        type={buttonType}
        onClick={onClick}
        disabled={isDisabled}
      >
        {iconStart && <ButtonSvg icon={iconStart} />}
        {title && (
          <Typography
            title={title}
            variant={typographyVariant}
            color={buttonTypographyColor}
          />
        )}
        {iconEnd && <ButtonSvg icon={iconEnd} />}
      </ButtonInner>
    </ButtonWrapper>
  );
};
