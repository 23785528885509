import { createSelector, Selector } from "@reduxjs/toolkit";
import { IColor, IExtendTheme, ITheme } from "state/theme/themeTypes";

export const selectTheme: Selector<IExtendTheme, ITheme> = ({ theme }) => theme;

export const selectColorBackground: Selector<IColor, string> = (color) =>
  color.background;

export const selectColorBackgroundActive: Selector<
  IColor,
  string | undefined
> = (color) => color.backgroundActive;

export const selectColorBackgroundHover: Selector<
  IColor,
  string | undefined
> = (color) => color.backgroundHover;

export const selectColorText: Selector<IColor, string> = (color) => color.text;

export const selectColorTextActive: Selector<IColor, string | undefined> = (
  color
) => color.textActive;

export const selectColorTextHover: Selector<IColor, string | undefined> = (
  color
) => color.textHover;

// Items selectors

export const selectItemPrimary = createSelector(
  [selectTheme],
  (theme) => theme.item.primary
);

export const selectItemSecondary = createSelector(
  [selectTheme],
  (theme) => theme.item.secondary
);

export const selectItemCorrect = createSelector(
  [selectTheme],
  (theme) => theme.item.correct
);

export const selectItemDanger = createSelector(
  [selectTheme],
  (theme) => theme.item.danger
);

export const selectItemDisabled = createSelector(
  [selectTheme],
  (theme) => theme.item.disabled
);

// Primary Item selectors

export const getItemPrimaryBackground = createSelector(
  [selectItemPrimary],
  selectColorBackground
);

export const getItemPrimaryBackgroundActive = createSelector(
  [selectItemPrimary],
  selectColorBackgroundActive
);

export const getItemPrimaryBackgroundHover = createSelector(
  [selectItemPrimary],
  selectColorBackgroundHover
);

export const getItemPrimaryText = createSelector(
  [selectItemPrimary],
  selectColorText
);

export const getItemPrimaryTextActive = createSelector(
  [selectItemPrimary],
  selectColorTextActive
);

export const getItemPrimaryTextHover = createSelector(
  [selectItemPrimary],
  selectColorTextHover
);

// Secondary Item selectors

export const getItemSecondaryBackground = createSelector(
  [selectItemSecondary],
  selectColorBackground
);

export const getItemSecondaryBackgroundActive = createSelector(
  [selectItemSecondary],
  selectColorBackgroundActive
);

export const getItemSecondaryBackgroundHover = createSelector(
  [selectItemSecondary],
  selectColorBackgroundHover
);

export const getItemSecondaryText = createSelector(
  [selectItemSecondary],
  selectColorText
);

export const getItemSecondaryTextActive = createSelector(
  [selectItemSecondary],
  selectColorTextActive
);

export const getItemSecondaryTextHover = createSelector(
  [selectItemSecondary],
  selectColorTextHover
);

// Correct Item selectors

export const getItemCorrectBackground = createSelector(
  [selectItemCorrect],
  selectColorBackground
);

export const getItemCorrectBackgroundActive = createSelector(
  [selectItemCorrect],
  selectColorBackgroundActive
);

export const getItemCorrectBackgroundHover = createSelector(
  [selectItemCorrect],
  selectColorBackgroundHover
);

export const getItemCorrectText = createSelector(
  [selectItemCorrect],
  selectColorText
);

// Danger Item selectors

export const getItemDangerBackground = createSelector(
  [selectItemDanger],
  selectColorBackground
);

export const getItemDangerBackgroundActive = createSelector(
  [selectItemDanger],
  selectColorBackgroundActive
);

export const getItemDangerBackgroundHover = createSelector(
  [selectItemDanger],
  selectColorBackgroundHover
);

export const getItemDangerText = createSelector(
  [selectItemDanger],
  selectColorText
);

// Disabled Item selectors

export const getItemDisabledBackground = createSelector(
  [selectItemDisabled],
  selectColorBackground
);

export const getItemDisabledText = createSelector(
  [selectItemDisabled],
  selectColorText
);

// App selectors

export const getAppBackground = createSelector(
  [selectTheme],
  (theme) => theme.app.background
);

export const getAppPlaceholder = createSelector(
  [selectTheme],
  (theme) => theme.app.placeholder
);
