import React from "react";
import { IImageWithLabelProps } from "./ImageWithLabelTypes";
import { ImageWithLabelWrapper } from "./ImageWithLabelStyle";
import { Typography } from "../Typography/Typography";
import {
  TypographyColor,
  TypographyVariant,
} from "../Typography/TypographyTypes";

export const ImageWithLabel: React.FC<IImageWithLabelProps> = ({
  title,
  url,
  width,
}) => {
  return (
    <ImageWithLabelWrapper
      style={{ width: width || "auto", alignSelf: "center" }}
    >
      <img style={{ content: `url(${url})`, width: width || "auto" }} />
      <Typography
        title={title}
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
        color={TypographyColor.PRIMARY}
        align="center"
      />
    </ImageWithLabelWrapper>
  );
};
