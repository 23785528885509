export enum TypographyVariant {
  HEADER = "HEADER",
  BASIC_TEXT = "BASIC_TEXT",
  BASIC_BOLD_TEXT = "BASIC_BOLD_TEXT",
  BASIC_ITALIC_TEXT = "BASIC_ITALIC_TEXT",
  SMALL_TEXT = "SMALL_TEXT",
  SMALL_BOLD_TEXT = "SMALL_BOLD_TEXT",
}

export enum TypographyColor {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  CORRECT = "CORRECT",
  DANGER = "DANGER",
  DISABLED = "DISABLED",
}

export type TypographyAlign = "start" | "center" | "end" | "justify";

export interface ITypographyStateProps {
  title: string;
  variant?: TypographyVariant;
  color?: TypographyColor;
  className?: string;
  align?: TypographyAlign;
}

export interface ITypographyDispatchProps {}

export interface ITypographyProps
  extends ITypographyStateProps,
    ITypographyDispatchProps {}
