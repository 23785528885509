import { createSlice } from '@reduxjs/toolkit'
import { ITheme } from './themeTypes'
import { defaultStoryTheme } from './defaultTheme'

const initialState: ITheme = defaultStoryTheme

const slice = createSlice({
  name: 'theme',
  initialState,
  reducers: {},
})

export default slice.reducer
