import React, { useEffect, useState } from "react";
import { Typography } from "components/shared/Typography/Typography";
import {
  TypographyColor,
  TypographyVariant,
} from "components/shared/Typography/TypographyTypes";
import { ICardProps } from "./CardTypes";
import { CardImage, CardTitle, CardWrapper, ContentWrapper } from "./CardStyle";
import { Button } from "../Button/Button";
import { ButtonSize, ButtonVariation } from "../Button/ButtonTypes";

export const Card: React.FC<ICardProps> = ({
  title,
  content,
  imageURL,
  buttonText = "Читать подробнее",
  onClick,
}) => {
  const [isTextSmall, setIsTextSmall] = useState<boolean>(
    window.innerWidth < 600
  );

  useEffect(() => {
    const handleResize = () => {
      setIsTextSmall(window.innerWidth < 600);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <CardWrapper>
      {imageURL && (
        <CardImage
          style={{
            content: `url(${imageURL})`,
          }}
        />
      )}
      <ContentWrapper>
        <CardTitle>
          <Typography
            title={title}
            align="center"
            variant={
              isTextSmall
                ? TypographyVariant.SMALL_BOLD_TEXT
                : TypographyVariant.BASIC_BOLD_TEXT
            }
            color={TypographyColor.PRIMARY}
          />
        </CardTitle>
        {content && (
          <Typography
            title={content}
            align="justify"
            variant={
              isTextSmall
                ? TypographyVariant.SMALL_TEXT
                : TypographyVariant.BASIC_TEXT
            }
            color={TypographyColor.PRIMARY}
          />
        )}
        <Button
          title={buttonText}
          size={isTextSmall ? ButtonSize.S : ButtonSize.M}
          variation={ButtonVariation.PRIMARY}
          onClick={onClick}
          typographyVariant={
            isTextSmall
              ? TypographyVariant.SMALL_BOLD_TEXT
              : TypographyVariant.BASIC_BOLD_TEXT
          }
        />
      </ContentWrapper>
    </CardWrapper>
  );
};
