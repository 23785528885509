import styled from "styled-components";

export const HugeScreenImagesWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100vw;
  max-height: 80vh;
  gap: 10px;

  @media screen and (max-width: 2170px) {
    max-height: 70vh;
  }

  @media screen and (max-width: 1730px) {
    max-height: 60vh;
  }

  @media screen and (max-width: 1470px) {
    max-height: 50vh;
  }
`;

export const CurrentImage = styled.div`
  & img {
    width: 100%;
    max-height: 80vh;
    @media screen and (max-width: 2170px) {
      max-height: 70vh;
    }
    @media screen and (max-width: 1730px) {
      max-height: 60vh;
    }
    @media screen and (max-width: 1470px) {
      max-height: 50vh;
    }
  }
`;

export const ImagesScrollWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 15vw;
  max-width: 15vw;
  max-height: 100%;
  overflow-x: scroll;
  gap: 5px;

  & * {
    width: 100%;
  }
`;

export const GalleryWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  gap: 20px;
  padding-bottom: 30px;
  overflow-x: scroll;

  &.wrap {
    justify-content: space-between;
    padding-bottom: 0;
    flex-wrap: wrap;

    @media screen and (max-width: 1320px) {
      justify-content: center;
    }
  }
`;

export const Wrapper = styled.div`
  padding: 40px 140px 160px;
  &.no-wrap {
    padding: 0 140px;
    width: 100%;

    @media screen and (max-width: 1320px) {
      padding: 0 40px;
    }
  }
  @media screen and (max-width: 1320px) {
    padding: 40px 40px 160px;
  }
`;

export const SmallScreenImagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  & img {
    width: 100%;
  }
`;
