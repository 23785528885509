import { createGlobalStyle } from 'styled-components'
import { IExtendTheme } from 'state/theme/themeTypes'

export const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    background-color: ${({ theme }: IExtendTheme) => theme.app.background};
  }  
  body * {
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
  }
`
