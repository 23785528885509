import React from "react";
import { useNavigate } from "react-router-dom";
import { PageTitle } from "../../components/shared/PageTitle/PageTitle";
import { Card } from "../../components/shared/Card/Card";
import { articlesPath } from "../AppRoutes";
import { articlesContent } from "./content/articlesContent";
import { ArticlesWrapper, Wrapper } from "./ArticlesStyle";

export type ArticlesProps = {
  isPage?: boolean;
};

export const Articles = ({ isPage = false }: ArticlesProps) => {
  const navigate = useNavigate();

  return (
    <>
      <PageTitle title="СТАТЬИ" />
      <Wrapper className={isPage ? "" : "no-wrap"}>
        <ArticlesWrapper className={isPage ? "wrap" : ""}>
          {articlesContent.map((article) => {
            const { title, id, imageURL, preview } = article;
            return (
              <Card
                key={`article-${id}`}
                title={title}
                content={preview}
                imageURL={imageURL}
                onClick={() =>
                  navigate(isPage ? `${id}` : `${articlesPath}/${id}`)
                }
              />
            );
          })}
        </ArticlesWrapper>
      </Wrapper>
    </>
  );
};
