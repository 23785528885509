import React from "react";
import { Typography } from "components/shared/Typography/Typography";
import {
  TypographyColor,
  TypographyVariant,
} from "components/shared/Typography/TypographyTypes";
import { TabBar, TabTitleWrapper, TabWrapper } from "./TabStyle";
import { ITabProps } from "./TabTypes";
import { TabSvg } from "../../../lib/helpers/tab";

export const Tab: React.FC<ITabProps> = ({
  title,
  isActive,
  icon,
  onClick,
}) => {
  const typographyVariant = isActive
    ? TypographyVariant.BASIC_BOLD_TEXT
    : TypographyVariant.BASIC_TEXT;
  return (
    <TabWrapper className="tab" onClick={onClick}>
      <TabTitleWrapper>
        <Typography
          title={title}
          variant={typographyVariant}
          color={TypographyColor.PRIMARY}
        />
        {icon && <TabSvg icon={icon} />}
      </TabTitleWrapper>
      {isActive && <TabBar />}
    </TabWrapper>
  );
};
