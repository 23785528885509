import styled from "styled-components";
import { ReactComponent as LogoSvg } from "assets/images/logo-small.svg";
import { getAppBackground } from "selectors/theme";

export const HeaderWrapper = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 120px;
  padding: 0 140px;
  background-color: ${getAppBackground};
  z-index: 10;

  @media screen and (max-width: 1120px) {
    padding: 0 20px;
  }
`;

export const Logo = styled(LogoSvg)`
  height: 120px;
  min-width: 120px;
  @media screen and (max-width: 825px) {
    & image {
      height: 60px;
      width: 60px;
    }
    height: 80px;
    min-width: 80px;
  }
`;
