import React from "react";
import { ReactComponent as Add } from "assets/icons/add.svg";
import { ReactComponent as Cancel } from "assets/icons/cancel.svg";
import { ReactComponent as Earth } from "assets/icons/earth.svg";
import { ReactComponent as Edit } from "assets/icons/edit.svg";
import { ReactComponent as EyeClosed } from "assets/icons/eye-closed.svg";
import { ReactComponent as EyeOpened } from "assets/icons/eye-open.svg";
import { ReactComponent as Login } from "assets/icons/login.svg";
import { ReactComponent as Logout } from "assets/icons/logout.svg";
import { ReactComponent as Remove } from "assets/icons/remove.svg";
import { ReactComponent as Save } from "assets/icons/save.svg";
import {
  ButtonIcon,
  ButtonSize,
  ButtonType,
  ButtonVariation,
} from "components/shared/Button/ButtonTypes";
import { TypographyColor } from "components/shared/Typography/TypographyTypes";

interface ButtonIconProps {
  icon: ButtonIcon;
}

export const ButtonSvg: React.FC<ButtonIconProps> = ({ icon }) => {
  switch (icon) {
    case ButtonIcon.ADD: {
      return <Add />;
    }
    case ButtonIcon.CANCEL: {
      return <Cancel />;
    }
    case ButtonIcon.EARTH: {
      return <Earth />;
    }
    case ButtonIcon.EDIT: {
      return <Edit />;
    }
    case ButtonIcon.EYE_CLOSED: {
      return <EyeClosed />;
    }

    case ButtonIcon.EYE_OPENED: {
      return <EyeOpened />;
    }
    case ButtonIcon.LOGIN: {
      return <Login />;
    }
    case ButtonIcon.LOGOUT: {
      return <Logout />;
    }
    case ButtonIcon.REMOVE: {
      return <Remove />;
    }
    case ButtonIcon.SAVE: {
      return <Save />;
    }

    default:
      return null;
  }
};

interface ButtonClassNameProps {
  variation: ButtonVariation;
  isDisabled: boolean;
  title?: string;
  type?: ButtonType;
  size?: ButtonSize;
}

export const getButtonClassName = (props: ButtonClassNameProps): string => {
  const { variation, title, isDisabled, type, size } = props;
  let className = `button${title ? " button-titled" : ""}`;
  if (isDisabled) className += " button-disabled";
  switch (variation) {
    case ButtonVariation.PRIMARY: {
      className += " button-variation-primary";
      break;
    }
    case ButtonVariation.SECONDARY: {
      className += " button-variation-secondary";
      break;
    }
    case ButtonVariation.CORRECT: {
      className += " button-variation-correct";
      break;
    }
    case ButtonVariation.DANGER: {
      className += " button-variation-danger";
      break;
    }
    case ButtonVariation.DISABLED: {
      className += " button-variation-disabled";
      break;
    }
  }
  switch (type) {
    case ButtonType.FILLED: {
      className += " button-type-filled";
      break;
    }
    case ButtonType.GHOST: {
      className += " button-type-ghost";
      break;
    }
  }
  switch (size) {
    case ButtonSize.S: {
      className += " button-size-s";
      break;
    }
    case ButtonSize.M: {
      className += " button-size-m";
      break;
    }
  }
  return className;
};

interface ButtonTypographyColorProps {
  variation: ButtonVariation;
  title?: string;
}

export const getButtonTypographyColor = (
  props: ButtonTypographyColorProps
): TypographyColor => {
  const { variation } = props;
  switch (variation) {
    case ButtonVariation.PRIMARY: {
      return TypographyColor.PRIMARY;
    }
    case ButtonVariation.SECONDARY: {
      return TypographyColor.SECONDARY;
    }
    case ButtonVariation.CORRECT: {
      return TypographyColor.CORRECT;
    }
    case ButtonVariation.DANGER: {
      return TypographyColor.DANGER;
    }
    case ButtonVariation.DISABLED: {
      return TypographyColor.DISABLED;
    }
    default:
      return TypographyColor.PRIMARY;
  }
};

// todo: refactor Variation Interfaces
