import React from "react";
import { TypographyVariant } from "../Typography/TypographyTypes";

export enum ButtonVariation {
  PRIMARY = "PRIMARY",
  SECONDARY = "SECONDARY",
  CORRECT = "CORRECT",
  DANGER = "DANGER",
  DISABLED = "DISABLED",
}

export enum ButtonIcon {
  ADD = "ADD",
  CANCEL = "CANCEL",
  EARTH = "EARTH",
  EDIT = "EDIT",
  EYE_CLOSED = "EYE_CLOSED",
  EYE_OPENED = "EYE_OPENED",
  LOGIN = "LOGIN",
  LOGOUT = "LOGOUT",
  REMOVE = "REMOVE",
  SAVE = "SAVE",
}

export enum ButtonType {
  FILLED = "FILLED",
  GHOST = "GHOST",
}

export enum ButtonSize {
  S = "S",
  M = "M",
}

export interface IButtonStateProps {
  title?: string;
  type?: ButtonType;
  size?: ButtonSize;
  variation: ButtonVariation;
  iconStart?: ButtonIcon;
  iconEnd?: ButtonIcon;
  isDisabled?: boolean;
  isSubmit?: boolean;
  containerWidth?: boolean;
  typographyVariant?: TypographyVariant;
}

export interface IButtonDispatchProps {
  onClick(event: React.MouseEvent): void;
}

export interface IButtonProps extends IButtonStateProps, IButtonDispatchProps {}
