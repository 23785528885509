import styled from "styled-components";

export const Wrapper = styled.div`
  padding: 40px 140px 160px;
  &.no-wrap {
    padding: 0 140px;
    width: 100%;

    @media screen and (max-width: 1320px) {
      padding: 40px;
    }
  }
  @media screen and (max-width: 1320px) {
    padding: 40px 40px 160px;
  }
`;

export const NewsWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-wrap: nowrap;
  gap: 40px;
  padding-bottom: 30px;
  overflow-x: scroll;

  &.wrap {
    justify-content: space-between;
    padding-bottom: 0;
    flex-wrap: wrap;

    @media screen and (max-width: 1320px) {
      justify-content: center;
    }
  }
`;
