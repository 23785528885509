import React from "react";
import { NewsContent } from "./newsContent";
import { Typography } from "../../../components/shared/Typography/Typography";
import { FlexColumn } from "../../../components/shared/SharedStyle";

export const news0: NewsContent = {
  id: 0,
  title: "Братская встреча 19.05.2023",
  preview:
    "19 мая 2023 года состоялась встреча Братьев символического Треугольника «Коронованный Лев», учрежденного на Востоке города Владимира.",
  imageURL: "/news/0/0-1.png",
  content: () => (
    <FlexColumn>
      <Typography
        title="19 мая 2023 года состоялась встреча Братьев символического Треугольника «Коронованный Лев», учрежденного на Востоке города Владимира. Встреча прошла в выездном режиме, для проведения исторического исследования родовой усадьбы Воронцовых и Воронцовых-Дашковых, в селе Андреевское, Владимирской области. Кроме проведения исследования, были обсуждены насущные вопросы развития символического Треугольника «Коронованый Лев».
По результатам совместной работы Братьев, была написана статья, посвященная выбранной теме."
      />
      <img
        alt="Картинка для новости"
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/news/0/0-1.png)",
        }}
      />
    </FlexColumn>
  ),
};
