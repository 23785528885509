import React from "react";
import { ArticlesContent } from "./articlesContent";
import { TypographyVariant } from "../../../components/shared/Typography/TypographyTypes";
import { Typography } from "../../../components/shared/Typography/Typography";
import { ImageWithLabel } from "../../../components/shared/ImageWithLabel/ImageWithLabel";
import { FlexColumn } from "../../../components/shared/SharedStyle";
import { PageTitle } from "../../../components/shared/PageTitle/PageTitle";

export const article2: ArticlesContent = {
  id: 2,
  title: "Биография, деятельность и жизнь Р.И. Воронцова. Часть 2",
  preview:
    "Первая встреча Братьев прошла 19 мая 2023 года, в родовом имении Воронцовых.",
  imageURL: "/articles/2/2-6.jpg",
  content: (isArticleImageSmall: boolean) => (
    <FlexColumn>
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/2/2-1.jpg)",
        }}
      />
      <PageTitle title="УЧРЕДИТЕЛЬ ВОЛЬНОГО ЭКОНОМИЧЕСКОГО СООБЩЕСТВА" />
      <Typography
        title="Вольное экономическое общество было основано в Петербурге 31 октября
1765 года. К этому времени подобные сельскохозяйственные и
экономические общества возникали во многих странах Европы. Первое такое
общество было организовано в Шотландии в 1723 году, а затем в Ирландии
/1736 г./, в Швейцарии /1747 г./, в Англии /1753 г./, во Франции /1757 г./, в
Германии /1762 г./, а затем в Австрии /1767 г./ и Пруссии /1770 г./.
Главными задачами созданного экономического общества являлись сбор
экономических сведений о России, распространение новейшего
сельскохозяйственного оборудования. Оно занималось также мерами по
широкому внедрению всевозможных растений, организацией экспедиций для
разведки полезных ископаемых, изучением опыта передовых европейских
стран в области сельского хозяйства и многими другими отраслями
экономики. “Пятнадцать особ знатного рода опытностью в сельском
хозяйстве или ученостью отличающиеся в Санк-Петербурге, чтобы
составить... общество которое заботилось бы о распространении в
государстве полезных для земледелия и промышленности сведений”. Первым
в списке учредителей общества значился Роман Илларионович Воронцов,
вторым - Г.Г. Орлов - фаворит Екатерины II. Далее идут сенаторы и
министры, а также профессора химии и ботаники. Заканчивался список
придворным садовником. Императрица одобрила устав общества, все члены
которого были ей представлены. В своей речи, обращенной к ним, она
сказала: “... в знак отличного нашего к Вам благоволения дозволяем
поставить собственный наш девиз пчелы, в улей мед приносящей, с
надписью “Полезное”. Сверх всего жалуем еще Всемилостивейше Обществу
вашему 6 т. рублей на покупку пристойного дома для собрания вашего, так и
для учреждения экономической библиотеки”. По прочтении 1-й главы устава
об избрании президента, члены общества предложили этот пост Роману
Илларионовичу Воронцову, но он отговорился недостаточным знанием
иностранных языков, которые по его мнению президент должен знать “как
для переговоров с иностранными членами общества, так и для рассмотрения
вступающих на чужих языках пиес, писем и прочего”. В результате первым
президентом был избран А.В. Алсуфьев, а его секретарями Нартов и Леман.
Со второго заседания члены общества стали собираться в доме Р.И.
Воронцова, “яко ревнительного сочлена”, и продолжали в нем собираться до
января 1767 года. Затем на строительство дома для экономического общества
Роман Илларионович пожертвовал тысячу рублей. Одновременно со сбором
сведений о русском земледелии, Общество “признало нужным следить за
иностранным хозяйством”, выписывать “исправные модели самых лучших”
сельскохозяйственных машин. Граф Роман Илларионович, “по ревности
своей”, обещал выписать такие из Англии.
Вольное Экономическое Общество за 10 лет работы, выпустило тридцать
томов трудов. В этих трудах были опубликованы и статьи Р.И. Воронцова. В
статье “О заведении запасного хлеба” он предлагал в каждой деревне
построить “житницу” для хранения зерна на случай голода или неурожая.
“Из запасных житниц делается всем нуждающимся ссуда”, - писал он в своей
статье. Он рекомендовал назначить особое от Общества “награждение
золотой медалью каждому помещику, который первый заведет в своем уезде
хлебные запасы”. По его же инициативе были назначены три премии за
лучшее льняное полотно и пряжу. Для распространения этой культуры и в
других губерниях он предложил членам общества купить через псковского
губернатора Я.Е. Сиверса семена льна и “разослать всем охотникам с
печатными наставлениями о посеве льна”. В 1769 г. по предложению и за
счет Р.И. Воронцова была выдана золотая медаль и награда 50 червонцев
тому, кто укажет “легчайший в Копорском уезде способ поправления и
удобрения земель”.
В пятом номере трудов Общества опубликована интересная и прогрессивная
по содержанию статья Романа Илларионовича “О способах к исправлению
сельского делопроизводства”. В статье, рассматривая сложные
взаимоотношения между помещиком и крепостными крестьянами, он писал о
том, что надо делать, чтобы эти отношения были добрыми. Прилежных
крестьян, утверждает он, надо одобрять “публичною похвалою”, а их детей
не торопиться отдавать в рекруты. В один из дней в году, предлагает он,
помещикам надо “потчевать своих крестьян благодаря их за труд”, отмечать
“не всех равно”, а лентяев не следует наказывать тем, что “употреблять их в
работу и в праздничные дни”. Особую заботу он проявлял о крестьянках-
вдовах и предлагал землю у этой семьи не отнимать, а “должно обществом
той деревни... вспахать, хлеб посеять, убрать и обмолотить и к дому их
доставить; также дров и топлива им привезти”. И если эти предложения
будут выполняться, то “в короткое время крестьянство наше восчувствовало
бы немалое благополучие”. Члены Экономического Общества с первых дней
своей работы обратили внимание на оспу, “как на бич народного здоровья”.
Р.И. Воронцов выступил в Сенате с благодарственной речью, обращенной к
Екатерине II, которая сделала себе и своему сыну Павлу прививку от оспы:
“Всемилостивейшая Государыня, Сенат... напоминая ту опасность, в которую
для спасения всего рода человеческого Ваше Величество привитием оспы
Себе и любезному своему сыну поступить изволили, не благодарен был
перед Богом и Вами явиться, если б публичным знаком на вечные времена не
оставить память сего великого действия”. При этом Императрице было
преподнесено 12 золотых медалей с надписью: “Собою подала пример” и
“1768 год Октября 12 числа”. Опубликованные в 1775 году “Учреждения для
управления губерний” подробно регламентировали всю систему органов
управления и суда. Наместник или генерал-губернатор возглавлял
управление двумя или тремя губерниями. Права наместника определялись
правилами: “Строгое и точное взыскание чинить со всех подчиненных ему
мест и людей о исполнении законов и определенного их звания и должно но
без суда да не накажет никого”."
      />
      <PageTitle title="ВО ГЛАВЕ НАМЕСТНИЧЕСТВА" />
      <ImageWithLabel
        title="Главное здание усадьбы рода Воронцовых"
        url="/articles/2/2-2.jpg"
        width="100%"
      />
      <Typography
        title="Первым Правителем наместничества (губернатор) стал Самойлов Александр
Борисович (с 1778 по 1787 гг.). 1 октября 1778 г. во Владимире большой
пожар, от которого пострадало множество деревянных построек, 4 каменных
храма и Золотые ворота. Сгорела Ямская слобода. Она была возобновлена на
новом месте, за Золотыми воротами, далеко за городом.    "
      />
      <Typography
        title="В 1779 г. на месте сгоревшей Ямской слободы начали строиться улицы
Дворянская и Мещанская. Владимирская губерния - административно-
хозяйственная единица с центром в городе Владимире - была образована по
именному Указу Екатерины II 2 марта 1778 года. По этому указу надлежало
образовать по «примерному расписанию» 13 уездов, наименования которых
не указаны.2 марта 1778 г. Екатерина II повелела генерал-губернатору графу
Р.И. Воронцову: "
      />
      <Typography
        title="«Нашему Генералу Графу Воронцову.
Мы почитая за благо учредить вновь Володимирскую Губернию 
Всемилостивейше определяем Вас в оную Генерал-Губернатором, и поручаем 
Вам оную Губернию не упуская времени объехать, и по данному от Нас Вам 
примерному росписанию оной на тринадцать уездов на месте удобность их 
освидетельствовать, и как о сем, так и какие вновь Города для приписания к 
ним уездов назначить нужно будет, Нам самолично представить.
Екатерина.2 Марта 1778. С.-Петербург». «Граф Роман Ларионович.
На потребныя в Володимирской губернии строения для помещения новых 
присутственных мест Позволяем Вам употребить из тамошних Статс 
Канторских доходов двенадцать тысяч рублей. Екатерина.  9 Марта 1778. 
С.-Петербург»."
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography
        title="8 мая 1778 года первый владимирский генерал-губернатор, граф Роман
Илларионович Воронцов находился в инспекционной поездке по только что
учрежденной губернии. Это было необходимо для организации губернии по
«примерному росписанию», то есть по примерному плану. Роман Воронцов
должен был, «не упуская времени», объехать губернию и
освидетельствовать, какие города удобны «для приписания к ним уездов». А
о результатах доложить императрице Екатерине II."
      />
      <Typography
        title="1 сентября 1778—1782 гг. – Владимирский Наместный генерал-губернатор.
Указом Екатерины II от 1 сентября 1778 года было открыто Владимирское
Наместничество, которое просуществовало до 1796 года."
      />
      <Typography
        title="Главным присутственным местом Наместничества учреждено было во
Владимире.
Высшей властью в наместничестве обладал генерал-губернатор («государев
наместник»), управлявший, как правило, несколькими губерниями (обычно
двумя) и не столько занимавшийся конкретной работой по управлению
губерниями, сколько осуществляя общее политическое руководство.
Первым генерал-губернатором Владимирского Наместничества (в него
входили изначально Владимирская и Костромская губернии) был назначен
уже престарелый граф Роман Илларионович Воронцов.
В каждое наместничество назначался «правитель наместничества» или
губернатор (в некоторых источниках в качестве наименования должности
используется наместник), который занимался хозяйственными и
финансовыми делами губернии. Большинство современных исследователей
не делает различий между губерниями и наместничествами при Екатерине II.
В ряде документов, в том числе официальных указах, времен ее царствования
для обозначения данных административно-территориальных единиц
термины «губерния» и «наместничество» используются как синонимы.
Различия между губерниями и наместничествами при Екатерине II в
основном заключаются в особенностях административного управления.
Некоторые исследователи на основании анализа территориальных гербов
полагают, что наместничество представляло собой административную
надстройку над губернией с целью контроля центром местной власти."
      />
      <Typography
        title="Обязанностью Воронцова стала организация торжеств по случаю открытия
наместничества. Газета «Санкт-Петербургские ведомости» писала об этом 15
марта 1779 г.: «Его сиятельство господин генерал-аншеф, действительный
камергер и кавалер генерал- губернатор Володимирского наместничества
граф Роман Ларионович Воронцов в прошедшем декабре месяце, произвел с
помощью Божиею, надлежащим порядком, открытие сей новой губернии.
Оное начато 19 декабря и продолжалось по 29 число. Во все дни сего
знаменитого происшествия генерал-губернатор оказывал неусыпные свои
попечения, при разных распоряжениях наместничества и действием, и
словом давал наипаче возчувствовать собравшимся дворянам неоцененные
Ея Императорского Величества на них и потомков их излияниые». "
      />
      <Typography
        title="В той же газете сообщалось, что во Владимире Воронцов «...во время
пребывания дворян в сем городе угощал их обеденными столами, в
окончательные же дни, так как и при начале сейма, в доме ею были для всего
дворянства балы, иллюминации и напоследок маскарад».
При открытии наместничества были проведены дворянские выборы.
Губернским предводителем дворянства стал Федор Алексеевич Апраксин,
владимирским уездным дворянским предводителем - Александр Николаевич
Зубов."
      />
      <Typography
        title="«Граф Роман Ларионович. Благополучное открытие наместничества
Володимерскаго, и похвальнейшее ревнование дворянством тамошним и
прочими чинами при сем случае оказанное к выполнению намерений Моих
коблагу их всегда клонящихся, обязывают Меня изъявить Вам и им отличное
Мое благоволение. Удовольствие мое тогда в полной мере будет когда
последуя в точности воли моей и каждый тщательно возложенные на него
звания исправляя ощутят они делом самым все плоды и пользы из того
проистекающия, чего усердно желая пребываю Вам доброжелательная.
Екатерина. В С.-Петербурге, Генваря 2 дня 1779 года». "
      />
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/2/2-3.jpeg)",
        }}
      />
      <Typography
        title="Среди первых распоряжений Воронцова было назначение 2 января 1779 г. во
Владимир подлекаря Василия Чупинского, в Муром - лекаря Петра
Липгольта, в Александров - лекаря Михаила Семчевского. С каждым
заключался контракт о службе. По контракту годовая зарплата муромского
лекаря Петра Липгольта составляла 140 рублей в год. При этом он должен
был лечить бесплатно «канцелярских и других служителей» и военную
команду, находящуюся в городе, а в случае неисполнения своих
обязанностей мог подвергнуться штрафу. 5 января 1779 г. состоялось
торжественное открытие ковровских присутственных мест. Для ведения
делопроизводства требовались канцелярские служащие. 9 января 1779 г.
Воронцов подписал «предложение» наместническому правлению о
назначении в разные губернские учреждения протоколистов, регистраторов,
архивариусов и т.д. и об их заработной плате. 21 января он распорядился,
чтобы все учреждения в губернии принимали канцелярских служащих
самостоятельно без представления в наместническое правление.«Граф Роман
Ларионович. Дополнительное известие мною полученное от Вас с
губернским стряпчим Кайсаровым о совершении открытия наместничества
Володимерскаго, было столь же мне приятно как и прежния Ваши о том
донесения. Повторяю и присем случае засвидетельствование благоволения
моего о трудах Ваших и прочих с Вами соподвизавшихся и об усердной
признательности общества тамошняго. Пребывая впрочем Вам
доброжелательная. Екатерина.В С.-Петербурге, Генваря 15 дня 1779 года».
22 января 1779 года Владимирское наместническое правление издало указ об
учреждении внутри губернии почтовых дистанций, так была учреждена
почта Владимирского наместничества."
      />
      <Typography
        title="Известный мемуарист А.Т. Болотов писал про графа Воронцова, что
наместник «…их [кошек] так боялся, что в состоянии был при воззрении на
них упасть в обморок; и потому наистрожайшим образом приказано было во
всем городе всем жителям ловить, скрывать и запирать своих бедных кошек,
что они и принуждены были делать». Состоявший при графе конвой из
армейских кавалеристов специально разгонял всех усатых-полосатых по пути
следования генерал-губернатора, изрядно озадачивая и смущая горожан.
Совестный суд был создан как общесословный, сочетающий в себе
одновременно функции примирения по малозначительным делам,
третейского суда и даже прокуратуры. Владимирский Совестный Суд ачал
свою работу в январе 1779 г.  «Граф Роман Ларионович. В последнем
донесении Вашем уведомляете Вы меня о намерении Дворянства
Володимерской губернии дать не малую сумму в пользу Владимирского
приказа общественного призрения. Подвиг сей без сомнения есть всякой
хвалы достойный, и прямое о добре общем радение доказывающий, но образ
збору той суммы по числу душ в донесении Вашем выраженный не может
быть удобным, а всего меньше не отяготительным; когда и в прочих
губерниях подобныя расположения отнюдь не могли иметь места, и только
дали повод к жалобам. Для сего не утверждая таковой по числу душ складки
Вы скажите им что каждый из них может дать в пользу помянутаго Приказа
столько сколько подобной своей воли он разсудит за благо не налагая на
отсутствующих и нехотящих. Есмь впрочем Вам благосклонная. Екатерина.
В С.-Петербурге, Генваря 15, 1779 года».2 июня 1779 г. Воронцов «по
совершении божественной литургии благоволил открыть приказ
общественного призрения». Председателем Приказа Общественного
Призрения стал Правитель Наместничества, д.с.с. Самойлов.
В 1779 г. построена каменная (из красного кирпича) Сергиевская церковь на
месте существовавшего ранее и упразднённого после 1719 г. женского
монастыря. Из-за цвета стен называлась Красной церковью. В 1780 г. за
городом, на Всполье, отведена земля владимирским купцам для
строительства кирпичных заводов. В «Трудах Вольного экономического
общества» Р. Воронцов публиковал статьи, в которых предлагал держать в
селах специальные запасы хлеба для избежания голода в неурожайные годы,
создать крестьянский суд из «лучших людей», а господ призывал
«обходиться с ласкою» с крестьянами. С другой стороны, в
исследовательской литературе неоднократно упоминается его прозвище
«Роман – большой карман», указывающее на лихоимство и поборы
государева наместника.
"
      />
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/2/2-4.jpg)",
        }}
      />
      <Typography
        title=" В архиве Воронцовых сохранилось много документов: указы, рапорты,
ведомости, письма городских магистров, верхних и нижних земских судов,
правителей наместничеств, предводителей дворянства и частных лиц, а также
предложения Романа Илларионовича разным учреждениям и частным лицам.
Из этих документов видно, как широк был круг его деятельности, - тут и
образование новых уездов, и определение новых границ губерний,
благоустройство городов, набор рекрутов, сбор недоимок и много других
административных вопросов. Обширен круг лиц, с которыми он состоял в
переписке."
      />
      <Typography
        title="В марте 1780 года он пишет Тамбовскому предводителю наместничества:
“До сведения моего дошло, что во многих местах Тамбовской губернии
имеются воровские партии, которые разбивают приезжих, то Вашему
превосходительству через сие рекомендую - приложить ваше старание о
искоренении оных...” и советую “для искоренения сих злодеев в тех местах,
где они обыкновенно бывают, учредить из ближних селений пикеты, а равно
иметь для поимку оных разъезжие лодки...” Он считает своим долгом
заступиться за обер-интендантшу, которая обратилась к нему с просьбой,
решить ее дело положительно, так как она больна и не может присутствовать
на судебном заседании. Он вникает в каждые детали и мелочи. В Пензе идет
большое строительство и не хватает инструментов. Чтобы решить эту
проблему, он обращается из Пензы к Тамбовскому губернатору с просьбой
прислать в Пензу “железные инструменты: пилы, лом и прочие... т.к. в Пензе
оных крайне недостаток...” обещая, что они “по окончании здесь строения
присланы будут в Тамбов обратно”. "
      />
      <Typography
        title="18 ноября 1780 года он пишет сыну: “Теперь, наконец, могу ласкать себя
надеждой, что старания мои о искоренении всяких злоупотреблений будет
иметь желаемый успех”. «Граф Роман Ларионович. Для скорейшаго
доставления Вам решения Моего на просьбу татарских Мурз в Тамбовской
губернии обитающих об освобождении их из подушнаго оклада, и о
причислении к дворянству, Я желаю чтоб Вы поспешили прислать ко мне те
самыя об них сведения, и списки родов их с доказательствами, которые Вы
самолично Мне предполагали представить. Между тем Вы можете их
обнадежить что прошение их не останется без Моего разсмотрения, и что
помере справедливости всевозможное удовлетворение им учинено будет.
Пребываю впрочем Вам доброжелательная. Екатерина. В С.-Петербурге,
Генваря 3 дня 1780 года». "
      />
      <Typography
        title="«Нашему Генералу, Володимирскому, Тамбовскому и Пензенсокму Генералу
Губернатору Графу Воронцову… Октября 31 дня 1780 года». В 1782 г. к
Владимирскому было присоединено Костромское наместничество, и Роман
Ларионович стал еще и Костромским наместником. «Нашему Генералу
Володимерскому и Костромскому Генерал-Губернатору Графу
Воронцову…Генваря 13 дня, 1782 года»."
      />
      <Typography
        title="16 августа 1781 года согласно Указу Екатерины II утверждён герб города
Владимира. Первым владимирским губернским архитектором (1779–1788)
был Николай фон Берк. Он спроектировал первые деревянные казенные
здания – дом наместника, дом губернатора, палаты Присутственных мест и
др. Однако эти замыслы не были осуществлены. Новый план застройки г.
Владимира, разработанный губернским архитектором Н.П. фон Берком, был
утвержден Екатериной II 31 марта 1781 г. Согласно плану, центральная улица
города должна была застраиваться двух- и трёхэтажными каменными
домами. Не имеющие средств для строительства таких домов горожане
должны были продавать свои участки в центре и строить дома более
дешёвые, полукаменные (низ каменный, верх деревянный) или деревянные, в
боковых улицах. По плану 1781 г. было сохранено традиционное деление
города на части в соответствии с основными функциями: административная
(от Золотых ворот до Торгового моста), торговая (от Торгового моста до
Нижегородской заставы), ремесленно-жилая (бывшие слободы). См. Улица
Большая губернского гор. Владимира. В 1781 г. открыта первая аптека во
Владимире. Она располагалась на территории Богородице-Рождественского
монастыря. Её владельцем был провизор Христиан Деринг. У Золотых ворот
ему была выделена земля для «аптекарского огорода». В 1782 г. в новой
Ямской слободе выстроена каменная Казанская церковь. Часто в
просторечии Казанскую церковь называли Ямской или Извозной. "
      />
      <Typography
        title="В феврале 1782 года, Воронцова постигло разочарование: “Я довольно вижу,
что все мои труды кои и я для благосостояния порученным моему попечению
жителем прилагаем, не имея почти ни день, ни ночь покоя, остаются тщетны,
и что я остаюсь жертвой людей приобвыкшим к клеветам... И сколько
прежде желал служить, столько ныне служба сия мне тягостна... Я все свое
старание прилагаю, чтобы все были довольны и, позволяя последнему
крестьянину к себе свободный вход, приказываю доставлять каждому
правосудие...”"
      />
      <Typography
        title="Но недовольных и завистников было немало. На Воронцова отправлялись
жалобы в Петербург. И хотя у Романа Илларионовича сложились хорошие
отношения, и с владимирским предводителем дворянства Владимирской
губернии (1778—1787) Апраксиным Федором Алексеевичем и с тамбовским,
и пензенским дворянством, а также с губернскими чиновниками, которых он
уважал “за их трудолюбие и хорошее поведение”, количество жалоб не
уменьшалось. В январе 1783 года Р.И. Воронцов отправляет рапорт на имя
Екатерины II с просьбой об отставке."
      />
      <Typography
        title="Среди всех этих неприятностей, происходят и радостные события. 18 августа
1781-го года состоялась свадьба его младшего сына Семена Романовича с
Екатериной Алексеевной Сенявиной, дочерью адмирала. Свою радость
Роман Илларионович высказал в письме к своему старшему сыну
Александру: “Сейчас получил я приятнейшее письмо Ваше о намерении
графа Семена Романовича соединить судьбу свою со столь достойною
партиею, каковою я нахожу Катерину Алексеевну. Я весьма рад его выбору,
что он предпочел взаимную склонность всему другому. И тем охотнее буду я
стараться оказывать вам всякую помощь, а теперь уступаю вам мой дом,
приморские дачи и Муринскую фабрику со всеми их доходами”. Через год
рождается долгожданный внук Семен, а еще через год – внучка Екатерина. "
      />
      <Typography
        title="22 сентября 1783 г. Роман Илларионович был награжден орденом Св.
Владимира. По этому поводу он пишет сыну Семену в Лондон;
“Всемилостивейшей государыне благоугодно было всемилостивейшее
пожаловать меня орденом святаго Владимира I степени, но мне сия милость
несравненно была драгоценнее, если б на вас обращена была”. Орден был
учрежден ровно год назад 22 сентября 1783-го года, и Роман Илларионович
получил этот орден за №22. "
      />
      <Typography
        title="В 1783 г. во Владимире открыты смирительный и работный (рабочий) дома
для содержания в них как неимущих жителей города, так и совершивших
преступления, оказавших «непокорность». Своё содержание находившиеся в
этих домах люди обеспечивали собственным трудом."
      />
      <Typography
        title="На торжественном открытии Екатерина Романовна Дашкова произносит
речь, определяя главные задачи вновь открытой академии: сочинение
российской грамматики, составление российского словаря и изучение
риторики и правил стихосложения. Первыми академиками стали
выдающиеся деятели XVIII века, поэты, драматурги, историки и
переводчики. Среди них Г.Р. Державин, Д.И. Фонвизин, Я.Б. Княжнин. Уже
на втором заседании, которое состоялось 28 октября, действительным членом
российской академии избирается Р.И. Воронцов. Этим отметили его заслуги
на поприще отечественной культуры. Роман Илларионович являлся
инициатором переводов многих французских авторов, часто эти и другие
книги издавались за его счет. Он был не только личным другом М.В.
Ломоносова, но морально и материально часто поддерживал его, когда у
Михаила Васильевича возникали трудности в организации научной работы
или мозаичного дела. Многие стихи Ломоносова посвящены Воронцовым.
Михаил Илларионович Воронцов заказал в Италии памятник для надгробия
Ломоносова, установленного в Александро-Невской лавре. После кончины
М.В. Ломоносова его вдова писала о Романе Илларионовиче: “Истинно, в
числе многих благодетелей, ни к кому с такой радостью не приступаю, как к
его сиятельству графу Роману Ларионовичу...”"
      />
      <Typography
        title="Злоупотребления при рекрутских наборах, которые в те времена были делом
обычным, конечно, были в тех губерниях, во главе которых стоял Р.И.
Воронцов. И слухи о том, что сам наместник замешан, дошли до Екатерины
II. Существует предание, что в день своих именин 27 ноября 1783 года, когда
в его доме было много гостей, Роман Илларионович получил в подарок от
императрицы пустой кошелек “длиною более аршина”. Через три дня после
этого Р.И. Воронцов скончался (30 ноября 1783)."
      />
      <Typography
        title="В декабрьском номере Санкт-Петербургских ведомостей, в сообщениях из
Владимира говорилось: “Тридесятого числа прошедшего месяца ноября
скончался здесь граф Роман Ларионович Воронцов, генерал-аншеф, сенатор,
действительный камергер, Владимирского и Костромского наместничества
генерал-губернатор и орденов Российских Св. Апостола Андрея, Св.
Александра Невского, Св. Равноапостольского Князя Владимира Большого
креста первой степени, Польского Белого Орла и Св. Анны кавалер...”"
      />
      <Typography
        title="Погребение состоялось при “великом стечении народа”. Предводители
дворянства сами внесли гроб в Дмитриевский собор. Коллежские асессоры
несли балдахин над гробом, председатель и советники несли регалии на
бархатных подушечках. “Кончина его тем более чувствительна многим
добродетельным, честным, а особливо неимущим людям, - говорилось далее
в некрологе, - потому что он покровительствовал первым и оказывал
благодеяния последним”."
      />
      <Typography
        title="Спустя два года после смерти графа Воронцова правительством было
назначено расследование по делу о злоупотреблениях при рекрутских
наборах во Владимирском наместничестве. В ревизии участвовал сенатор
князь М.М. Щербатов. А в 1786 г. состоялся суд над вице-губернатором
князем Ухтомским Д.М. (1783—1785 гг. - вице-губернатор). За эти же
злоупотребления был привлечен к ответственности и губернатор А.Б.
Самойлов. Роман Илларионович был полностью оправдан. В письме графа
П.В. Завадовского от 1 мая 1787 года А.Р. Воронцову сыну Романа
Илларионовича говорится: "
      />
      <Typography
        title="“По делам Владимирским Сенат решил, согласно 
комитету, оправдая невинность покойного графа, а бездельники предаются 
суду”."
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />

      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/2/2-5.jpg)",
        }}
      />
      <PageTitle title="Поучительные письма графа Р.И. Воронцова" />
      <Typography
        title="В.Н. Алексеев. Поучительные письма графа Р.И. Воронцова "
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
        align="center"
      />
      <Typography
        title="Окруженная анекдотами и небылицами личность первого Владимирского
Наместника, графа Романа Илларионовича Воронцова так часто
представляется весьма искаженной с самых разных точек зрения, что может
быть примером «исторического клейма». Настоящая работа посвящена
оценке педагогических взглядов графа, его участию в деле воспитания
собственных детей. Как известно, Роман Илларионович был самым
многодетным из всех известных Воронцовых. В 18 лет он вступил в брак с
Марфой Ивановной Сурминой, от которой за десять лет супружеской жизни
имел семерых детей (двое из них скончались в младенчестве). Впоследствии
Роман Воронцов состоял в не венчанном (гражданском) браке с Елизаветой
Денисовной Брокет, родившей ему еще четырех детей, получивших фамилию
дворян Ранцовых."
      />
      <Typography
        title="Хотя документально нам почти ничего не известно о самых первых годах
воспитания Воронцовых, можно предположить, что дети Романа
Илларионовича не были лишены семейного наставничества. Будущая
княгиня Е.Р. Дашкова после смерти матери воспитывалась у своей бабушки
Федосьи Сурминой, а Семен Романович ранние детские годы провел в семье
деда Лариона Воронцова. Несомненно, им были привиты существовавшие в
дворянском обществе XVIII столетия традиционные духовные и
нравственные понятия. Возможно, основой для детского воспитания были
правила, изложенные в различных произведениях того времени (Например, в
известном наставлении «Юности честное зерцало или показание к
житейскому обхождению» (СПб, 1717 г). Хорошо известно, что наиболее
прочные основы будущего мировоззрения человека закладываются в его
детские годы в атмосфере собственной семьи, в кругу ближайших
родственников, которые нередко служат юношам идеалами, своеобразными
путеводными звездами."
      />
      <Typography
        title="С этой стороны, Воронцовы с самых ранних лет видели перед собой
прекрасные примеры для подражания. Таковым был их родной дядя граф
Михаил Илларионович Воронцов, вице-канцлер с 1744 г., канцлер с 1758 г.
Вслед за ним значительный успех на службе и глубокое почитание
племянников заслужил Иван Илларионович Воронцов - генерал-поручик,
камергер, сенатор, президент Вотчинной коллегии. Наконец, лучшим
образцом для детей служил и их собственный отец Р.И. Воронцов - граф,
генерал-поручик, действительный камергер Двора Её Императорского
Величества, председатель Уложенной комиссии. Представления о Романе
Илларионовиче как педагоге и воспитателе чаще всего основываются лишь
на единственной фразе из «Записок» его дочери, княгини Е.Р. Дашковой. В
первых строках своих мемуаров княгиня писала: «Мой отец, граф Роман,
младший брат канцлера, в молодости любил жизненные удовольствия, а
стало быть, мало занимался своими детьми; он был чрезвычайно рад, когда
дядя из чувства благодарности к моей покойной матушке, а также из дружбы
к нему взялся за мое воспитание. Две мои сестры [Мария и Елизавета]
находились под покровительством императрицы и, назначенные фрейлинами
еще в детском возрасте, жили при дворе. В родительском доме оставался
один мой старший брат Александр... Младший брат [Семён] жил у дедушки в
деревне...». Совершенно очевидно, что оставшаяся сиротой в два года,
княгиня Дашкова не могла охарактеризовать отца по собственным
наблюдениям. В дом дяди, Михаила Илларионовича она была взята в
четырехлетнем возрасте и тоже не могла помнить о том, что отец «был
чрезвычайно рад» этому событию. Остается неизвестным, с чьих слов
Екатерина Романовна судила об отце, и по этой причине трудно оценить
достоверность ее мнения. Между тем, существует достаточно большое
количество документов, позволяющих судить о роли Романа Илларионовича
как воспитателя собственных детей. Такими документами, прежде всего,
являются письма Р.И. Воронцова к своему старшему сыну Александру во
время пребывания того в Европе."
      />
      <Typography
        title="До отъезда за границу оба сына Романа Илларионовича, Александр и Семен,
закончили обучение в частном пансионе профессора юриспруденции
Штрубе, говорили по-французски, были обучены придворному этикету,
весьма и весьма начитаны, а Александр даже имел успех на литературном
поприще: оказался первым в России переводчиком Вольтера. В марте 1758
году, на семнадцатом году от рождения Александр Воронцов был отправлен
в Версаль для обучения в рейтарской школе «Chevaux lagers». В школе
обучались юноши известнейших французских семей, а для Александра она
должна была стать ступенью в его воинской карьере. Между тем, еще до
отъезда молодой Воронцов признавался отцу и дяде, что имеет сильное
желание стать дипломатом и чувствует в себе способности к этой
деятельности."
      />
      <Typography
        title="После полутора лет обучения, Александр еще более года путешествовал по
Европе, В это время между ним и Романом Илларионовичем существовала
очень частая (иногда по несколько писем в неделю!) переписка,
используемая отцом для всевозможных поучений и наставлений. Эти
поучения несомненно ложились на уже подготовленную почву, то есть на те
нравственные основы, которые были заложены еще при домашнем
воспитании Александра."
      />
      <Typography
        title="Какие же качества личности уже проявлялись у этих молодых людей, и какие
наставления заповедовали им старшие родственники?
Традиционным в семье было почитание и глубокое уважение родителей.
Александр (как и его сестры и брат) искреннее любил и почитал отца и дядю,
которые пользовались своим несомненным авторитетом в педагогических
целях. "
      />
      <Typography
        title="«Ты молод, и меня всякой тем стращает, чтоб не испортился твой 
нрав, - писал Роман Илларионович Александру. - Я пишу тебе больше как 
друг. Это имя хотя часто употребляется, только редко. Старайся 
сохранить это имя». "
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography
        title="Сын, действительно, всю свою жизнь боготворил
своего «батюшку». Он обращается к нему со словами «милостивый государь
батюшка», «осмелюсь просить», «имею честь вам донести», «осмелюсь вас
трудить». Эти выражения - не условность и не принятая форма обращения, а
выражение искреннего почитания родителя. "
      />
      <Typography
        title="«Ничего на свете мне нет 
дороже, - писал Александр отцу, - как ваши милости и для которых все в 
жертву отдам... За щастие всегда почту, когда буду иметь оказию вам 
дать опыты етой преданности, которую должен и иметь буду, покуда 
жив». "
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography
        title="Четверть века спустя, когда Александр Романович получил известие о
кончине отца, он примчался на погребение во Владимир из Петербурга, что
по тем временам можно было считать подвигом. Почитание старших в семье
было характерным и для остальных Воронцовых, а одобрение отца или дяди
считалось высшей наградой для детей, даже когда они становились
взрослыми. Успехи отца были предметом гордости его детей. В ответ на
назначение Романа Илларионовича сенатором его 19-летний сын признается: "
      />
      <Typography
        title="«Публика давно оное ожидала и довольна теперь быть может. Вы, конечно, 
окажете вскоре, что она не попустому надежду имела. Рассудите, сколько 
мне приятно будет слышать: отец, которого люблю, один может из 
нонешнего века быть сравнен добротелею сенаторов римских при 
установлении Сената». "
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography
        title="Одной из важнейших нравственных заповедей,
которую с детства слышали Воронцовы, была, конечно, «честь рода». Этой
честью рода, покоящейся на высочайшем духовном основании, в роду
Воронцовых очень дорожили.
В своем первом письме в Париж Роман Илларионович наставлял: "
      />
      <Typography
        title=" «Я не 
подтверждаю тебе о том, чтоб твои поведения всегда согласны были с 
твоим именем, и чтоб как мне, так и брату моему не было сожаления о 
том, что тебя отпустили так молода вояжировать». "
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography
        title="Примерно те же
мысли ложатся на бумагу и под пером дяди.: «Прощай, мой друг, и содержи
себя разумно и честно, чтоб отец твой и я, слыша о тебе могли радоваться.
Надобно Вам сохранить честь российского дворянства и фамилии Вашей,
которая того от Вас требовать право имеет». Одним из важнейших
педагогических правил всегда являлось предостережение от необдуманных
поступков, случайных или нежелательных знакомств. Роман Илларионович
не был в этом исключением. Предупреждая знакомство сына с мотами, он
советует ему: "
      />
      <Typography
        title="«На те оригиналы, с которых копии сюда выезжают, 
надежды мало. Что можно получить с ними в обращении, как не одно о 
театрах знание и героинях театральных»."
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography
        title="(Тем не менее, театр был,
пожалуй, единственным «грехом», от которого Александр не уберегся.
Вернувшись в Россию, он стал не только театралом, но и организовал
собственные крепостные театры сначала с селе Б. Алабухи, а затем в родовой
усадьбе Андреевское). Сохраняя честь рода, Александр должен был
надлежащим образом постигать науки, к чему постоянно и призывал его
отец. Из писем Романа Илларионовича следует, что он желал бы, чтобы сын
выучил латинский язык, прошел курс философии и считал, что «в знании
надлежит дойти до такой степени, чтобы не посрамить себя, когда тебе в
компании случится вступить в рассуждение о каком-нибудь деле».
Неразрывно с целеустремленностью у Воронцовых с ранних лет
воспитывалась самостоятельность детей и доверие к ним родителей.
Определяя Александра за границу для обучения, Роман Илларионович
отправил сына без полагающегося в таких случаях гувернера. «Я не в
состоянии дать тебе гувернера, а надеюсь, что ты сам себе гувернером
будешь», напутствовал он сына. Юношу сопровождали только двое слуг, о
которых отец писал следующее: "
      />
      <Typography
        title="«люби своих людей и принимай от них 
представления» (доклады). «Все, что случится достопамятного, записывай, 
чтобы вояж твой служил тебе в пользу», - советует Воронцов сыну,
путешествующему по Италии."
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography
        title="Видимо, зная о извержении сицилийского
вулкана Этна в 1669 г., когда погибло около 100 тысяч человек, Роман
Илларионович просит сына не ходить на нее, хотя и путает Этну с Везувием:"
      />
      <Typography
        title="«и гору как будешь смотреть Этну в Неаполе, то прошу на нее не ходить»."
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography
        title="За этой короткой и как бы сухой просьбой отчетливо проступает отцовская
забота о сыне, боязнь случайно потерять его.Разумеется, значительная часть
поучений Романа Илларионовича касалась бережливости и экономии
денежных средств. Занимавшие высокие должности отец и дядя Александра
всегда были стеснены в средствах и вынуждены были проживать больше,
чем имели доходов. Роман Илларионович знал цену деньгам и не уставал
постоянно напоминать об этом сыну."
      />
      <Typography
        title="«Помни, мой друг, - говорится в одном
из писем, что ты поехал учиться, а не щеголять, и что мотовством доброго
имени не наживешь и не удивишь... Знай, что по приезде твоем я во всем
потребую отчета, и для того [ты] должен иметь верную книгу для записи
своих расходов»."
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography title="Отцу вторил и М.И. Воронцов:" />
      <Typography
        title="«Я вам как дядя и приятель
советую сколько возможно от излишних расходов воздержаться и быть
хорошим экономом... Я сие вам советую, к сожалению моему, по
собственной экспериенции [опыту], что... остаюсь в великих долгах, прожив
почти все имение»."
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/2/2-6.jpg)",
        }}
      />
      <Typography
        title="Справедливо отметить, что семнадцатилетний Александр, действительно, вел
строгий учет всем своим расходам и практически не тратил денег на
увеселения. Тем не менее, узнавая о суммах затрат, Роман Илларионович
распалялся еще более."
      />
      <Typography
        title="«Объявляю, что ты издержками своими, как видно,
дом свой разорить хочешь, - писал он сыну. - Не имеешь ты жалости ни ко
мне, ни к брату своему, а напоследок, кажется, забыл ты и самого себя». "
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography
        title="Интересно, что эти угрозы носили чисто воспитательный характер, и вслед за
резкими словами отец писал: "
      />
      <Typography
        title="«Ты примирись со мною хоть за то, что я ни
одной из моих угроз не произвел в действие»."
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography
        title="Наставления, передаваемые Александру Романовичу в письмах, касаются
небольшого, но ключевого круга нравственных понятий: понятия о долге и
чести, призывы к бережливости, самостоятельности. Часто все эти правила
напоминались Александру даже в одном письме. Так Роман Воронцов писал
сыну: "
      />
      <Typography
        title="«Прошу Вас, мой друг, сколько можно стараться меньше расходов
иметь, дабы мы в вашей персоне нашли в фамилии нашей хорошего
хозяина...»."
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography
        title="Можно предположить, что дядя и отец Александра знали, что их адресат
готов к восприятию подобных нравоучений, и что эти поучения будут
безукоризненно исполнены. Считая, что у Александра большое будущее,
отец и наставлял его как дипломата, рекомендуя в Италии:"
      />
      <Typography
        title="«...объездить всё,
не оставляя ни оного городка. Все, что ни случится достойного - записывай,
чтобы вояж твой, служил тебе в пользу»."
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography
        title="К сожалению, нам мало что
известно об отцовских отношениях Романа Илларионовича к своему
младшему сыну Семену. Отправив Александра на учебу во Францию, отец
послал Семена в путешествие по России. Тот проехал от Петербурга до
Москвы, посетил родовое имение своей матери Андреевское, а затем
отправился на Урал, чтобы познакомиться с медеплавильными заводами
Воронцовых. Такие путешествия юношей весьма способствовали их
всестороннему развитию и тоже могут быть отнесены к особенностям
семейного воспитания Воронцовых. Забота Р.И. Воронцова о своих сыновьях
продолжалась в течение всей его жизни. Граф был несказанно рад женитьбе
младшего Семена и тут же уступил молодоженам свой дом в Петербурге,
приморские дачи и одну из своих фабрик со всеми доходами. При этом он
просил старшего сына во всем быть Семену вместо отца. За полгода до
смерти, будучи тяжело больным, граф совершил тяжелейшую для себя
поездку в Петербург, чтобы посмотреть на родившуюся внучку и
подрастающего внука. Эта поездка окончательно подорвала его здоровье.
Таким образом, даже весьма краткое знакомство с воспитательной
деятельностью Романа Илларионовича отвергает всякие сомнения в его
равнодушии к судьбе собственных детей. Напротив, по отношению к своим
чадам Р.И. Воронцов обладал немалым педагогическим даром, сочетавшимся
у него с безграничной любовью, заботой и высоким чувством отеческого
долга. Анализируя переписку Воронцовых и их биографические сведения,
можно говорить, если и не о идеально систематизированной, но, безусловно,
существующей в сознании этих людей программе воспитания и образования
своих детей и внуков, которую они всеми своими силами старались
воплотить в жизнь. Эпистолярные поучения юношей, как одно из средств
семейного воспитания Воронцовых, хотя и вызывают очень большой
интерес, конечно, не были исключительным явлением. Использование
письменных наставлений юношества, как средства передачи педагогических.
идей, известно с очень давних времен. Нравственное наставничество
следующего поколения почиталось долгом мудреца еще во времена
античности (Гесиод) и впоследствии было замечательно выражено в письмах
Луция Аннея Сенеки к Люцилию. Построенные в форме философского
диалога эти сочинения своей логикой убеждали молодого человека в
необходимости следования высоким идеалам, в том, что гармония личности
заключается в сочетании знания с множеством добродетелей и преодолением
страстей. "
      />
      <Typography
        title="Конечно, в письмах Воронцовых нет того блеска риторики, броских фраз и
идейных парадоксов, которыми изобилуют письма Сенеки, но они хороши
своей домашней, семейной простотой и ясностью. С этой точки зрения
письма Михаила и Романа Илларионовичей весьма и весьма созвучны
таковым английского писателя и аристократа Филипа Честерфилда к его
сыну. Состоящие из целого свода педагогических наставлений в духе идей
Джона Локка письма Честерфильда представляли собой своеобразную
программу подготовки юноши к государственной карьере и были высоко
оценены Вольтером."
      />
      <Typography
        title="Следуя наставлениям Дж. Локка, Ф. Честерфилд подтверждает идею о
решающем влиянии на воспитание родителей, которые должны быть для
него самым лучшим и понятным примером. По его мнению, к учению
следует приступать только после того, как средствами семейного воспитания
будет сформирован характер ребенка и ему привиты основы морали.
Поражает почти дословное совпадение многих поучений Р.И. Воронцова и
Ф. Честерфилда. Не повторяя уже приведенных цитат из писем первого,
приведем некоторые советы Честерфилда сыну: «Надо отлично знать
греческий... латынь знает всякий и не знать ее - стыд и срам. - Если же ты не
оправдаешь возлагаемых на тебя надежд, то потеряешь свое доброе имя, а
это - самое унизительное для человека благородного. - Смею думать, что, как
ты не молод, собственный твой разум подскажет тебе, что советы, которые я
тебе даю, имеют в виду твои и только твои интересы. - Записывай в книгу все
приходы и расходы, потому что человеку, который знает, сколько он
получает и сколько тратит, никогда не грозит опасность выйти из бюджета».
Проблема семейного воспитания Воронцовых позволяет приблизиться к
решению вопроса о феномене рода Воронцовых вообще. Появившись на
небосводе российского государства 2-й половины XVIII столетия, как плеяда
исключительно ярких личностей, графы Воронцовы представляли собой
достаточно необычное явление. Представители рода были не только
знаковыми фигурами своего времени, но необыкновенно одаренными,
преданными Отчизне и присяге гражданами, всеми своими поступками,
доказывавшими неизменность своих жизненных принципов и высоту
нравственных ориентиров. С этой точки зрения классическое толкование
девиза герба рода Воронцовых «Semper immota fides» («Всегда
непоколебимая верность») как клятва верности государю, может быть
дополнено и свидетельством верности своим мировоззренческим
убеждениям, основа для которых закладывалась традициями семейного
воспитания."
      />
      <PageTitle title="ВЛАДИМИР-ЦЕНТР НАМЕСТНИЧЕСТВА" />
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/2/2-6.jpg)",
        }}
      />
      <Typography
        title="Владимиро-Суздальское княжество. Город Владимир столица Великого
Владимирского княжения. С ХVI в. городом Владимиром управляют
воеводы, назначаемые из Москвы. В 1527 г. воеводой во Владимире на
Клязьме был князь Александр Васильевич Друцкий. В 1609 г. воевода во
Владимире - Василий (Граня) Бутурлин. Владимирский край в Смутное
время город Владимир в XVII веке30 января 1699 года по указу Петра I
земские старосты,        таможенные и кабацкие головы были переименованы
на голландский манер в земских бурмистров и таможенных и кабацких
бурмистров."
      />
      <Typography
        title="30 января 1699 года был издан указ об учреждении земских изб в городах.
Владимирский край в XVIII веке. 18 декабря 1708 г. царем Петром I был
издан Указ «Об учреждении губерний и о росписи к ним городов». Согласно
этому указу вся территория России была разделена на 8 губерний во главе с
губернаторами. В 1708 г. Владимир, Суздаль, Юрьев-Польский, Шуя,
Переславль-Залесский причислены к новоучрежденной Московской
губернии, а города Гороховец, Муром, Вязники к Казанской губернии.
Города с выборными бурмистрами теперь уже не тяготеют к Московской
Бурмистерской Палате. Они подчинены губернатору, всевластному сатрапу в
пределах своей губернии. С 1710 года Владимир стал центром одной из обер-
комендантских провинций Московской губернии. 24 апреля 1713 года было
предписано выбрать в каждой губернии 8-12 «ландраторов» в совет при
губернаторе."
      />
      <Typography
        title="С 1715 года Владимир - центр одной из ландратских долей Московской
губернии. См. Петровские преобразования. Ландраты не просуществовали и
пяти лет, были ликвидированы. Россию разделили на 50 провинций. Во главе
каждой провинции поставлен воевода, которому прежние областные
правители, ландраты, должны сдать все дела."
      />
      <PageTitle title="ВЛАДИМИРСКАЯ ПРОВИНЦИЯ" />
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/2/2-7.jpg)",
        }}
      />
      <Typography
        title="9 июня 1719 года город Владимир стал центром Владимирской провинции
Московской губернии. Обширная Московская губерния в 1719 году 29 Мая
была разделена на 9 провинций. В Московскую губернию вошли следующие
провинции: Суздальская (30 879 дворов); Юрьев-Польская (19 990 дворов) с
городами Юрьев-Польский, Шуей и Лухом; Владимирская (28 000 дворов) с
городами Владимиром, Муромом и Гороховцом; Переславль-Залесская
(28345 дворов) с городами Переславль-Залесским и Ростовом. Дворцовыми
селами в XVIII веке были Меленки, Вязники и Ярополч. Вотчина
императорского дома Александрова слобода (ныне г. Александров) при
Екатерине I находилась во Владимирском уезде, а при Елизавете Петровне -
в Переславль-Залесском уезде; в 1778 году она приписана в конюшное
ведомство."
      />
      <Typography
        title="Каждая провинция делилась на дистрикты. Во Владимирской провинции
Московской губернии появилось четыре дистрикта: Владимирский,
Вязниковский, Гороховецкий, Муромский. Термин дистрикт практически
применялся недолго, сменен был более знакомым термином уезд.
Как указывали еще дореволюционные исследователи, по смыслу указа 1719
года «провинция должна была стать высшим областным делением, но этот
указ не отменял прежних губерний. Это было обусловлено необходимостью
существования губерний в качестве военных округов. Провинциальный
воевода не был подчинен губернатору и только иногда становился в
известное ему подчинение как к военному начальству, например, по делам о
рекрутском наборе». В 1744 г. указом Императрицы Елизаветы Петровны
восстановлена Владимирская епархия. В 1750 г. Была
учреждена Владимиpская духовная семинаpия. В 1753 г. была учреждена
Духовная Семинария в Переславле-Залесском. 26/15 декабря 1763 года
Указом Екатерины II утверждены генеральные штаты провинций, согласно
которым в состав провинциальной канцелярии вводилась должность
прокурора."
      />
      <Typography
        title="В 1763 году с разделением Сената на шесть департаментов генерал-прокурор
был официально прикреплен к первому, в котором решались наиболее
важные государственные вопросы. Прокуратура фактически стала высшим
органом государственного управления. Возрос статус местных прокуроров.
В соответствии с указом в апреле 1764 года прокурором Владимирской
провинциальной канцелярии был назначен Иван Иванович Дурнов. Так
начала свою историю прокуратура во Владимирской губернии, а затем и
области."
      />
    </FlexColumn>
  ),
};
