import React from "react";
import { Typography } from "../../components/shared/Typography/Typography";
import { ButtonVariation } from "../../components/shared/Button/ButtonTypes";
import { Button } from "../../components/shared/Button/Button";
import { PageWrapper } from "../../components/shared/PageWrapper/PageWrapper";
import { FlexColumn, FlexRow } from "../../components/shared/SharedStyle";
import { LogoLion, LogoAcacia, EmailLink } from "./MembershipStyle";

export const Membership = () => {
  return (
    <PageWrapper title="ПОДАТЬ ПРОШЕНИЕ">
      <FlexColumn>
        <Typography
          title="Масонство – братство, чьими основными принципами являются братская
любовь, свобода волеизъявления, помощь ближнему, милосердие и
стремление к поиску истины. Выражаясь более кратко, цель масонства
«сделать хорошего человека еще лучше»."
        />
        <Typography
          title="Возможно, Вас заинтересовало
масонство, потому что вам рассказал о нем друг или вы где то о нем
прочитали, сделав получение информации о вольных каменщиках, целью
личного исследования. Может случилось и так, что у Вас есть знакомые
масоны, они помогут в шагах, которые необходимо предпринять, чтобы стать
кандидатом для вступления, и будут сопровождать Вас на всех этапах пути к
Братству."
        />
      </FlexColumn>
      <FlexRow>
        <LogoAcacia />
        <FlexColumn>
          <Typography
            title="Если Вами движут сугубо личные мотивы, или же Вас никто не
может рекомендовать - напишите прошение на электронный адрес:"
            align="center"
          />
          <EmailLink
            href="mailto: crownedlion33@internet.ru"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button
              title="crownedlion33@internet.ru"
              variation={ButtonVariation.PRIMARY}
              onClick={() => {}}
            />
          </EmailLink>
          <Typography
            title="С Вами обязательно свяжутся и помогут в подаче прошения о вступлении."
            align="center"
          />
        </FlexColumn>
        <LogoLion />
      </FlexRow>
    </PageWrapper>
  );
};
