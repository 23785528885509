import React from "react";
import { getTypographyClassName } from "lib/helpers/typography";
import {
  ITypographyProps,
  TypographyColor,
  TypographyVariant,
} from "./TypographyTypes";
import { TypographyInner } from "./TypographyStyle";

export const Typography: React.FC<ITypographyProps> = ({
  title,
  color = TypographyColor.PRIMARY,
  variant = TypographyVariant.BASIC_TEXT,
  className,
  align = "justify",
}) => {
  const typographyClassName = `${getTypographyClassName({
    variant,
    color,
  })} typography-${align} ${className ?? ""}`;

  return (
    <TypographyInner className={typographyClassName}>{title}</TypographyInner>
  );
};
