import React from "react";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { AppRoutes } from "pages/AppRoutes";
import { appSelector } from "selectors/app";
import { selectTheme } from "selectors/theme";
import { ScrollToTop } from "./ScrollToTop";

import { GlobalStyle } from "components/shared/GlobalStyle/GlobalStyle";

function App() {
  const theme = appSelector(selectTheme);

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <BrowserRouter>
        <ScrollToTop>
          <AppRoutes />
        </ScrollToTop>
      </BrowserRouter>
    </ThemeProvider>
  );
}

export default App;
