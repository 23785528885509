import styled from "styled-components";
import {
  getItemPrimaryText,
  getItemPrimaryTextHover,
  getItemSecondaryTextHover,
} from "selectors/theme";

export const TabWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
`;

export const TabTitleWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;

  & .typography {
    white-space: nowrap;
  }

  & .typography:hover {
    color: ${getItemPrimaryTextHover};
  }

  & svg {
    fill: ${getItemPrimaryText};
    width: 18px;
    height: 18px;
  }

  & * {
    text-transform: uppercase;
  }
`;

export const TabBar = styled.span`
  width: 100%;
  height: 3px;
  margin-top: -3px;
  border-radius: 4px;
  background: ${getItemPrimaryText};
`;
