import styled from "styled-components";
import {
  getAppBackground,
  getItemPrimaryBackground,
  getItemPrimaryBackgroundHover,
} from "selectors/theme";

export const CardWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  min-width: 400px;
  max-width: 400px;
  min-height: 300px;
  max-height: 300px;
  cursor: pointer;
  background-color: ${getItemPrimaryBackground};
  border-radius: 10px;
  &:hover {
    background-color: ${getItemPrimaryBackgroundHover};
  }
  &:not(:hover) {
    & div *:not(:first-child) {
      display: none;
    }
  }
  @media screen and (max-width: 1568px) {
    min-width: 500px;
    max-width: 500px;
    min-height: 375px;
    max-height: 375px;
  }

  @media screen and (max-width: 1320px) {
    flex-direction: row-reverse;
    min-width: 600px;
    max-width: 600px;
    min-height: 450px;
    max-height: 450px;
  }
  @media screen and (max-width: 1120px) {
    flex-direction: row-reverse;
    min-width: 500px;
    max-width: 500px;
    min-height: 375px;
    max-height: 375px;
  }
  @media screen and (max-width: 600px) {
    flex-direction: row-reverse;
    min-width: 300px;
    max-width: 300px;
    min-height: 225px;
    max-height: 225px;
  }
`;
export const CardTitle = styled.div`
  display: flex;
  justify-content: space-around;
  width: 100%;
`;

export const CardImage = styled.img`
  position: absolute;
  top: 0;
  z-index: 1;
  width: 100%;
  min-height: 100%;
  max-height: 100%;
  border-radius: 10px;
  object-fit: cover;
`;

export const ContentWrapper = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100% - 40px);
  height: fit-content;
  background-color: ${getAppBackground};
  padding: 20px;
  border-radius: 10px;
  gap: 10px;

  @media screen and (max-width: 600px) {
    gap: 5px;
    padding: 7.5px 10px;
  }
`;
