import React, { useEffect, useState } from "react";
import { Typography } from "../../components/shared/Typography/Typography";
import { ImageWithLabel } from "../../components/shared/ImageWithLabel/ImageWithLabel";
import { PageWrapper } from "../../components/shared/PageWrapper/PageWrapper";
import { FlexColumn, FlexRow } from "../../components/shared/SharedStyle";

export const History = () => {
  const [isImageSmall, setIsImageSmall] = useState<boolean>(
    window.innerWidth > 700
  );

  useEffect(() => {
    const handleResize = () => {
      setIsImageSmall(window.innerWidth > 700);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <PageWrapper title="МАСОНСТВО НА ВЛАДИМИРСКОЙ ЗЕМЛЕ: ВОЗРОЖДЕНИЕ">
      <FlexColumn>
        <Typography
          title="Владимир-на-Клязьме был основан Владимиром Святославичем(Красное Солнышко)
примерно в 990-992 г. и явился одним из крупнейших экономических, политических и
культурных центров Залесской земли. В XII веке Мономахов, город занимал центральное
положение в структуре Владимира."
        />
        <FlexRow>
          <ImageWithLabel
            title="Вид на Успенский собор и г. Владимир с высоты птичьего полета"
            url="/history/uspensky.jpeg"
            width={isImageSmall ? "50vw" : "100%"}
          />
          <Typography
            title="В юго-западном углу, на самой высокой точке рельефа, хорошо просматриваемой с
территории посада и подступов к Владимиру, стоял белокаменный Успенский собор
(1158-1160). Его мощный объем, расширенный и обстроенный при Всеволоде III (1185-
1189), напоминал киевскую Софию, что говорило о преемственности власти и
перенесении духовного и политического центра из Киева во Владимир. Рядом
размещались епископский двор (1158-1160) с церковью Иоанна Предтечи (1194) и
княжеский каменный дворцовый комплекс (1195-1196). Княжеский дворец был связан
переходами с белокаменными лестничными башнями Дмитриевского собора (1195),
который был богато украшен резьбой, что подчеркивало светский характер дворцового
храма. Весь комплекс соборов и дворцов был объединен системами переходов в единую,
целостную композицию, отвечавшую тесному союзу княжеской власти и духовенства. "
          />
        </FlexRow>
        <Typography
          title="Вокруг высились каменные стены детинца (1194) с единственными воротами напротив
Успенского собора, на которых была выстроена церковь Иоакима и Анны. Эти ворота
выходили на новую торговую площадь города. Здесь князь Константин Всеволодович в
1218 г. поставил последнее каменное сооружение древней столицы, небольшую
одноглавую церковь Воздвижения Честного Креста. В восточном углу Мономахова
города, замыкая парадный комплекс каменных сооружений, располагался Рождественский
монастырь. Его белокаменный храм (1192-1196), сходный по пропорциям с Дмитровским
собором, был декорирован строже, чем придворный Дмитровский. Вся остальная
застройка Мономахова города была деревянной с большим количеством приходских
церквей. Общие условия развития Владимира были такими же, как многих других старых
городов Северо-Восточной Руси."
        />
        <Typography
          title="Окончательным же возвышением и всей последующей славой своей город обязан сыну
Юрия - Великому князю Андрею Юрьевичу Боголюбскому. Став со времени Андрея
Боголюбского стольным городом великого княжения, Владимир оставался им и после
монголо-татарского нашествия. При Андрее Боголюбском градостроительная схема
получила дальнейшее развитие: его основная княжеская резиденция, в отличие от
резиденции прежних князей, находилась еще дальше от города в Боголюбово, а сам
Владимир получил дробную трехчастную структуру, состоявшую из трех
самостоятельных районов с собственными центрами. Мономахов город, с епископским
двором и кафедральным Успенским собором, стал стараниями Боголюбского духовным
центром княжества. Владимир становится политическим центром Руси, а Андрей
Боголюбский - первым великорусским князем."
        />
        <Typography
          title="С 1252 по 1263 гг. во Владимире княжил Александр Ярославич Невский. В своё
княжение он старался загладить следы последнего татарского погрома в городе. В это
время князь Александр старался улаживать все проблемы с Ордой мирным способом; в
одной из таких дипломатических поездок в Орду он заболел и, на обратном пути, умер.
Александр Невский был похоронен в Рождественском монастыре Владимира "
        />
        <Typography
          title="Венский епископ И. Фабр в 1526 г. написал даже, что Владимир имеет «равную величину
с Москвой»."
        />
        <Typography
          title="Значение Владимира в событиях XIV-XV вв. в первую очередь определялось его
положением как центра великого княжения и митрополии до определенного времени
(Владимир перешел к московскому митрополиту с ликвидацией Владимиро-Суздальской
епархии в 1355 г. В 16 веке здесь был проложен этапный путь из Москвы в Сибирь, но
первые партии арестантов, закованных в кандалы, прошли по Владимирскому тракту еще
в 14 веке. Позже эту дорогу скорби и печали, по которой веками гнали в Сибирь
непокорных людей, боровшихся против самодержавия, с насилием правящих классов, в
народе прозовут «владимиркой»."
        />
        <FlexRow>
          <ImageWithLabel
            title="Храм Покрова Святой Богородицы на Нерли. Выдающийся памятник зодчества XII в. Один из символов не только Владимирской земли, но и России"
            url="/history/pokrova.jpeg"
            width="100%"
          />
        </FlexRow>
        <Typography
          title="История не стоит на месте и Век Просвещения-стал  одной из важнейших эпох в истории
европейской культуры, характеризующаяся развитием общественной, философской и
научной мысли. В основе этого мощного идейного движения лежали свободомыслие и
рационализм, а деятели Просвещения видели в знаниях мощный двигатель прогресса
всего человечества. Просвещение-безусловно значимый период в истории развития
европейского, а в последствии и русского общества, который стал продолжением
гуманистических идей эпохи Возрождения. Пика своего  расцвета, эта эпоха достигла во
Франции в XVIII веке, и ее идеи очень быстро распространились по странам Европы и в
России. Данное течение стало ответной реакцией на углубляющийся кризис абсолютной
монархии и феодализма, которые уже не могли удовлетворять потребности общества в
реальном познании окружающего мира и стремлении к знаниям. Образованным людям
того времени становилось тесно в рамках догматических идей и установленных кем то
требований, формировавшихся веками и служивших, в том числе, и рычагом подчинения.
Таким образом, в первой половине  XVIII века в Европе появляются идеи о преобладании
разума над слепой и догматичной верой. Новые тенденции в европейской культуре были
налицо и философия эпохи Просвещения дала огромный толчок развитию науки и оказала
влияние на весь ход мировой истории. Как и в остальной Европе,  на русское
Просвещение очень сильное влияние оказало Просвещение Франции, а одним из
основных направлений которого, явилось Русское масонство, представленное
Н.Новиковым, И.Шварцем, И.Елагиным, Н.Карамзиным, Р.И. Воронцовым."
        />
        <Typography
          title="Русское масонство обрело свое начало в первой половине 18 века. Большинство
масонских лож, что не удивительно, появляются в Санкт-Петербурге-столице не только
Российского государства, но и просвещенного свободомыслия. Однако следует отметить,
что масонство проникло и в российскую глубинку. Это было обусловлено назначениями
видных политических и общественных деятелей того времени на ответственные
руководящие посты в Российских губерниях, наличие во владении русской аристократии
обширных земель и родовых имений. Будучи представителями просвещенной Российской
аристократии они несли свет масонства в провинциальные города и губернии."
        />
        <FlexRow>
          <ImageWithLabel
            title="Воронцов Р. И. (1717-1783)"
            url="/history/vorontsov.jpeg"
            width={`${isImageSmall ? 20 : 50}vw`}
          />
          <FlexColumn>
            <Typography
              title="Одним из таких видных государственных и политических деятелей того времени, был
Роман Илларионович Воронцов. Роман Илларионович родился 17 (28) июля 1717
г.,однако точного места рождения история нам не оставила."
            />
            <Typography
              title="Говоря о Романе Илларионовиче Воронцове нельзя забывать, что он играл заметную роль
в жизни тогдашнего Российского общества и много лет являлся одним из активных
деятелей русского масонства. Его имя неотрывно связано с Владимирской землей, для
которой надо сказать, он сделал немало. Вообще, масонские идеи начали проникать в
Россию еще при Петре I, но свидетельство о существовании масонских лож относится к
1731 году. В 1747 году было предпринято первое правительственное расследование, с
целью выяснения сущности и целей масонского учения. В 1756 г. графом А.И.
Шуваловым было представлено императрице Елизавете Петровне показание Михаила
Олсуфьева о масонской ложе в Петербурге. В списке “гранметров и масонов” перечислено
35 лиц, среди которых первым стоял Р.И. Воронцов. Затем следуют имена будущих
видных Русских историков и литераторов того времени: князя М.М. Щербатова, И.
Болтина, Ф. Мамонова и П. Свистунова. В списке значатся офицеры кадетского корпуса,
аристократы самых знатных фамилий, местом службы которых были Преображенский,
Семеновский и Измайловский полки: князь Михаил Дашков, трое князей Голицыных,
Сергей Трубецкой, П. Бутурлин и другие."
            />
          </FlexColumn>
        </FlexRow>
        <Typography
          title="Известно, что Петр III учредил масонскую
ложу в Ораниенбауме и подарил петербургской ложе “Постоянство” дом, в котором
обязанности великого мастера исполнял Р.И. Воронцов, а членами ее были
преимущественно гвардейские офицеры и представители аристократии - люди изрядно
образованные и конечно разделявшие прогрессивные взгляды того времени."
        />
        <FlexRow>
          <ImageWithLabel
            title="Усадьба Р.И. Воронцова в селе Андреевском. Владимирская область."
            url="/history/manor-vorontsov.jpeg"
            width="100%"
          />
        </FlexRow>
        <Typography
          title="Позже, уже при Екатерине II, из диплома, данного при учреждении ложи “Муз” в 1772 г.,
видно, что великим мастером был Р.И. Воронцов, а провинциальным великим секретарем
- Василий Майков, известный поэт.
В протоколах ложи “Урания” (1гг.) личный состав провинциальной ложи представлен так:
великий провинциальный мастер - Е.П. Елагин, великий провинциальный мастер-Р.И.
Воронцов. В 1774 году в Петербурге открывается ложа “Скромности”, великим мастером
которой является Р.И. Воронцов."
        />
        <FlexRow>
          <ImageWithLabel
            title="Надгробие на месте захоронения Р.И. Воронцова в Дмитриевском соборе Владимира."
            url="/history/burial-vorontsov.jpeg"
            width={`${isImageSmall ? 25 : 50}vw`}
          />
          <FlexColumn>
            <Typography
              title="Скончался Р.И. Воронцов, в 1783 году и был погребен в
Дмитриевском соборе Владимира. Брат захоронен в соборе, не имевшем некрополя, из
уважения к заслугам графа и по его завещанию. Скульптурное надгробие поставили в
1804 г. его сыновья Александр и Семен. Аллегорические фигуры - «плакальщица» с
кипарисовой ветвью в руке, склонившаяся над урной и  мальчик с пеликаном - изваяны из
белого мрамора в Лондоне, где служил посланником, а затем министром, Семен
Романович. Пеликан один из символических знаков масонства, к которому принадлежал
Р.И. Воронцов. Еще в 1756 г. Романа Ларионовича указывали в качестве главы ложи
«Молчаливость», в которой кроме него числились представители лучших родов
российского дворянства. Впоследствии Р.Л. Воронцова и его сына Семена Романовича
указывали в качестве членов Великой Провинциальной ложи И.П.Елагина. Роман
Ларионович был членом лож «Муз», «Урания» и «Скромность»."
            />
            <Typography
              title="Фоном для скульптурной группы служит пирамида из серого мрамора, аллегория
вечности. Пирамида поставлена позже, во время ремонта собора в 1841 г., на средства
внука - Новороссийского губернатора М.С. Воронцова; изготовлена в московских
мастерских Витали и Кампиони.
Изначально, надгробие стояло у южной стены там, где под полом находится погребение.
В 1896 или 1906 г., в связи с появлением фресок на своде под хорами, надгробие было
переставлено к западной стене; в 2003 г. реставрировано. По стилю, одухотворенности и
качеству исполнения надгробие принадлежит к лучшим образцам мемориальной
скульптуры начала XIX в."
            />
            <Typography title="Эпитафия на надгробии гласит: " />
            <Typography
              title="«Почивающему графу Роману Ларионовичу Воронцову, генерал-аншефу, сенатору,
действительному камергеру, Владимирскому и Костромскому государеву наместнику,
орденов св. апостола Андрея, Александра Невского, св. Владимира, Польского Белого Орла
и св. Анны кавалеру, родившемуся в 1717 году июля 17 дня, скончавшемуся во Владимире
ноября 30 дня 1783 года. Надгробную сию поставили сыновья его, граф Александр и
Семен Воронцовы в лето 1804. Возобновил внук граф Михаил Воронцов в 1841 году.»"
            />
          </FlexColumn>
        </FlexRow>
        <Typography
          title="С открытием во Владимире наместничества в 1778 г. утверждается масонская ложа,
которой управлял секретарь Воронцова,П.И. Берг."
        />
        <Typography
          title="Петр Иванович фон Берг родился в 1749 году в Лифляндии в дворянской Лютеранской
семье. В 1768-1772 годах обучался в Московском университете. С 22 апреля 1772 «за
ревность и усердие в службе» был переведен переводчиком в Ревизион-коллегию.
В июле 1777 года получил чин коллежского секретаря.
В марте 1778 года, по указу Екатерины II было образовано Владимирское
Наместничество, а в 1778 году, Петр Иванович фон Берг, был определён адъютантом к
Р.И. Воронцову и исполнял при этом обязанности правителя канцелярии при учреждении
этих губерний. Масон, член-основатель и с 1778 года мастер стула ложи во Владимире,
которая входила в масонский союз под руководством И.П.Елагина. В сентябре 1780 года,
был назначен во Владимирскую гражданскую палату, где пользовался протекцией Р.И.
Воронцова, а с 2 мая 1791 года, стал председателем Гражданской палаты. Кроме того,
И.П. Берг был главным комиссаром Комиссии по разграничению Владимирской и
соседних с ней губерний, вёл следствие о злоупотреблениях чиновников при рекрутских
наборах.17 мая 1798 года был произведён в статские советники. В 1802 года, назначен
вице-губернатором Литовско-Гродненской (Гродненской) губернии. В 1804 года-
Екатеринославский губернатором, с производством в действительные статские советники.
В 1808 года -гражданский губернатор Подольской губернии. С 1809 до 1812 г. состоял
Могилёвским гражданским губернатором. Умер Петр Иванович, в 1813 году."
        />
        <FlexRow>
          <ImageWithLabel
            title="Дмитриевский собор во Владимире"
            url="/history/dmitrievsky.jpeg"
            width={isImageSmall ? "50vw" : "100%"}
          />
        </FlexRow>
        <Typography
          title="Как видно из вышесказанного Владимирская земля имеет и свою собственную историю
масонства. Существование в 18 веке ложи именно во Владимире, имеет глубокий
символический смысл: в древней столице, древнего государства  и в самом его центре,
зажегся свет свободы. Сегодня, Досточтимые Мастера Досточтимой Ложи «Белая
Акация», Великого Востока Франции, вдохновляемые самыми искренними чувствами,
задались благородной целью возродить масонство на Владимирской земле. Современные
Вольные Каменщики продолжают традиции своих предков и стремятся создать братское
общество, имеющее глубокие корни в российской истории и возрождающее в
современном мире традиции взаимопомощи, сохранения и изучения собственной истории,
саморазвития и  исследования философских и духовных тем."
        />
        <Typography
          title="Мы хотим повторить символический путь наших братьев -предшественников и так же как
они, зажечь огни новой ложи, под эгидой Великого Востока Франции на древней
Владимирской земле. Как и в 17 веке прогрессивные идеи просвещения вновь проделают
путь из Франции в Россию. "
        />
        <Typography
          title="Основой этого благородного начинания станет символический треугольник
«Коронованный Лев», название которого далеко не случайно.  Лев – как символ сильной
власти известен как родовой знак Владимирских князей еще с XII века. Именно он
являлся атрибутом, способным справиться с невзгодами феодальной раздробленности
Руси."
        />
        <Typography
          title="Изображение хищного зверя, как личный знак, связывают с великим князем
Владимирским Андреем Юрьевичем Боголюбским. При нем объединенное Владимиро-
Суздальское княжество стало одним из самых мощных на Руси и легло в основу
современной России. Подтверждения данного факта можно найти на фресках XII – XIII
веков в соборах Суздаля, Владимира и Юрьева-Подольского, а также на монетах более
позднего времени. В частности, на фасаде Георгиевского собора, сооруженного в 1234
году в Юрьеве-Подольском, помещен образ Георгия Победоносца с высоко поднятым
копьем и варяжским щитом, на котором можно наблюдать изображение родовой эмблемы
династии в виде «вздыбленного» льва.  Мы помним нашу историю и гордимся ей
.Поэтому именно лев, увенчанный короной стал гербом символического треугольника,
учреждаемым на Востоке древнего города Владимира. Продолжая и чтя старинные
традиции масонства на Владимирской земле, для герба треугольника был выбран девиз
рода Воронцовых, один из отпрысков которого впервые зажег огни ложи во Владимире: "
        />
        <FlexRow>
          <ImageWithLabel
            title="Девиз рода Воронцовых: «Semper Immota Fides» (Всегда непоколебимая верность)"
            url="/history/SIF.jpeg"
            width={`${isImageSmall ? 30 : 70}vw`}
          />
        </FlexRow>
        <Typography
          title="Идеи масонов о человеческом достоинстве, о любви к людям, о свободе совести, о
всеобщем равенстве, об уважении к самому себе и другим, бесспорно, оказывали и
оказывают благотворное влияние на передовое русское общество. "
        />
        <Typography
          title="Эти стремления в настоящий момент абсолютно не утратили своей актуальности. Масоны
всегда занимались благотворительностью, устройством больниц и аптек, школ и
типографий, изданием учебников и книг духовного содержания и распространением их, а
также покровительствовали литературным и научным трудам. Если вдуматься, то сама
идея масонства в России, существует «не для», а «вопреки». Вопреки гонениям, которым
подвергались братья и сестры на том или ином отрезке времени в истории, вопреки
слухам о черных мессах и сатанинских ритуалах, вопреки догмам и предубеждениям-оно
существует и будет существовать, пока человек свободный, способен мыслить и идти к
свету познания самого себя."
        />
      </FlexColumn>
    </PageWrapper>
  );
};
