import styled from "styled-components";
import {
  getItemPrimaryBackground,
  getItemPrimaryText,
} from "../../selectors/theme";
import { ReactComponent as AlarmSvg } from "assets/icons/alarm.svg";

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  overflow: hidden;
  gap: 40px;
  padding-bottom: 160px;
`;

export const MainImageContent = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  width: 460px;
  top: 220px;
  right: 80px;
  gap: 20px;

  @media screen and (max-width: 1320px) {
    top: 160px;
    right: 40px;
  }

  @media screen and (max-width: 1120px) {
    width: 330px;
    top: 180px;
    right: 70px;
  }
  @media screen and (max-width: 1000px) {
    width: 330px;
    top: 150px;
    right: 30px;
  }
`;

export const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  & > * {
    white-space: break-spaces;
  }
`;

export const Link = styled.a`
  text-decoration: none;
`;

export const Alarm = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: start;
  gap: 20px;
  margin: 0 140px;
  background-color: ${getItemPrimaryBackground};
  padding: 20px;
  border-radius: 10px;
  border: 1px solid ${getItemPrimaryText};

  @media screen and (max-width: 1120px) {
    margin: 0 40px;
  }
`;

export const AlarmIcon = styled(AlarmSvg)`
  min-width: 30px;
  max-width: 30px;
  min-height: 30px;
  max-height: 30px;
  & path {
    fill: ${getItemPrimaryText};
    stroke: ${getItemPrimaryText};
  }
`;
