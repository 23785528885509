import React from "react";
import { ArticlesContent } from "./articlesContent";
import { TypographyVariant } from "../../../components/shared/Typography/TypographyTypes";
import { Typography } from "../../../components/shared/Typography/Typography";
import { ImageWithLabel } from "../../../components/shared/ImageWithLabel/ImageWithLabel";
import { FlexColumn, FlexRow } from "../../../components/shared/SharedStyle";

export const article0: ArticlesContent = {
  id: 0,
  title: "Усадьба рода Воронцовых: историческое исследование",
  preview:
    "Первая встреча Братьев прошла 19 мая 2023 года, в родовом имении Воронцовых.",
  imageURL: "/articles/0/0-1.JPG",
  content: (isArticleImageSmall: boolean) => (
    <FlexColumn>
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/0/0-2.JPG)",
        }}
      />
      <Typography
        title="Первая встреча Братьев, составивших символический Треугольник
«Коронованный Лев» по единодушному их решению, прошла 19 мая 2023
года, в родовом имении Воронцовых. Встреча  именно в этом уголке
Владимирской области, является весьма символичной, поскольку отпрыски
этого славного рода всегда находились в гуще исторических событий
Государства Российского, а один из его представителей -Роман
Илларионович Воронцов, явился основателем первой масонской ложи на
Востоке города Владимира.     Наши исторические исследования и эта статья,
посвящены именно этой теме."
      />
      <ImageWithLabel
        title="Главное здание усадьбы рода Воронцовых"
        url="/articles/0/0-3.JPG"
        width="100%"
      />
      <Typography
        title="История Владимирской земли неотрывно связана с историей всего
Российского государства и ее знаменитых сынов, от Великих князей древней
Руси до наших дней. На территории нынешней Владимирской области в 18-
19 веках находились многочисленные родовые имения видных политических
и государственных деятелей Российской Империи. Одним из таких деятелей
был Михаил Илларионович Воронцов (1714-1767 г.г.),которому за активное
участие в дворцовом перевороте 1741 года, совершенного с целью
возведения на российский престол императрицы Елизаветы Петровны, было
пожаловано сельцо Андреевское (в настоящее время: село Андреевское,
Петушинского района Владимирской области). "
      />
      <FlexRow>
        <ImageWithLabel
          title="Михаил Илларионович Воронцов"
          url="/articles/0/0-4.jpg"
          width={`${isArticleImageSmall ? 20 : 50}vw`}
        />
        <Typography
          title={`В последствии, за весьма эффективную  дипломатическую деятельность, в 
1744 г. М.И. Воронцов, был возведен Карлом VII в графское достоинство 
Римской империи. Титул после присвоения, распространился и  на его 
братьев, так как  у М.И. Воронцова не было сыновей, а так же все их 
нисходящее потомство. В 1758 г. М.И. Воронцов был произведен в 
должность канцлера Российской империи. Должность канцлера появилась в 
России в петровские времена и после принятия имперского "Табеля о рангах" 
в 1722 году, канцлер стал чином первого класса, то есть высшим 
гражданским чином. За всю историю, только 12 человек удостоились этой 
должности и были канцлерами Российской империи. Этот государственный 
чин  просуществовал от Петровских времен до последних десятилетий 
правления Александра III. Именно с назначения Михаила Илларионовича на 
эту высшую государственную должность, началось стремительное 
возвышение всего рода Воронцовых.`}
        />
      </FlexRow>
      <FlexRow>
        <ImageWithLabel
          title="Роман Илларионович Воронцов"
          url="/articles/0/0-5.jpg"
          width={`${isArticleImageSmall ? 20 : 50}vw`}
        />
        <Typography
          title="После кончины М.И. Воронцова в 1767 г., имение Андреевское перешло к
его брату-Роману Илларионовичу Воронцову (1717-1783), с которым
неотрывно связано как  благоустройство так и реконструкция имения, а так
же начало строительства дворцово-паркового ансамбля. Общирные
окрестные земли перешли в собственность рода Воронцовых в качестве
свадебного приданого супруги Р.И. Воронцова-Марфы Ивановны Сурминой.
Вотчина Воронцовых включала три села и более двадцати окрестных
деревень. Центром родовой вотчины, было село Андреевское, где была
расположена контора вотчинного правления."
        />
      </FlexRow>
      <FlexRow>
        <ImageWithLabel
          title="Екатерина Романовна Воронцова-Дашкова"
          url="/articles/0/0-6.jpeg"
          width={`${isArticleImageSmall ? 20 : 50}vw`}
        />
        <Typography
          title="Роман Илларионович Воронцов, как и его брат, Михаил Илларионович-
будучи особо приближенным к императрице Елизавете Петровне, стал
одним из самых знатных и богатейших людей России. С восшествием на
престол императора Петра III, его положение при дворе значительно
укрепилось, поскольку его дочь Елизавета Романовна Воронцова, была
фавориткой нового Российского императора. Однако при Екатерине II он был
сначала в опале, а затем восстановив свое положение при дворе, в том числе,
благодаря дружбе императрицы с его дочерью-Екатериной Воронцовой, в
замужестве – Дашковой. Екатерина Романовна Воронцова-Дашкова-
знаменитая представительница рода Воронцовых, прославившая его в веках-
она, будущий президент Санкт-Петербургской Академии наук, президент
Императорской Российской Академии, видный деятель и представитель
Российского Просвещения."
        />
      </FlexRow>
      <Typography
        title="Одновременно с созданием Владимирского наместничества в 1778 году, Р.И.
Воронцов назначен первым Владимирским генерал-губернатором. Во время
его правления, им был утвержден регулярный план застройки города
Владимира, был произведен ремонт и реконструкция Золотых ворот, открыта
первая почтовая контора. Отдавая дань уважения к заслугам первого
Владимирского губернатора и по его завещанию, Р.И. Воронцов был
погребен в Дмитриевском соборе города Владимира."
      />
      <Typography
        title="Свое родовое гнездо Андреевское, Роман Илларионович отстраивал с
большим размахом, соответствовавшим его графскому титулу, должности и
общественному положению. Архитектурный комплекс в Андреевском
спроектировал и непосредственно руководил строительством, личный
архитектор Воронцовых – Николай Петрович фон Берк. Он же разработал и
первый регулярный план застройки Владимира, но уже будучи губернским
архитектором. По характеру застройки и организации пространства
Андреевское скорее напоминало город в миниатюре, а не родовую
дворянскую усадьбу. Усадьба, организованная в междуречье Пекши и
Нергели, образующие участок в виде большого полуострова, были чётко
выделены: главная площадь, улицы, дома многочисленной челяди, каменные,
хозяйственные постройки различного назначения. Все эти улицы, площади и
дворы-усадьбы, были вымощены диким камнем-булыжником. Особо следует
отметить, что в то время не каждый город мог похвастать наличием мощеных
улиц и площадей. Горожане ходили либо по земле, или довольствовались
деревянным настилом."
      />
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/0/0-7.JPG)",
        }}
      />
      <Typography
        title="Центральное место в архитектурном ансамбле усадьбы занимал трёхэтажный
каменный дворец, который был соединен с одноэтажным зданием,
образующим двор в виде каре. Над аркой, служившей парадным въездом во
двор, возвышалась ярусная башня, завершенная восьмигранным куполом и
шпилем."
      />
      <FlexRow>
        <img
          style={{
            width: isArticleImageSmall ? "40vw" : "100%",
            alignSelf: "center",
            content: "url(/articles/0/0-8.JPG)",
          }}
        />
        <img
          style={{
            width: isArticleImageSmall ? "40vw" : "100%",
            alignSelf: "center",
            content: "url(/articles/0/0-9.JPG)",
          }}
        />
      </FlexRow>
      <Typography
        title="Дворец в усадьбе Воронцовых поражал своими размерами и  роскошью,
которые могли соперничать  с лучшими дворцами Санкт-Петербурга. Во
дворце было около восьмидесяти комнат: гостиные, столовые, чайная
комната, курительная, библиотека, бильярдная, жилые комнаты и кабинеты
самого графа и его семьи. В одной из комнат дубовый паркет был особым:
при ходьбе по нему он издавал музыкальные звуки. Предметы убранства и
быта семьи Воронцовых  представляли значительную, как материальную так
и художественную ценность. В доме была огромная библиотека с архивом
семьи. Во дворце была огромная портретная зала с  сотнями полотен. По
особому  заказу, сделанному художнику Ф.С. Рокотову, были написаны
портреты всех представителей рода Воронцовых. "
      />
      <Typography
        title="По собственному проекту семейного архитектора Воронцовых, Николая
Петровича фон Берка, в усадьбе была построена  церковь Святого Андрея
Первозванного. Храм представлял собой пятиглавую церковь с помещением
трапезной и колокольней под шпилем и являлся образцом архитектуры эпохи
раннего классицизма. По приказу графа Р.И. Воронцова в архитектурный
проект фон Берка были включены, а в последствии  отстроены, богоугодное
заведение и школа."
      />
      <ImageWithLabel
        title="Церковь Святого Андрея Первозванного на территории усадьбы, построенная по проекту Н.П. фон Берка."
        url="/articles/0/0-10.JPG"
        width="100%"
      />
      <Typography
        title="После смерти графа Р.И. Воронцова  содержание и дальнейшую отстройку
имения продолжил его сын, Александр Романович Воронцов. Именно после
его вступления во владение, усадьба Андреевское, достигла наибольшего
расцвета. "
      />
      <FlexRow>
        <img
          style={{
            width: isArticleImageSmall ? "40%" : "100%",
            alignSelf: "center",
            content: "url(/articles/0/0-11.JPG)",
          }}
        />
        <FlexColumn>
          <Typography
            title="Александр Романович Воронцов, был одним из образованнейших
представителей аристократии своего времени, канцлером Российской
империи в 1802-1804 гг., занимал пост министра иностранных дел
Российской Империи и возглавлял государственную Комиссию по
составлению законов при правлении императора Александра I. Вместе с А.Н.
Радищевым он участвовал в разработке и подготовил проект первой
Российской Конституции. В 1797 г., за пять лет до совместной работы над
проектом Конституции, Радищев останавливался в имении Андреевское,  по
возвращении из сибирской ссылки."
          />
          <Typography
            title="Александр Романович имел давние дружеские отношения с опальным
писателем и всячески покровительствовал ему. В портретной галерее имения
Андреевское, был выставлен и портрет А.Н. Радищева."
          />
        </FlexColumn>
      </FlexRow>
      <FlexRow>
        <Typography
          title="Гостями в усадьбе Воронцовых пребывали и другие известные и
образованные люди своего времени., В 1784 и 1785 годах сюда приезжал
маршал А.В. Суворов. Он с интересом и свойственной ему живостью
интересовался устройством парка имения Андреевское и даже приказывал
управляющему своего владимирского имения в Ундоле (в настоящее время:
д.Ундол, Собинского района, Владимирской области), учиться обустройству
и обращаться за советами, к садовнику графа Воронцова.А.Р. Воронцов
организовал реконструкцию и ремонт интерьеров дворца. При нём
перестраивались и заново строились  жилые и хозяйственные постройки,
скотный двор был реконструирован в конюшню, создана каскадная система
прудов, плотины на реках Пекша и Нергель. На территории имения
сохранились фрагменты стены, которая определяла в то время пределы
усадьбы,  а так же дворовые постройки, в одной из которых в настоящее
время находится магазин и сельская почта."
        />
        <img
          style={{
            width: isArticleImageSmall ? "30vw" : "50vw",
            alignSelf: "center",
            content: "url(/articles/0/0-12.JPG)",
          }}
        />
      </FlexRow>
      <FlexRow>
        <ImageWithLabel
          title="Место в усадьбе, где находился сад и оранжереи"
          url="/articles/0/0-14.JPG"
          width={`${isArticleImageSmall ? 15 : 50}vw`}
        />
        <Typography
          title="Свое участие в улучшение и обновление усадьбы, внесла и дочь Романа
Илларионовича – княгиня Екатерина Воронцова-Дашкова. Она разработала
планировку парка: главная аллея разделяла его на две основные части –
регулярную и пейзажную. Кроме того, был разбит фруктовый сад с
оранжереями. Для сада и оранжерей постоянно покупались новые деревья и
кустарники."
        />
        <ImageWithLabel
          title="Сохранившиеся фрагменты внешней стены усадьбы"
          url="/articles/0/0-13.JPG"
          width={`${isArticleImageSmall ? "40vw" : "100%"}`}
        />
      </FlexRow>
      <Typography
        title="Граф А.Р. Воронцов, после отставки с государственной службы вернулся в
имение Андреевское, где 2 декабря 1805 г., скончался. Он был погребен в
церкви во имя Св. Апостола Андрея Первозванного на территории усадьбы
Андреевское. "
      />
      <Typography
        title="А.Р. Воронцов не был женат и не имел детей, поэтому после его смерти
усадьба Андреевское перешла во владение его племяннику, Михаилу
Семёновичу Воронцову (1782-1856), герою Отечественной войны 1812 г.,
впоследствии дослужившемуся до чина генерал-фельдмаршала. После
ранения в Бородинском сражении он отправился в своё имение для лечения.
Михаил Семенович привез с собой  50 раненых офицеров и более 300
человек младших чинов, за лечение которых он сам нес расходы."
      />
      <FlexRow>
        <img
          style={{
            width: isArticleImageSmall ? "40vw" : "100%",
            alignSelf: "center",
            content: "url(/articles/0/0-15.JPG)",
          }}
        />
        <img
          style={{
            width: isArticleImageSmall ? "40vw" : "100%",
            alignSelf: "center",
            content: "url(/articles/0/0-16.JPG)",
          }}
        />
      </FlexRow>
      <Typography
        align="center"
        title=" Мемориальная доска на стене главного здания усадьбы"
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
      <Typography
        title="Будучи в 1823-1844 гг. назначенным генерал-губернатором Новороссии и
Бессарабии, М.С. Воронцов начал строительство дворцов в Алупке и Одессе.
Большое количество крепостных мастеров-строителей и отделочников, а
также лучшие предметы интерьера усадьбы :картины, мебель, хрусталь- из
Андреевского были вывезены в эти дворцы, а имение постепенно стало
пустеть и приходить в упадок. Зимой 1842 года вымерз фруктовый сад,
прекратила существование оранжерея, где росли пальмы, абрикосы, лимоны,
персики. Без должного ухода зарос и одичал парк."
      />
      <Typography
        title="Последним частным владельцем усадьбы, был Илларион Иванович
Воронцов-Дашков (1837-1916), министр Императорского двора и уделов,
наместник на Кавказе в 1905-1916 гг. Во владении семьи Воронцовых имение
Андреевское находилось до 1917 г., когда его последней владелицей была
вдова И.И. Воронцова-Дашкова – Елизавета Андреевна Воронцова-Дашкова."
      />
      <FlexRow>
        <img
          style={{
            width: isArticleImageSmall ? "40vw" : "100%",
            alignSelf: "center",
            content: "url(/articles/0/0-17.JPG)",
          }}
        />
        <img
          style={{
            width: isArticleImageSmall ? "40vw" : "100%",
            alignSelf: "center",
            content: "url(/articles/0/0-18.JPG)",
          }}
        />
      </FlexRow>
      <Typography
        title="После 1917 года,  родовое имение Андреевское было национализировано. В
храме Андрея Первозванного был организован сельский клуб, а затем он
использовался как автомобильный гараж. В усадебных постройках с 1920-х
годов размещались различные учреждения, в том числе туберкулёзный
санаторий. В годы Великой Отечественной войны здесь был  военный
госпиталь, а после окончания войны –в усадьбе  вновь помещался санаторий.
За несколько десятилетий было произведено много перестроек, возникли
новые постройки, нарушившие историческую архитектурную структуру
всей усадьбы."
      />
      <Typography
        title="Нам было немого грустно покидать это красивое, тихое место, где каждая
постройка и каждый камень, поистине свидетель истории. "
      />
    </FlexColumn>
  ),
};
