import React from "react";
import { ArticlesContent } from "./articlesContent";
import { TypographyVariant } from "../../../components/shared/Typography/TypographyTypes";
import { Typography } from "../../../components/shared/Typography/Typography";
import { FlexColumn } from "../../../components/shared/SharedStyle";
import { PageTitle } from "../../../components/shared/PageTitle/PageTitle";

export const article3: ArticlesContent = {
  id: 3,
  title: "Владимирская губерния",
  preview:
    "7 ноября 1775 г. при Екатерине II было издано «Учреждение для управления губерний Российской империи»",
  imageURL: "/articles/3/3-4.jpg",
  content: (isArticleImageSmall: boolean) => (
    <FlexColumn>
      <Typography
        title="7 ноября 1775 г. при Екатерине II было издано «Учреждение для управления
губерний Российской империи», по которому Россия была разделена на 31
наместничество, 9 губерний и одну область. «Учреждение для управления
губерний Всероссийской империи» вводилось постепенно: первые 28 глав
были подписаны императрицей 7 ноября 1775 года, а три последние главы - 4
января 1780 года. Реформа провела разукрупнение губерний, их число
практически удвоилось. Каждая губерния делилась на уезды; промежуточная
единица - провинция - была ликвидирована. Основу деления на губернии
составляли задачи налоговой и карательной политики. Каждая губерния
должна была иметь 300-400 ревизских душ, а уезд - 20-30 тысяч ревизских
душ."
      />
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/3/3-1.png)",
        }}
      />
      <Typography
        title="Владимирская губерния - административно-хозяйственная единица с
центром в городе Владимире - была образована по именному Указу
Екатерины II 2 марта 1778 года. По этому указу надлежало образовать по
«примерному расписанию» 13 уездов, наименования которых не указаны.
Екатерина II генерал-губернатором Владимирской губернии назначила графа
Р.И. Воронцова. «Нашему Генералу Графу Воронцову.Мы почитая за благо
учредить вновь Володимерскую Губернию Всемилостивейше определяем
Вас в оную Генерал-Губернатором, и поручаем Вам оную Губернию не
упуская времени объехать, и по данному от Нас Вам примерному
росписанию оной на тринадцать уездов на месте удобность их
освидетельствовать, и как о сем, так и какие вновь Города дляприписания к
ним уездов назначить нужно будет, Нам самолично представить. Екатерина.
2 Марта 1778. С.-Петербург».8 мая 1778 года первый владимирский генерал-
губернатор, граф Роман Илларионович Воронцов находился в
инспекционной поездке по только что учрежденной губернии. Это было
необходимо для организации губернии по «примерному росписанию», то
есть по примерному плану. Роман Воронцов должен был, «не упуская
времени», объехать губернию и освидетельствовать, какие города удобны
«для приписания к ним уездов». А о результатах доложить императрице
Екатерине II."
      />
      <PageTitle title="ВЛАДИМИРСКОЕ НАМЕСТНИЧЕСТВО" />
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/3/3-2.jpg)",
        }}
      />

      <Typography
        title="Группа из двух-трех губерний образовывала наместничество. Некоторые
исследователи на основании анализа территориальных гербов полагают, что
наместничество представляло собой административную надстройку над
губернией с целью контроля центром местной власти. В ряде документов, в
том числе официальных указах, времен царствования Екатерины II для
обозначения данных административно-территориальных единиц термины
«губерния» и «наместничество» используются как синонимы.
Указом Екатерины II от 1 сентября 1778 года было открыто Владимирское
Наместничество, которое просуществовало до 1796 года.
Во Владимирское наместничество в разные годы входили Владимирская,
Тамбовская, Пензенская, Костромская губернии.
Высшей властью в наместничестве обладал генерал-губернатор («государев
наместник»), управлявший, как правило, несколькими губерниями (обычно
двумя) и не столько занимавшийся конкретной работой по управлению
губерниями, сколько осуществляя общее политическое руководство.
«Должность Государева Наместника, или Генерал-Губернатора есть
следующая; строгое и точное взыскание чинить со всех ему подчиненных
мест и людей о исполнении законов и определенного их звания и
должностей: но без суда да не накажет никого; преступников законов и
должностей да отошлет, куда по узаконениям следует для суда, ибо
Государев Наместник не есть судья, но оберегатель Императорскаго
Величества изданнаго узаконения, ходатай за пользу общую и Государеву,
заступник утесненных и побудитель безгласных дел. Словом сказать, нося
имя Государева Наместника, должен он показать в поступках своих
доброхотство, любовь и соболезнование к народу... Государев Наместник,
или Генерал-Губернатор во время пребывания своего в наместничестве,
имеет для конвоя своей особы двадцать четыре человека легкой конницы с
одним Подпоручиком, ему же даются два Адьютанта, да дворянство
наряжает для почести его молодых дворян с каждого уезда по одному
человеку, которых Генерал-Губернатор отпускает по своему
благоусмотрению. Когда Государев Наместник, или Генерал-Губернатор в
наместничестве ему вверенном пребывание свое имеет, тогда производится
ему по пяти сот рублей на месяц столовых денег».
Первым генерал-губернатором Владимирского и Костромского
Наместничества был назначен уже престарелый Роман Илларионович
Воронцов (1707-1783 гг.), «Генерал-Аншеф, Сенатор, Действительный
Камергер и орденов Андрея, Александра Невского, Владимира и польского
Белого Орла кавалер», как значится на его надмогильном памятнике в
Дмитриевском соборе, где он погребен. Наместные генерал-губернаторы:
Воронцов Роман Илларионович (граф, генерал-аншеф) 1778-1783 гг.
Алексей Алексеевич Ступишин 1782-1783 гг. Был в течение нескольких
месяцев наместником, и не оставил заметного следа в истории города и
губернии. Граф Салтыков Иван Петрович (генерал-аншеф) 1784-1787 гг.
Заборовский Иван Александрович (генерал-поручик) 1787-1796 гг. Генерал-
губернаторы назначались императрицей из высших сановников, из людей,
близких к императорскому дому."
      />
      <Typography
        title="В каждую губернию был назначен свой губернатор, который подчинялся
наместному генерал-губернатору (в 1781 году было 40 губерний, которые
распределялись между 19 наместными генерал-губернаторами).
Владимирская губерния была разделена на 14 уездов. «…стать
Володимирской губернии, из четырнадцати уездов, а именно:
Володимирскаго, Суздальскаго, Переславльско-Залескаго, Юрьевско-
Польскаго, Шуйскаго, Муромскаго, Гороховскаго, Александровскаго,
Киржачскаго, Покровскаго, Судогодскаго, Ковровскаго, Вязниковскаго и
Меленковскаго… В каждом уезде, или округе учреждается уездный, или
окружный суд. В уездном, или окружном суде заседают уездный или
окружный судья и два заседателя. При каждом уездном суде учреждается
место под названием: дворянская опека. В дворянской опеке председает
уездный дворянский Предводитель, и заседают уездный судья и его
заседатели. В каждом уезде, или округе учреждается нижний земский суд. В
нижнем земском суде заседает земской исправник, или капитан, и два или
три заседателя, смотря на обширность уезда. В каждом уезде или округе
определяется уездный казначей один, землемер присяжный один, подлекарей
два и лекарских учеников два». Отличие этой реформы состояло в открытии
новых уездных городов из числа крупных торговых селений. К семи древним
городам Владимирского края: Владимиру, Гороховцу, Мурому, Переславль-
Залесскому, Суздалю, Шуе и Юрьев-Польскому - присоединились еще семь,
образованных из государственных селений и слобод: Александров, Вязники,
Киржач, Ковров, Меленки, Покров, Судогда.«В каждом уездном городе, где
нет Коменданта, определяется городничей, где же есть Коменданты, то
поручается им исполнение нижеписаннаго в сей главе.
Городничему поручается: 1. Городская полиция, или благочиние, 2.
привидение во исполнение в действие повелений правления, решений палат и
прочих судов. Городничей не судья, но долженствует во первых иметь
бдение, дабы в городе сохранены были благочиние, добронравие и порядок;
второе, чтоб предписанное законами полезное в городе исполняемо и
сохраняемо было, в случае же нарушения онаго, городничей по состоянию
дела, не смотря ни на какое лице, всякому напоминать может о исполнении
предписаннаго законом: а в случае непослушания даст о том знать судебному
месту для суждения виновнаго; третие, городничей в городе право имеет
привести в действие повелении правления, решения палат и прочих судов.
Нравоучительное наставление городничему. Городничему надлежит
отправлять должность свою с непоколебимою верностью и ревностию к
службе Императорскаго Величества, с доброхотством и человеколюбием к
народу, с с осторожною кротостию без ослабления во всех делах, и с
непрестанным бдением, дабы установленный порядок всеми и каждым в
городе сохранен был в целости…
О смотрении за мерами и весами. За мерами и весами в городе имеет
городничей обще с городовым Магистратом смотрение.
О свидетельстве прилипчивых болезней, и о лечении больных. Буде (от чего
Боже сохрани) в городе окажутся на людях прилипчивыя болезни, яко то
горячки с пятнами, кровавые поносы и другия тому подобныя болезни, о том
городничей освидетельствовать велит немедленно доктору, или лекарю при
двух свидетелях, и буде подлинно таковые болезни по свидетельству явятся,
то для предупреждения прилипчивости городничей отлучить велит здоровых
от больных так, чтобы сообщения одни с другими не имели, пользование же
и излечение поручает врачам. Сам же о болезни уведомляет не мешкав
наместническое правление и Генерал-Губернатора, или Правителя
наместничества с ясным докторским, или лекарским описанием как болезни,
так и где кому и от чего она приключилась. Городничей в опасном случае из
города не отлучается. Городничей ни в каком опасном случае город не
покидает под опасением лишения места и чести.
О бытии Магистратскому члену по делам купецким. Городничей, когда что
ему исполнить следует по его должности в делах до купца, или мещанина
касающихся, да призовет члена городоваго Магистрата, который
Магистратом особо для того назначен и всегда на лицо быть должен…»
«По городам и посадам старосты и судьи словеснаго суда остаются на
прежнем основании. Ратушам быть только по посадам, и выбирать в них
людей чрез всякие три года по балам, во малых же посадах, где менее пяти
сот душ, дозволяется выбирать по одному бургомистру и по два ратмана, а в
больших посадах выбирать число бургомистров и ратманов против городов.
В городах остаться имеют городовые Магистраты, в оных присутствовать
двум бургомистрам и четырем ратманам, из коих трем, то есть одному
бургомистру и двум ратманам дозволяется по очереди быть в отлучке.
При каждом городовом Магистрате учреждается городовый сиротский суд. В
городовом сиротском суде председает городской глава и заседают два члена
городоваго Магистрата, и городовый староста. Как выбирать бургомистров и
ратманов. Бургомистры и ратманы выбираются городовым купечеством и
мещанством чрез всякие три года по балам.
По окончании службы дать похвальный лист, и где иметь место и проч. По
окончании службы старост, судей словесного суда, ратманов и бургомистров
(буде справедливостию и добропорядочными поступками то заслужили) дать
старостам и судьям словеснаго суда от городовых дел, а ратманам и
бургомистрам от общества похвальный лист. Когда же лист получать, тогда
иметь им в городском обществе после бывших в Магистрате людей перьвое
место; бургомистров же отличать названием степенных, и в другия нижния
службы их не выбирать.  Унизить должностию запрещается, а оставить при
оной и повысить из нижней в высшую дозволяется. По окончании службы
старост, судей словеснаго суда, ратманов и бургомистров дозволяется
городовому обществу имена их балотировать с прочими, но запрещается
того, который похвальный лист имеет, унизить должностью, то есть из
бургомистров в ратманы, из ратманов в судьи словеснаго суда определить.
При той же должности кого оставить, или должностию повысить, от воли
городоваго общества зависит.Запрещается перенос из городоваго
Магистрата, или ратуши в губернский Магистрат в деле ниже двадцати пяти
рублей. Перенос дела из городоваго Магистрата, или ратуши в губернский
Магистрат запрещается, буде тяжба идет о деле, котораго настоящая цена
ниже двадцати пяти рублей…О заседании. Городоваго Магистрата, или
ратуши члены, будучи всегда на лицо, могут присутствовать безпрерывно,
кроме табельных дней…»."
      />
      <img
        style={{
          width: `${isArticleImageSmall ? 20 : 60}vw`,
          alignSelf: "center",
          content: "url(/articles/3/3-3.jpg)",
        }}
      />
      <Typography
        title="Гражданские губернаторы:Самойлов Александр Борисович (статский
советник, действительный статский советник) 1778-1787 гг. Лазарев Петр
Гаврилович (статский советник, тайный советник) 30.07.1787-19.12.1796
Вице-губернаторы:Ухтомский Дмитрий Михайлович (князь, генерал-майор)
1778-1785; Ярцов Аникита Сергеевич (горный инженер, действительный
статский советник) 1785-1796 «Учреждение губернскаго Магистрата. В
каждом наместничестве учреждается губернской Магистрат, и буде
обширность наместничества того требует, то дозволяется учредить в
наместничестве более одного губернскаго Магистрата. В губернском
Магистрате заседает первый и вторый Председатель и шесть заседателей.
Учреждение совестнаго суда. В каждом наместничестве учреждается по
одному суду под названием: совестный суд. В совестном суде председает
судья совестного суда того наместничества, и заседают по дворянским делам
двое дворян, по городовым делам двое граждан, по расправным делам двое
поселян»."
      />
      <Typography
        title="«О председателе и заседателях наместническаго правления. Генерал-
Губернатор есть председатель правления, с ним заседает Губернатор с двумя
губернскими Советниками; когда же Генерал-Губернатор и Губернатор вне
губернии бывают, тогда Вице-Губернатор заступает место Губернатора.
Власть Наместнического правления. Правление Наместническое есть то
место, которое управляет в силу законов именем Императорскаго Величества
всею губерниею, обнародывает и объявляет по всюды в подчиненных оному
областях законы, указы, учреждения, повеления и приказания
Императорскаго Величества, и выходящия из Сената и из прочих
государственных мест на то власть имеющих. Должность Наместническаго
правления. Наместническое правление бдение свое прилежно простирает,
дабы законы были везде исполняемы, взыскание чинить со всех
непослушных, роптивых, ленивых и медлительных, пеню на них налагает, и
буде не исправляются, то оных к суду отсылает, тщание имеет, дабы
учреждения полицейския, или благочиния и торговли сохраняемы и
исполняемы были с полностью. Все непорядки законам противные
заблаговременно прекращает, прилежное старание имеет о установлении,
утверждении и сохранении в ненарушимости всякаго рода благонравия,
порядка, мира и тишины не токмо в городах, селах и деревнях, но и во всех
подчиненных той губернии землях и водах, так же и на дорогах во оной
лежащих. О делах до губернскаго правления надлежащих. В наместническое
правление взносятся, производятся и отправляются в оном все дела
исполнительныя, и скраго отправления, или приказания требующие, так же и
те, о которых противоречия, или спора быть не может, как например:
подписанные должником счеты, или вексели, или конракты ясные и явные,
по которым в срок платеж не воспоследовал. По таковым делам жалобы
вносятся в губернское правление, и от онаго делается понуждение, буде же
какое дело окажется не безспорно, и некоторому сомнению подлежащее,
тогда отсылается для разобрания спорющихся в те места, куда надлежит.
О наложении ареста и понуждении подчиненных мест. Наложение ареста на
имение, или на часть онаго за долги по приговору судебнаго места, есть дело
губернскаго правления, туда же следуют и прошения о проволочке в
подчиненных местах, и понуждение делается от наместническаго правления
по рассмотрению обстоятельств. Кто же наместническим правлением не
доволен, тот жалобу свою приносить имеет в Сенат.
В случае дел важных или чрезвычайных, или при получении новаго
общенароднаго узаконения, Государев Наместник призывать может палату
уголовнаго суда, палату гражданскаго суда, и казенную палату для уважения
дел обще с губернским правлением. При случае же новаго общаго
узаконения, буде закон в чем не удобен усмотрится, то дозволяется им
вообще единогласно делать свои представления в Сенат, а в случае
подтверждения от верьховной власти непременное и безмолвное исполнение
последовать имеет."
      />
      <Typography
        title="О Правителе, или Губернаторе. Во время отлучки из наместничества
Государева Наместника, Правитель или Губернатор отправляет свою
должность по данным Губернаторам наказам, и отправляет переписку с
прилежащими к его губернии соседними внутренними и внешними
провинциями. О пограничных же переписках Губернаторов, так как и
Генерал-Губернатор уведомляет коллегию Иностранных дел.
О Советниках губернскаго правления. Два Советника определяются в
губернское правление для вспоможения Губернатору. Они разсуждением
своим уважают дело, и по том исполняют положения Губернаторския, буде
же случится, что Губернаторския приказания не соответствуют пользе
общей, или службе Императорскаго Величества, или нарушают узаконения, и
Губернатора разсуждениями от того отвратить им не можно, тогда
Советники долженствуют внести в правление письменно свое мнение, и
Генерала-Губернатора и Сенат о том уведомить; но приказаний
Губернаторских отменить не могут, и по оным исполнять обязаны». В 1778 г.
епископская кафедра была перенесена в Суздаль. В 1798 г. епископская
кафедра вновь была возвращена во Владимир. По указу 1779 г. 22 января в
соседние города: Меленки, Вязники, Гороховец, Ковров и Шую, для
сношения по делам Губернского Начальства из Владимира и обратно, была
учреждена временная почта. В городе Владимире, как в центре
наместничества оказались сосредоточенными многочисленные уже новые
учреждения всего наместнического, уездного и городского управления,
состоявшие в большинстве из дворян и зажиточного купечества.
Важнейшими из этих учреждений были: губернское правление, казенная
палата, палаты гражданского и уголовного суда, губернский прокурор с его
помощниками - стряпчими, приказ общественного призрения и совестной
суд. Последовавшими затем 21 апреля жалованной грамотой дворянству и
грамотой на права и выгоды городам и самим «Городовым Положением» еще
более расширены были сословные права дворянства, составившего
общественную верхушку города и губернии в лице их дворянских
депутатских съездов и собраний. Городские жители были разделены на 6
разрядов: 1) «настоящие городовые обыватели» (владельцы домов и земли в
городе - лица всех сословий), 2) купцы всех 3 гильдий, 3) цеховые
ремесленники, 4) иногородние и иностранные гости, 5) именитые граждане -
дважды служившие по выборам и 6) посадские, которые «промыслом,
рукоделием или торговлей кормятся». Цеховые ремесленники и посадские
обыватели были объединены под общим наименованием «мещане». Сам
город стал управляться шестигласной городской думой. Подготовительными
к введению первого Городового Положения были: указ Сената продавать
застроенные земли в городе с зачислением сумм и переходу городу и
горожанам значительного количества монастырских и церковных там земель.
В частности такому переходу и последующей застройке подвергся
принадлежавший до того Николо-Златовратской церкви и примыкавший к
ней земельный участок размером ... кв. саж. вдоль Большой Дороги. Для
соответствующего распределения жителей города по различным сословиям, а
в зависимости от этого и для расселения их в городах последовали указы от
25 февраля 1782 г. о «написании находящихся в губерниях рассыльщиков,
каменщиков, кузнецов, дворовых садовников и тому подобных, не имеющих
земель, по желанию их в купечество, мещанство или в государственное
крестьянство». Ту же цель преследовал и указ от 22 марта 1782 г.,
дозволявший крестьянам и ямщикам, занимающимися торгами или
промыслами записаться в купечество или мещанство. Наконец, указом от 8
июля 1782 г. на все города империи был распространен указ Сената от 28
июня того же года о построении рынков. Им предписывалось рынкам быть в
каждом городе и в каждой части его. «На рынке развести лавки овощные,
мыльные, мучные, свечные, сальные, восковые, зеленые, охотные, курьяные,
мясные, рыбные и проч. Места для постройки новых лавок на этих новых
рынках отводить мещанам и купцам преимущественно малоимущим, но
никак более одного места не отводить. Для шерстяных, лоскутных, щепяных,
кроватных, тележных, лапотных и других тому подобных лавок, коим
безопасность от огня и благолепие города возбраняют быть внутри оного...
отвести места за городом. В прочих рынках держать все сии товары
воспретить». Этим же указом разрешалось всем купцам иметь в своих домах
лавки и в них торговать, а также производить торговлю и в лавках на
Гостином Дворе, построенных своих собственных или наемных."
      />
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/3/3-4.jpg)",
        }}
      />
      <Typography
        title="31 марта 1781 г. был высочайше утвержден план по переустройству города
Владимира за немногими исключениями соответствующий нынешнему
расположению его улиц и площадей. В этом плане во всей реконструкции
Владимира нашли отражение те новые градостроительные принципы,
которые были довольно последовательно применены тогда при
восстановлении и реконструкции других древних русских городов (Тверь,
Ярославль, Кострома, Екатеринославль и проч.): план города должен быть
правильным и симметричным, его площади просторны, улицы широкие и
прямые. Города застраивался «сплошною фасадою», т. е. смежными один с
другим домами, что создавало архитектурное единство улицы и вносило в
них известную упорядоченность. Регулировалась высота домов и ширина
улиц. Город получал четкие границы. В центре допускалось исключительно
каменное строительство, посильное только дворянству и купечеству. В плане
города приобретала особенное значение центральная площадь с
расположенными на ней административными зданиями. В 1780 г. была
отведена земля под кирпичные заводы купцам Петровским, Гордееву,
Коновалову, Ульянову, Лазареву, Льву Алексеевичу Кайсарову, Михаилу
Ивановичу Масолову. Эти кирпичные карьеры сохранились еще до
последнего времени налево от выезда из города на Юрьевскую заставу.
Такой центральной площадью по плану перепланировки Владимира должно
было явиться «плац-парадное место», располагавшееся от нынешнего
Пушкинского парка до Музея.
С учреждением Владимирской губернии Екатерина II послала генерал-
губернатору Воронцову Р.И. повеление выделить из статс-конторских
доходов 12 тысяч рублей для строения помещений новых Присутственных
мест. Указом Правительствующего сената аппарат губернской власти во
Владимире был открыт в декабре 1779 года.В то время часть
Присутственных мест размещалась в строениях бывшего Богородицкого
монастыря, переведенная сюда из кремля, а часть - в архиерейском доме,
освободившимся в связи с переездом архиерея в Суздаль.Торжественная
закладка «палаты» - Губернских Присутственных мест произошла в четверг,
28 августа 1785 г. Для этого строения было заготовлено кирпича 209460 шт.,
да на цоколь белого камня 70800 шт. «подряжено сделать нынешним летом
один миллион кирпича, да приготовлено извести 4258 четвертей, бутового
камня 123 саж». Для изготовления кирпича по словесному приказанию
губернатора Воронцова архитектору фон Бергу был построен кирпичный
завод с двумя печами из восьми сараев. (Дело губернского архива № 598,
неопубликованные записки В.В. Косаткина). Сооружение здания
присутственных мест было закончено в 1790 г. и обошлось в 122 257 руб. 10
коп.Для устройства самой площади стоявшие здесь здания, образовавшие в
XVII в. Дмитриевскую улицу, а затем сгоревшие в пожар 1719 г. и вновь
восстановленные улицей того же названия, были снесены. Это были
обывательские дома, и для них было отведено место в районе уже Троицкой
улицы. (Дело губернского архива № 634, из неопубликованных записей В.В.
Косаткина)."
      />
      <Typography
        title="Другая большая городская площадь была отведена в Китай-городе для
построения Гостиного Двора - того самого, который существует, правда, в
несколько измененном по фасаду виде и в настоящее время. Этот двор строен
был с 1787 по 1790 гг. на средства и на земле тех лиц, которым принадлежал.
Строение их было окончено в три года лишь благодаря энергичному
воздействию генерал-губернатора князя В.П. Салтыкова и правившего тогда
должность губернатора В.Г. Лазарева. Из предписаний последнего Городской
Думе видно, что прежние лавки были деревянные, ветхие, беспрестанно
угрожавшие падением и пожаром, при том безобразившие город. Сколько ни
побуждал губернатор Лазарев и словесными и письменными
распоряжениями поспешить с постройкой Гостиного Двора, но все было
безуспешно, доколе он «через коменданта Штурмана (речь идет о
Георгиевском кавалере, подполковнике (с 1797 г. - полковник) Леонтии
Ивановиче Шурмане) не отрядил обер-офицера (унтер-офицера) и двух
рядовых, чтобы они как за смотрителями над работами - купцами Гордеевым
и Свешниковым, и за распорядителями по заготовке материалов
Сандриковым (Садовниковым) и Мигуновым, так и за подрядчиками и
самыми работами неослабно смотрели от утра до вечера, неотступно были
при строении и понуждали, чтобы рабочие от самого рассвета до захождения
солнечного производили работу безленосно».
В 1790 г. городской голова со словесными и добросовестными судьями и со
строителями купцами - Алферовым, Григорием Яковлевичем Петровским (с
Григорием и Яковом Петровскими), Спиридоном Сомовым и Семеном
Лазаревым - лавкам Гостиного Двора произвели расценку и по жребию
признали владельцев их. Из реестра лавок видно, что в том Гостином Дворе
была устроена 51 лавка, из которых, начиная с первого заворота против дома
Лазарева с наугольной от площади первой лавки 12 лавок было определено
для овощного и питейного ряда (для овощного, железного и питейного ряда):
№ 1 - взято 350 руб., отдана Андрею Свешникову; № 2 - 350 руб., Ивану
Моторину; № 3 - 450 руб., Андрею Свешникову; № 4 - 600 руб., неизвестно
кому; № 5 - 600 руб., Дмитрию Гордееву; №6 - 600 руб., Григорию
Петровскому; № 7 - 600 руб., Спиридону Сомову; № 8 - 450 руб., Якову
Петровскому; № 9 - 300 руб., Семену Лазареву; № 10 - 350 руб., Ивану
Мигунову; № 11 - 600 руб., Михаилу Шишеву; № 12 - 450 руб., Степану
Воронову."
      />
      <Typography
        title="Следующие 14 лавок назначены были для суконного и щепоточного ряда (13
лавок назначены для суконного и щепетильного ряда): № 13 - 450 руб.,
Дмитрию Гордееву; № 14 450 руб., Степану Воронову; № 15 - 430 руб.,
Герасиму Мыльникову; № 16 - 420 руб., Спиридону Сомову; № 17 - 400 руб.,
Семену Лазареву; № 18 - 380 руб., Ивану Ивановичу Луковникову; № 19 -
360 руб., Андрею Алферову; № 20 - 350 руб., Дмитрию Неростову; № 21 350
руб., Семену Лазареву; № 22 - 350 руб., Василию Калабину (Килитину) и
часть Шишееву; № 23 - 360 руб., Григорию Петровскому; № 24 - 360 руб.,
Якову Философову (Ивану Сидельникову); № 25 - Степану Воронову. В
проходе № 26 - 350 руб., Якову Петровскому; № 27 - 350 руб., Степану
Воронову."
      />
      <Typography
        title="Для сапожного и юхтяного (Юфть/юхть/юхоть/юхта - кожа рослого быка или
коровы, выделанная по русскому способу, на чистом дегтю) ряда 11 лавок: №
34 - 320 руб., Спиридону Сомову; № 35 - 320 руб., Якову Денисову; № 36 320
руб., Григорию Петровскому; № 37 - 320 руб., Кузьме Иванову; № 38 - 320
руб., Ивану Михайловичу Гордееву (Ивану и Михаилу Гордеевым); № 39 -
300 руб., Степану Антропову; № 40 - 300 руб., Федору Сабурову, № 41 - 300
руб., Афанасию Алферьеву; № 42 - 300 руб., Андрею Алферьеву.
Угольные лавки: № 43 - 250 руб., Константину (Козьме Иванову) Иванову, №
44 - 150 руб., Якову Денисову.
Для рыбного ряда было отведено 7 лавок: № 45 - 230 руб., Афанасию
Алферьеву (Андрею Алферову); № 46 - 230 руб., Афанасию Алферову; № 47
- 425 руб., Дмитрию Гордееву; № 48 - 400 руб., Василию Воронову; № 49 -
370 руб., Ксенофонту Мигунову; № 50 и 51 - 600 руб., Андрею Свешникову.
Всего собрано полавочных 18795 руб., но этой суммы не хватило для уплаты
за работы, и потому 13 мая 1791 г. было положено собрать с лавки по оценке
с каждого рубля по 8 копеек, всего 1503 руб. 60 коп. Следовательно,
Гостиный Двор строением стоил 20298 руб. 60 коп. Из дела о его строении
видно, что разделение по лавкам было сделано почти такое же, какое
существовало до 20-х годов нынешнего века (XX века). Так по улице к
женскому монастырю был рыбный ряд, от угла до бывшей Пятницкой церкви
- сапожный ряд, далее - суконный, а с угла к дому бывшего Лазарева и теперь
существующему тянулся, подходя почти н нынешнему театру - овощной и
питейный ряд.
Торговые Ряды были одноэтажными с монументальной галереей по фасаду, с
проходом от Большой улицы и по одному проходу возле передних углов, и с
тремя проездами с трех остальных сторон.
В связи с разрешением в 1780 г. «лицам, не имеющим возможности строить
каменные дома по Большой Нижегородской улице и в Китае городе,
продавать свои деревянные дворы людям зажиточным, а самим строить в
Белом или Земляном городе», а также Указом от 1783 г. «о дозволении всем
купцам Российской империи иметь в домах своих лавки и в них торговать» в
1871-1874 гг. здесь широко развернулось каменное строительство тогдашних
владимирских богатеев. Тогда же ратману (ратман - в Российской империи в
ХѴIII-ХІХ вв. выборный член городских магистратов, ратуш, управ
благочиния) городского магистрата купцу Семену Андреевичу Лазареву
было отведено 625 кв. саж. земли по правой стороне Большой Московской
дороги, начиная от Торгового моста и далее до проезда с Муромской улицы
на Торговую площадь. Таким образом, все пространство, занимаемое еще в
нач. ХХ века линией одноэтажных торговых помещений, тянущихся одним
строением от угла площади против Банка до ресторана Клязьма,
помещающегося в 3-х этажном каменном доме на углу к торговой площади и
далее по завороту к этой площади, почти до самого Дома Офицеров
составляли владение Лазарева. При постройке здесь ныне существующих
домов, против восточной части Гостиного двора были снесены стоявшие
здесь деревянная гауптвахта, питейный дом, обывательская цирюльня и дом
Колодезникова."
      />
      <Typography
        title="По-видимому, одновременно в 70-х гг. XVIII в. была застроена такой же
сплошной линией каменных лавок, как и лазаревская линия, и левая сторона
Большой улицы, начиная от парка Пушкина до Муромской улицы
(см. Южные малые торговые ряды). Так как обе эти линии домов строились
на линии рва, и по обеим сторонам существовавшего здесь Торгового моста,
то очевидно, что самый этот ров в данном месте был засыпан еще ранее, рвы
от Клязьмы и Лыбеди подняты в виде постепенного подъема в город, и
образовавшаяся таким образом дорога вышла на Большую улицу прямо к
Торговой площади, т. е. сделав большой изгиб от середины рва.
Для устройства этой дороги были, по-видимому, сброшены валы на месте
парка Пушкина и частично Троицкий вал, а в качестве закрепления земли от
падения вниз был сооружен под левой стороной строений глубокий
фундамент, поражающий и доселе своей мощностью, если смотреть на него
снизу, поднимаясь в город по Муромской улице перед заворотом с нее на
Большую улицу. О способе засыпки Торгового моста и возможно
прилегающего к нему рва отчасти свидетельствует следующее донесение
владимирского губернского архитектора гражданскому Губернатору И.М.
Долгорукову от 1808 г.: «Ордером вашего сиятельства от 13 дня сего апреля
под № 976 предписано мне дабы я по случаю отношения к вашему
сиятельству г. московского гражданского губернатора доставил план и
прожект здешним земляным мостам Торговому и Ивановскому. Но как я в то
время, был землемер, почему сия часть до меня и не принадлежала, при
бытности же моей здесь в городе деланья были, так как прежде оные
существовали - деревянные на взрубах клетками и в совершенном упадке, а
посему и было предложено от бывшего господина генерал-губернатора
Забороского (1787-1797) здешнему градскому обществу построить каменные.
Но как оное от сего отказалось, то и велено без прожекта и плана, не
разбирая те срубы, засыпать землею, дабы насыпанная земля не могла
распускаться, потом с боков осыпи окрыть дерном и засеять овсом с
поливкой воды, дабы от корня оного дерн мог вязаться». (Дело канцелярии
при Владимирском гражданском губернаторе № 133 за 1808 г.
Неопубликованные записки В.В. Косаткина) Таким образом, оба засыпанные
моста стали как бы гребнем обеих дорог с Клязьмы на Залыбедь.
В огромном пожаре, опять постигшем Владимир уже в 1778 г., выгорела та
его часть, которая уцелела в пожаре 1719 г., т. е. северо-западный угол
Китай-города и Ямская слобода - нынешняя улица от Золотых ворот до
заставы (улица Студеная Гора). И после этого начиналась застройка
каменными зданиями и Большой улицы от Золотых ворот до Муромской
улицы справа, и до Торговых рядов слева. В 1787 г. была отмерена земля для
участка под № 2 по плану, идучи к Золотым воротам, для постройки
почтового двора, а в 1795 г. куплен для почтамта и дом № 1 (сейчас ул. Б.
Московская, д. 2) у титулярного советника Мещерякова за 13000 рублей.
Любопытно, что в первом из них, будучи во Владимире, помещался
император Павел, а это говорит о том, что этот дом тогда был лучшим домом
города, во втором же, будучи в ссылке, в 1838 г. недолго проживал Герцен.
Дальше по той же линии сооружаются дома тогдашних богатеев,
торговавших в Торговых рядах: Петровских, Гордеева, Никитиных, Лазарева,
частично сохранившиеся за теми же фамилиями до Революции. Среди этих
домов высился дом-великан (ул. Б. Московская, д. 24). Он был построен для
нужд приказа общественного призрения, но по высочайшему повелению был
передан для жительства гражданского губернатора. "
      />
      <Typography
        title="От пожара, постигшего город Владимир 28 июля 1778 г., сильно пострадали
и Золотые ворота и, в частности, их шатровый верх, который до пожара был
выше и заканчивался шпилем украшенным огромным шаром. В 1795 г.
местным архитектором Чистяковым был составлен «Прожект, учиненный в
плане и фасаде для укрепления и возобновления древнего здания, самих
ворот», и было предложено: «церковь над вратами и те своды с
перемычками, на коих она основание имеет, поелику ветхи, то следовательно
разобрать, а потому эту церковь соорудить в знак достопамятности по
прежнему ее расположению». Эти работы и были затем осуществлены:
разобрана надвратная церковь, а большой свод переложен вновь из старого
белого камня с добавкой кирпича. На новом своде была поставлена церковь
измененного сравнительно с проектом характера.
Кроме того в связи с перепланировкой Владимира в целях расширения
Большой улицы города и связи ее с вновь устроенной Дворянской улицей на
месте бывшей Ямской слободы, валы, примыкавшие с обеих сторон к
Золотым воротам, были сняты, и ворота лишились своей старой опоры по
сторонам. Так как это могло гибельно отразиться на состоянии Золотых
ворот, то тогда же были подведены по углам ворот белокаменные
контрфорсы. Затем они были прикрыты круглыми башнями, а между ними
были построены с одной стороны - вход с лестницей в церковь, а с другой -
жилое помещение для сторожа. В таком виде Золотые ворота и дошли до
настоящего времени."
      />
      <Typography
        title="Источник:"
        variant={TypographyVariant.BASIC_BOLD_TEXT}
      />
      <Typography
        title="Косаткин, Михаил Васильевич. Город Владимир в XVI, XVII, XVIII веках / 
Департамент культуры администрации Владим. обл., Владим. обл. науч. б-
ка им М. Горького; (отв. ред. А.А. Тихомирова).- Владимир, 2018. 156 с."
        variant={TypographyVariant.BASIC_ITALIC_TEXT}
      />
    </FlexColumn>
  ),
};
