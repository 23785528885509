import { IThemeRebranding } from 'state/rebranding/rebrandingTypes'
import { mapThemeConfig } from './mapThemeConfig'

const defaultThemeConfig: IThemeRebranding = {
  item: {
    background: '#fff8dd',
    text: '#735542',
  },
  app: {
    background: '#ffffff',
  },
}

const getDefaultThemeConfig = () =>
  JSON.parse(JSON.stringify(defaultThemeConfig))
export const defaultStoryTheme = mapThemeConfig(getDefaultThemeConfig())
