import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react'

export const rebrandingAPI = createApi({
  reducerPath: 'rebrandingAPI',
  baseQuery: fetchBaseQuery({
    baseUrl: 'https://jsonplaceholder.typicode.com',
  }),
  endpoints: (build) => ({
    getApi: build.query({
      query: () => ({
        // url: '/rebranding',
        url: '/posts',
      }),
    }),
  }),
})

// todo: create rebrandingAPI
