import { ReactNode } from "react";
import { news0 } from "./news0";
import { news1 } from "./news1";
import { news2 } from "./news2";

export type NewsContent = {
  id: number;
  title: string;
  preview: string;
  imageURL: string;
  content: (isNewsImageSmall: boolean) => ReactNode;
};

export const newsContent: NewsContent[] = [news2, news1, news0];
