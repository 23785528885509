import React, { PropsWithChildren, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";

export const ScrollToTop: React.FC<PropsWithChildren<any>> = ({
  children,
}: PropsWithChildren<any>) => {
  const location = useLocation();
  useLayoutEffect(() => {
    document.documentElement.scrollTo(0, 0);
  }, [location.pathname]);
  return children;
};
