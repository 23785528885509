import React from "react";
import { IPageWrapperProps } from "./PageWrapperTypes";
import { PageWrapperWrapper } from "./PageWrapperStyle";
import { PageTitle } from "../PageTitle/PageTitle";

export const PageWrapper: React.FC<IPageWrapperProps> = ({
  title,
  hasHugePadding = true,
  children,
}) => {
  return (
    <>
      {title && <PageTitle title={title} />}
      <PageWrapperWrapper
        className={hasHugePadding ? "padding-bottom-huge" : ""}
      >
        {children}
      </PageWrapperWrapper>
    </>
  );
};
