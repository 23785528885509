import { lighten } from "polished";
import { IThemeRebranding } from "state/rebranding/rebrandingTypes";
import { ITheme } from "./themeTypes";

export const mapThemeConfig = ({ item, app }: IThemeRebranding): ITheme => {
  return {
    item: {
      primary: {
        background: item.background,
        backgroundActive: lighten(0.1, item.background),
        backgroundHover: lighten(0.1, item.background),
        text: item.text,
        textActive: lighten(0.1, item.text),
        textHover: lighten(0.1, item.text),
      },
      secondary: {
        background: item.text,
        backgroundActive: lighten(0.1, item.text),
        backgroundHover: lighten(0.1, item.text),
        text: item.background,
        textActive: lighten(0.1, item.background),
        textHover: lighten(0.1, item.background),
      },
      correct: {
        background: "#B4E8CF",
        backgroundHover: lighten(0.1, "#B4E8CF"),
        backgroundActive: lighten(0.1, "#B4E8CF"),
        text: "#6BA388",
        textActive: lighten(0.1, "#B4E8CF"),
        textHover: lighten(0.1, "#B4E8CF"),
      },
      danger: {
        background: "#E9AFB7",
        backgroundHover: lighten(0.1, "#E9AFB7"),
        backgroundActive: lighten(0.1, "#E9AFB7"),
        text: "#CF3F53",
        textActive: lighten(0.1, "#E9AFB7"),
        textHover: lighten(0.1, "#E9AFB7"),
      },
      disabled: {
        background: "#3C3C3C",
        text: "#777777",
      },
    },
    app: {
      background: app.background,
      placeholder: "#B9D4DB",
    },
  };
};
