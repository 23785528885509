import React from "react";
import { NewsContent } from "./newsContent";
import { Typography } from "../../../components/shared/Typography/Typography";
import { FlexColumn } from "../../../components/shared/SharedStyle";

export const news1: NewsContent = {
  id: 1,
  title: "Братская встреча 09.06.2023",
  preview:
    "9 июня 2023 года состоялась встреча Братьев символического Треугольника «Коронованный Лев», учрежденного на Востоке города Владимира.",
  imageURL: "/news/1/1-1.JPG",
  content: () => (
    <FlexColumn>
      <Typography
        title="9 июня 2023 года состоялась встреча Братьев символического Треугольника
«Коронованный Лев», учрежденного на Востоке города Владимира. Встреча
состоялась в городе Великий Новгород. Любезные Б:.и С:. из
Достопочтенной Ложи «Белая Акация», провели экскурсию по историческим
местам древнего Русского города. Братская агапа завершила труды сего дня."
      />
      <Typography title="Фотоотчет о встрече можно увидеть в разделе ГАЛЕРЕЯ, нашего сайта." />
      <img
        alt="Картинка для новости"
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/news/1/1-1.JPG)",
        }}
      />
    </FlexColumn>
  ),
};
