import { ReactNode } from "react";
import { article0 } from "./article0";
import { article1 } from "./article1";
import { article2 } from "./article2";
import { article3 } from "./article3";

export type ArticlesContent = {
  id: number;
  title: string;
  preview: string;
  imageURL: string;
  content: (isArticleImageSmall: boolean) => ReactNode;
};

export const articlesContent: ArticlesContent[] = [
  article3,
  article2,
  article1,
  article0,
];
