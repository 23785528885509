import React from "react";
import { ReactComponent as EyeClosed } from "assets/icons/eye-closed.svg";
import { ReactComponent as EyeOpened } from "assets/icons/eye-open.svg";
import { TabIcon } from "../../components/shared/Tab/TabTypes";

interface TabIconProps {
  icon: TabIcon;
}

export const TabSvg: React.FC<TabIconProps> = ({ icon }) => {
  switch (icon) {
    case TabIcon.EYE_CLOSED: {
      return <EyeClosed />;
    }
    case TabIcon.EYE_OPENED: {
      return <EyeOpened />;
    }
    default:
      return null;
  }
};
