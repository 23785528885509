import React, { useEffect, useState } from "react";
import {
  TypographyColor,
  TypographyVariant,
} from "../../components/shared/Typography/TypographyTypes";
import { Typography } from "../../components/shared/Typography/Typography";
import { Button } from "../../components/shared/Button/Button";
import {
  ButtonSize,
  ButtonVariation,
} from "../../components/shared/Button/ButtonTypes";
import { News } from "../News/News";
import { Articles } from "../Articles/Articles";
import {
  Link,
  LinksWrapper,
  MainImageContent,
  MainWrapper,
  Alarm,
  AlarmIcon,
} from "./MainStyle";
import { FlexColumn } from "../../components/shared/SharedStyle";
import { Gallery } from "../Gallery/Gallery";

export const Main = () => {
  const [isScreenHuge, setIsScreenHuge] = useState<boolean>(
    window.innerWidth > 1120
  );
  const [isScreenSmall, setIsScreenSmall] = useState<boolean>(
    window.innerWidth < 825
  );
  const [isPhone, setIsPhone] = useState<boolean>(window.innerWidth < 430);

  useEffect(() => {
    const handleResize = () => {
      setIsScreenHuge(window.innerWidth > 1120);
      setIsScreenSmall(window.innerWidth < 825);
      setIsPhone(window.innerWidth < 430);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const LinkButton = (url: string, title: string) => (
    <Link href={url} target="_blank">
      <Button
        title={title}
        variation={ButtonVariation.PRIMARY}
        size={isScreenHuge || isScreenSmall ? ButtonSize.M : ButtonSize.S}
        onClick={() => {}}
        typographyVariant={
          isScreenHuge || isScreenSmall
            ? TypographyVariant.BASIC_BOLD_TEXT
            : TypographyVariant.SMALL_BOLD_TEXT
        }
      />
    </Link>
  );
  const links = (
    <LinksWrapper>
      {LinkButton(
        "https://www.godf.org/",
        isPhone ? "Великий Восток Франции" : "Сайт Великого Востока Франции"
      )}
      {LinkButton(
        "https://www.masonry-acacia.org/index.php/ru/",
        isPhone ? 'Ложа "Белая Акация"' : 'Cайт Ложи "Белая Акация"'
      )}
    </LinksWrapper>
  );

  const greet = (
    <>
      <Typography
        title="Приветствуем Вас на официальном сайте символического треугольника
«Коронованный лев», на Востоке города Владимира, организованный от
Достопочтенной Ложи «Белая Акация», входящей во всемирную масонскую
цепь Великого Востока Франции, Grand Orient de France."
        variant={
          isScreenHuge || isScreenSmall
            ? TypographyVariant.BASIC_TEXT
            : TypographyVariant.SMALL_TEXT
        }
        color={
          isScreenSmall ? TypographyColor.PRIMARY : TypographyColor.SECONDARY
        }
        align={isScreenSmall ? "justify" : "center"}
      />
    </>
  );

  return (
    <MainWrapper>
      <img
        alt="Картинка с коронованным львом"
        style={{
          width: "100%",
          content: "url(/main/lion.jpg)",
        }}
      />
      {isScreenSmall ? (
        <>
          <FlexColumn style={{ padding: `0 40px` }}>{greet}</FlexColumn>
          {links}
        </>
      ) : (
        <MainImageContent>
          {greet}
          {links}
        </MainImageContent>
      )}
      <FlexColumn style={{ padding: `0 ${isScreenHuge ? "140px" : "40px"}` }}>
        <Typography
          title="Масонство позиционируется как нравственно-этическая система, выраженная
в аллегориях и иллюстрируемая символами. Масонство не является
самостоятельным религиозным течением, заменой религии или тем более
сектой. Философия масонства, не предполагает коррекцию религиозных
убеждений своих членов. Нравственно-этическая система масонства и его
философия, ссылается на веру своих членов в Бога, но имеет дело лишь с
вопросами отношений людей между собой, затрагивает только вопросы
этики и нравственности, не касаясь религиозных аспектов, оставляя право на
свободу вероисповедания за каждым из братьев-масонов."
          variant={TypographyVariant.BASIC_TEXT}
          color={TypographyColor.PRIMARY}
        />
        <Typography
          title="Мы, современные Вольные Каменщики продолжаем традиции своих предков
и стремимся создать братское общество, имеющее глубокие корни в
Российской истории и приветствующим традиции взаимопомощи,
сохранения и изучения собственной истории, саморазвития и  исследования
философских и духовных тем."
          variant={TypographyVariant.BASIC_TEXT}
          color={TypographyColor.PRIMARY}
        />
      </FlexColumn>
      <News />
      <Articles />
      <Gallery />
      <Alarm>
        <AlarmIcon />
        <Typography
          title="Символический Треугольник «Коронованный Лев», не является религиозной
организацией, не представляет никакую из религиозных конфессий и
находится вне политики . Мы не принимаем пожертвований и не занимаемся
ни какой агитационной деятельностью. Целью нашего существования и
деятельности, является проведение историческо-философских изысканий ,а
так же благотворительность.  "
          variant={TypographyVariant.BASIC_TEXT}
          color={TypographyColor.PRIMARY}
        />
      </Alarm>
    </MainWrapper>
  );
};
