import React from "react";
import { NewsContent } from "./newsContent";
import { Typography } from "../../../components/shared/Typography/Typography";
import { FlexColumn } from "../../../components/shared/SharedStyle";

export const news2: NewsContent = {
  id: 2,
  title: "Братская встреча 23.09.2023",
  preview:
    "23 сентября 2023 года состоялась встреча Братьев символического Треугольника «Коронованный Лев», учрежденного на Востоке города Владимира.",
  imageURL: "/news/2/2-1.jpg",
  content: () => (
    <FlexColumn>
      <Typography
        title="9 июня 2023 года состоялась встреча Братьев символического Треугольника
«Коронованный Лев», учрежденного на Востоке города Владимира. Были обсуждены насущные проблемы и планы развития
треугольника.  Утверждены расходы на текущий период. Братская агапа
завершила труды сего дня."
      />
      <img
        alt="Картинка для новости"
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/news/2/2-1.jpg)",
        }}
      />
    </FlexColumn>
  ),
};
