import styled from "styled-components";
import { getAppBackground, getItemPrimaryBackground } from "selectors/theme";

export const ListDropdownWrapper = styled.div`
  cursor: pointer;
`;

export const ListItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  position: absolute;
  padding: 20px;
  margin: 0 0 0 0;
  border-radius: 10px;
  background-color: ${getAppBackground};
  z-index: 2;
  gap: 20px;
  -webkit-box-shadow: 0px 0px 49px -29px rgba(34, 60, 80, 0.7);
  -moz-box-shadow: 0px 0px 49px -29px rgba(34, 60, 80, 0.7);
  box-shadow: 0px 0px 49px -29px rgba(34, 60, 80, 0.7);

  &.list-dropdown-size-s {
    min-width: fit-content;
    transform: translate(-45%, 40px);
  }

  &.list-dropdown-size-m {
  }

  &.list-dropdown-size-l {
    flex-wrap: wrap;
    right: 10px;
    top: 130px;
    width: calc(100% - 20px);
  }
`;
