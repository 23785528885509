import React, { useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { Main } from "pages/Main/Main";
import { History } from "pages/History/History";
import { GODF } from "pages/GODF/GODF";
import { Membership } from "pages/Membership/Membership";
import { Header } from "components/shared/Header/Header";
import { Tab } from "components/shared/Tab/Tab";
import { TabIcon } from "components/shared/Tab/TabTypes";
import { News } from "./News/News";
import { Footer } from "../components/shared/Footer/Footer";
import { Articles } from "./Articles/Articles";
import { ListDropdown } from "../components/shared/ListDropdown/ListDropdown";
import { NewsContent } from "./News/NewsContent";
import { ArticlesContent } from "./Articles/ArticlesContent";
import { GalleryContent } from "./Gallery/GalleryContent";
import { Library } from "./Library/Library";
import { Gallery } from "./Gallery/Gallery";

import { MenuClosed, MenuOpened } from "../components/shared/SharedStyle";

export const mainPath = "/";
export const historyPath = "/history";
export const godfPath = "/godf";
export const articlesContentPath = "/articles/:id";
export const articlesPath = "/articles";
export const galleryContentPath = "/gallery/:id";
export const galleryPath = "/gallery";
export const newsContentPath = "/news/:id";
export const newsPath = "/news";
export const libraryPath = "/library";
export const membershipPath = "/membership";

export const AppRoutes: React.FC = () => {
  const navigate = useNavigate();
  const [isMediaOpen, setMediaOpen] = useState<boolean>(false);
  const [isMenuOpen, setMenuOpen] = useState<boolean>(false);
  const [showHeader, setShowHeader] = useState<boolean>(
    window.innerWidth > 825
  );

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    const handleResize = () => {
      setShowHeader(window.innerWidth > 825);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const mediaTabs = (
    <>
      <Tab
        title={"Новости"}
        isActive={newsPath === pathname}
        onClick={() => {
          navigate(newsPath);
          setMediaOpen(false);
          setMenuOpen(false);
        }}
      />
      <Tab
        title={"Статьи"}
        isActive={articlesPath === pathname}
        onClick={() => {
          navigate(articlesPath);
          setMediaOpen(false);
          setMenuOpen(false);
        }}
      />
      <Tab
        title={"Галерея"}
        isActive={galleryPath === pathname}
        onClick={() => {
          navigate(galleryPath);
          setMediaOpen(false);
          setMenuOpen(false);
        }}
      />
      <Tab
        title={"Библиотека"}
        isActive={libraryPath === pathname}
        onClick={() => {
          navigate(libraryPath);
          setMediaOpen(false);
          setMenuOpen(false);
        }}
      />
    </>
  );

  const tabs = (
    <>
      <Tab
        title={"Главная"}
        isActive={mainPath === pathname}
        onClick={() => {
          navigate(mainPath);
          setMenuOpen(false);
        }}
      />
      <Tab
        title={"Наш след в истории"}
        isActive={historyPath === pathname}
        onClick={() => {
          navigate(historyPath);
          setMenuOpen(false);
        }}
      />
      <Tab
        title={"ВВФ"}
        isActive={godfPath === pathname}
        onClick={() => {
          navigate(godfPath);
          setMenuOpen(false);
        }}
      />
      {showHeader ? (
        <ListDropdown
          className="tab-list"
          state={[isMediaOpen, setMediaOpen]}
          size="s"
          trigger={
            <Tab
              title={"Медиа"}
              icon={isMediaOpen ? TabIcon.EYE_OPENED : TabIcon.EYE_CLOSED}
              isActive={
                articlesPath === pathname ||
                newsPath === pathname ||
                libraryPath === pathname ||
                galleryPath === pathname
              }
              onClick={() => {}}
            />
          }
        >
          {mediaTabs}
        </ListDropdown>
      ) : (
        mediaTabs
      )}
      <Tab
        title={"Вступление"}
        isActive={membershipPath === pathname}
        onClick={() => {
          navigate(membershipPath);
          setMenuOpen(false);
        }}
      />
    </>
  );

  const trigger = isMenuOpen ? <MenuOpened /> : <MenuClosed />;

  return (
    <div style={{ minHeight: "100vh", position: "relative" }}>
      <Header>
        {showHeader ? (
          tabs
        ) : (
          <ListDropdown trigger={trigger} state={[isMenuOpen, setMenuOpen]}>
            {tabs}
          </ListDropdown>
        )}
      </Header>
      <Routes>
        <Route path={membershipPath} element={<Membership />} />
        <Route path={godfPath} element={<GODF />} />
        <Route path={libraryPath} element={<Library />} />
        <Route path={newsContentPath} element={<NewsContent />} />
        <Route path={newsPath} element={<News isPage />} />
        <Route path={articlesContentPath} element={<ArticlesContent />} />
        <Route path={articlesPath} element={<Articles isPage />} />
        <Route path={galleryContentPath} element={<GalleryContent />} />
        <Route path={galleryPath} element={<Gallery isPage />} />
        <Route path={historyPath} element={<History />} />
        <Route path={mainPath} element={<Main />} />
      </Routes>
      <Footer />
    </div>
  );
};
