import styled from "styled-components";

export const PageWrapperWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
  padding: 40px 140px;

  @media screen and (max-width: 700px) {
    padding: 40px 40px;
  }

  &.padding-bottom-huge {
    padding-bottom: 160px;
  }
`;
