import React from "react";
import { useNavigate } from "react-router-dom";
import { PageTitle } from "../../components/shared/PageTitle/PageTitle";
import { Card } from "../../components/shared/Card/Card";
import { galleryPath } from "../AppRoutes";
import { galleryContent } from "./content/galleryContent";
import { GalleryWrapper, Wrapper } from "./GalleryStyle";

export type GalleryProps = {
  isPage?: boolean;
};

export const Gallery = ({ isPage = false }: GalleryProps) => {
  const navigate = useNavigate();

  return (
    <>
      <PageTitle title="ГАЛЕРЕЯ" />
      <Wrapper className={isPage ? "" : "no-wrap"}>
        <GalleryWrapper className={isPage ? "wrap" : ""}>
          {galleryContent.map((gallery) => {
            const { title, id, imageURL } = gallery;
            return (
              <Card
                key={`gallery-${id}`}
                title={title}
                imageURL={imageURL}
                buttonText="Перейти к фотографиям"
                onClick={() =>
                  navigate(isPage ? `${id}` : `${galleryPath}/${id}`)
                }
              />
            );
          })}
        </GalleryWrapper>
      </Wrapper>
    </>
  );
};
