import i18n from 'i18next'
import en from './en.json'
import fr from './fr.json'
import ru from './ru.json'

i18n.init({
  defaultNS: 'common',
  fallbackLng: 'en',
  keySeparator: false,
  interpolation: {
    escapeValue: false,
  },
  lng: 'en',
  resources: {
    en: en,
    fr: fr,
    ru: ru,
  },
})

export default i18n
