import React from "react";
import { IFooterProps } from "./FooterTypes";
import { FooterWrapper } from "./FooterStyle";
import { Typography } from "../Typography/Typography";
import {
  TypographyColor,
  TypographyVariant,
} from "../Typography/TypographyTypes";

export const Footer: React.FC<IFooterProps> = () => {
  return (
    <FooterWrapper>
      <Typography
        title="© 2024
Символический Треугольник «Коронованный Лев» учрежден и действует на
Востоке города Владимира. Создан и действует только под эгидой ВВФ. Все иные ссылки, сведения, изображения и логотипы,
связанные с деятельностью Треугольника, кроме размещенных на данном
сайте являются не достоверными. Все права защищены."
        variant={TypographyVariant.SMALL_TEXT}
        color={TypographyColor.SECONDARY}
      />
    </FooterWrapper>
  );
};
