import styled from "styled-components";
import { getItemPrimaryBackground } from "selectors/theme";

export const PageTitleWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 80px);
  height: 120px;
  max-height: fit-content;
  background-color: ${getItemPrimaryBackground};
  padding: 0 40px;
  box-sizing: content-box;

  & * {
    text-transform: uppercase;
  }
`;
