import styled from "styled-components";
import {
  getItemCorrectBackground,
  getItemCorrectText,
  getItemDangerBackground,
  getItemDangerText,
  getItemPrimaryBackground,
  getItemPrimaryText,
  getItemSecondaryText,
  getAppBackground,
} from "selectors/theme";

export const ButtonInner = styled.button`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  box-sizing: border-box;
  min-width: fit-content;
  border-radius: 10px;
  border: none;
  white-space: nowrap;
  cursor: pointer;

  &.button:hover:not(.button-disabled) {
    filter: brightness(105%);
  }

  &.button svg {
    width: 20px;
    height: 20px;
  }

  &.button-titled svg {
    margin: 0 10px 0 -5px;
  }

  &.button-size-m {
    height: 40px;
    padding: 10px 20px;
    gap: 20px;
  }

  &.button-size-s {
    height: 30px;
    padding: 8px 16px;
    gap: 20px;
  }

  &.button-variation-primary {
    background: ${getItemPrimaryBackground};
    color: ${getItemPrimaryText};
    stroke: ${getItemPrimaryText};
  }

  &.button-variation-secondary {
    background: ${getAppBackground};
    border: 1px solid ${getItemSecondaryText};
    color: ${getItemSecondaryText};
    stroke: ${getItemSecondaryText};
  }

  &.button-variation-correct {
    background: ${getItemCorrectBackground};
    color: ${getItemCorrectText};
    stroke: ${getItemCorrectText};
  }

  &.button-variation-danger {
    background: ${getItemDangerBackground};
    color: ${getItemDangerText};
    stroke: ${getItemDangerText};
  }

  &.button-type-ghost {
    background: inherit;
    margin: 0;
    padding: 0;
  }
`;

export const ButtonWrapper = styled.div`
  position: relative;

  &:not(:last-child) {
    margin-right: 10px;
  }
`;
