import styled from "styled-components";
import { ReactComponent as MenuSvg } from "assets/icons/menu.svg";
import {
  getAppBackground,
  getItemPrimaryBackground,
  getItemPrimaryText,
} from "../../selectors/theme";

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;

  @media screen and (max-width: 1120px) {
    & > * {
      align-items: center;
    }
  }
`;

export const FlexRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 20px;

  @media screen and (max-width: 1120px) {
    flex-direction: column;
    & > * {
      align-items: center;
    }
  }
`;

export const MenuOpened = styled(MenuSvg)`
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  & path {
    fill: ${getItemPrimaryBackground};
    stroke: ${getItemPrimaryText};
  }
`;

export const MenuClosed = styled(MenuSvg)`
  min-width: 60px;
  max-width: 60px;
  min-height: 60px;
  max-height: 60px;
  & path {
    fill: ${getAppBackground};
    stroke: ${getItemPrimaryText};
  }
`;
