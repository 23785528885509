import React from "react";
import { ArticlesContent } from "./articlesContent";
import { TypographyVariant } from "../../../components/shared/Typography/TypographyTypes";
import { Typography } from "../../../components/shared/Typography/Typography";
import { ImageWithLabel } from "../../../components/shared/ImageWithLabel/ImageWithLabel";
import { FlexColumn, FlexRow } from "../../../components/shared/SharedStyle";
import { PageTitle } from "../../../components/shared/PageTitle/PageTitle";

export const article1: ArticlesContent = {
  id: 1,
  title: "Биография, деятельность и жизнь Р.И. Воронцова. Часть 1",
  preview:
    "Роман Илларионович родился 17 (28) июля 1717 г. В ряде современных...",
  imageURL: "/articles/1/1-1.jpg",
  content: (isArticleImageSmall: boolean) => (
    <FlexColumn>
      <Typography
        title="Роман Илларионович родился 17 (28) июля 1717 г. В ряде современных и
дореволюционных изданий датой его рождения ошибочно называется 1707
год. Но в “Записках” его дочери Е.Р. Дашковой и в воспоминаниях
Екатерины II Романа Илларионовича называют младшим братом канцлера.
Дату рождения 17 июля 1717 года подтверждают некролог, опубликованный
в “Санкт-Петербургских ведомостях”, надпись на надгробной плите и
сведения, помещенные в Государственном архиве Тамбовской области.
Место рождения неизвестно.
Старший сын Иллариона Гавриловича Воронцова от брака его с Анной
Григорьевной Масловой. Будучи 16-ти лет от роду (1733) Роман Воронцов
был зачислен в лейб-гвардии Измайловский полк. Этот полк был
сформирован по распоряжению Анны Иоанновны и назван по имени
подмосковного села Измайловского, где в то время пребывала императрица,
возложившая на себя звание подполковника этого полка. В 1730-х гг.
поддерживал цесаревну Елизавету Петровну. "
      />
      <ImageWithLabel
        title="Роман Илларионович Воронцов"
        url="/articles/1/1-1.jpg"
        width="70%"
      />
      <Typography
        title="В 1735 г. Роман Воронцов обвенчался с 17-летней Марфой Ивановной,
урожденной Сурминой, в первом замужестве княгиней Долгорукой.
Выданная замуж в 11-летнем возрасте за Юрия Долгорукова, она вскоре
вернулась в родительский дом, а муж подал прошение на имя императрицы
Анны Иоанновны о разводе, обвиняя ее в неверности. Допрошенная в
присутствии “знатных духовных персон”, Марфа во всем созналась и даже
оговорила себя, потому что больше всего боялась, что ей придется вернуться
к мужу, так как он часто “пьянствовал и буйствовал”. Богатая наследница
своего отца, костромского дворянина Ивана Михайловича Сурмина,
скончавшегося в 1729 году, Марфа Ивановна была близкой подругой
Елизаветы Петровны, часто ссужала ее деньгами, так как положение дочери
Петра Великого при дворе Анны Иоанновны было нелегким."
      />
      <Typography
        title="В 1736 году началась война с Турцией. Армия под командованием
фельдмаршала Миниха, в которую входил и лейб-гвардии Измайловский
полк, штурмом берет Очаков. Известно, что Роман Воронцов в звании
сержанта служил под началом подполковника Густава Бирона, брата
всесильного фаворита Анны Иоанновны. В 1738 году “должна была
открыться новая кампания против турок”, в “Приказном журнале” лейб-
гвардии Измайловского полка мы читаем такую запись: “7 марта 1738 года,
измайловский сержант Роман Воронцов командирован для приготовления
почтовых к маршу его превосходительства [Густава Бирона] подвод”. В 1739
году он получил звание прапорщика, а через два года – подпоручика.
1 марта 1738 года у него родилась дочь Мария, восприемницей от купели
(крестной матерью) была Елизавета Петровна. "
      />
      <Typography
        title="В событиях 25 ноября 1741 года он, по-видимому, также как и его брат –
Михаил, принимает активное участие, и ему доверяет Елизавета Петровна
сопровождать в Ригу свергнутую правительницу Анну Леопольдовну с
семьей. В 1742 г. Роман Воронцов получил свое первое придворное звание
камер-юнкера. В 1745 г. в числе других камер-юнкеров Р.И. Воронцов
участвовал в церемонии бракосочетания великого князя Петра Федоровича с
великой княжной Екатериной Алексеевной. В 1745 г. умирает его жена
Марфа Ивановна, оставив пятерых детей. Младшему Семену не было и года.
Дочерей Марию и Елизавету Елизавета Петровна определяет ко двору
фрейлинами, Cемена отправляют к дедушке Иллариону Гавриловичу.
Младшую дочь Екатерину - к бабушке Сурминой Федосье Артемьевне, где
она живет до четырех лет, а затем ее забирает в свой дом дядя, Михаил
Илларионович. Там она воспитывается с его дочерью Анной, своей
двоюродной сестрой. В доме отца остался только старший сын Александр.
Многие мемуаристы и историки считали, что Роман Илларионович мало
занимался воспитанием своих детей, однако факты говорят о другом. Он
постоянно уделял им внимание, старался дать им хорошее образование,
выписал из Берлина гувернантку для своего старшего сына, и “незаметно”,
как пишет Александр, он научился французскому языку, а с шестилетнего
возраста “обнаружил решительную склонность к чтению”. Они часто бывали
с отцом в придворном театре, где два раза в неделю давали французские
комедии, причем Роман Илларионович имел свою ложу. Александру не было
и двенадцати лет, а он уже хорошо знал произведения Вольтера, Расина,
Корнеля, Буало и других французских писателей. В 1758 г. 16-ти летнего
Александра Воронцова отправляют за границу на учебу в
привилегированную школу в Версале, где обучались дети самых знатных
вельмож Франции. Немного позже второй сын Романа Илларионовича
Семен, тоже в шестнадцатилетним возрасте совершает поездку по
воронцовским имениям до Тобольска и Астрахани. Для обоих братьев эти
путешествия имели большое познавательное и образовательное значение.
Отец умно и умело готовил из них государственных деятелей. В архиве
семьи Воронцовых сохранилось несколько сотен писем Романа
Илларионовича к детям. Перед нами возникает живой образ человека умного,
деятельного, заботливого, требовательного и справедливого. Отдельные его
фразы из писем к сыну Александру за границу звучат как афоризмы:
“мотовством доброго имени не наживешь...”, “берегись дурных людей...”,
“худая компания портит добрый нрав...”, “никому не будь должен и чтобы
тебе не были должны господа, с которыми ты в товариществе”."
      />
      <Typography
        title="Отец хочет, чтобы сын посетил как можно больше европейских стран. Он
советует ему обязательно посетить Голландию, а по возвращении во
Францию “предпринять путь в Италию, объездить все, не оставляя ни одного
города. Журнал всему вести порядочный... Только прошу, больше всего
береги свое здоровье... И гору, как будешь смотреть Этну в Неаполе, то
прошу на нее не ходить...”.
Роман Илларионович настоятельно рекомендует сыну познакомиться с
Вольтером. Журит его за небрежный почерк. В письмах к сыну он сообщает
ему и новости семейной и придворной жизни."
      />
      <Typography
        title="Дружеские отношения Елизаветы Петровны с семьей Р.И. Воронцова
сохранились и в дальнейшем. Летом 1760 г. он находится в ее свите в
Царском селе и 17 июля в день рождения Романа Илларионовича она
“изволила пить его здоровье и приказала палить из пушек, и сделан был бал,
что при нынешнем случае за редкость почитается”, писал секретарь Романа
Илларионовича Александру Романовичу во Францию."
      />
      <Typography
        title="В 1760 г. происходит еще одно важное событие в жизни семьи Воронцовых.
Вот что пишет Роман Илларионович об этом сыну: “Его Римско-
Императорское величество милостивейше соизволил пожаловать меня и
брата Ивана Илларионовича графским достоинством”."
      />
      <PageTitle title="ВО ГЛАВЕ ЗАКОНОДАТЕЛЬНОЙ КОМИССИИ" />
      <Typography
        title="Свое родовое гнездо Андреевское, Роман Илларионович отстраивал с
большим размахом, соответствовавшим его графскому титулу, должности и
общественному положению. Архитектурный комплекс в Андреевском
спроектировал и непосредственно руководил строительством, личный
архитектор Воронцовых – Николай Петрович фон Берк. Он же разработал и
первый регулярный план застройки Владимира, но уже будучи губернским
архитектором. По характеру застройки и организации пространства
Андреевское скорее напоминало город в миниатюре, а не родовую
дворянскую усадьбу. Усадьба, организованная в междуречье Пекши и
Нергели, образующие участок в виде большого полуострова, были чётко
выделены: главная площадь, улицы, дома многочисленной челяди, каменные,
хозяйственные постройки различного назначения. Все эти улицы, площади и
дворы-усадьбы, были вымощены диким камнем-булыжником. Особо следует
отметить, что в то время не каждый город мог похвастать наличием мощеных
улиц и площадей. Горожане ходили либо по земле, или довольствовались
деревянным настилом."
      />
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/1/1-2.jpg)",
        }}
      />
      <Typography
        title="В 1760 г. Роман Илларионович Воронцов назначается Сенатором и
председателем Комиссии по новому законодательству. Вопрос о составлении
нового “Уложения” и об организации для этого особой Комиссии при Сенате,
был решен еще в марте 1754 года. Тогда же и было принято “решение”
Елизаветы Петровны “о преимуществе пред прочими делами сочинить ясные
законы и в том начало положить”. Возглавлял Комиссию П.И. Шувалов,
фактический руководитель внешней и внутренней политики России, но
подготовка к войне, шедшей в Европе, приостановила работу над новым
Уложением."
      />
      <Typography
        title="Р.И. Воронцов, став председателем Комиссии, начал с проверки работы
прежнего состава. “Сочинение нового Уложения у нас скоро начнется, -
пишет он сыну, - и я буду прилагать возможное старание, чтобы оно вскоре и
совершено было. Теперь дело идет в том, чтобы собрать к этому делу
надобных и способных людей”. Из протоколов Комиссии видно, что Р.И.
Воронцов и М.И. Шаховской приступили к работе 24 октября 1760 года. В
ноябре состоялось пополнение и переформирование комиссии, а с декабря
началась работа над проектом Уложения. Уже через год на заседании
комиссии было решено поручить редакцию текста Уложения “в чистоте
российского штиля искусным и совершенно знающим российский язык
людям”. Для этого были приглашены адъюнкты Академии наук Г. Козицкий
и М. Мотонис, ставшие позднее (в Екатерининское время) известными
литераторами. На посту председателя Комиссии Р.И. Воронцов отстаивал
программу дворянской монополии на землю и владение крестьянами, на
развитие дворянского предпринимательства. В своем имении Опалах
Костромской губернии он строит полотняную фабрику и обучает крестьян
для работы на ней. На своем примере он пытался показать, что
предпринимательская деятельность нисколько не умаляет дворянское
достоинство."
      />
      <Typography
        title="Еще одной заслугой Комиссии под председательством Р.И. Воронцова
является постановление от 1 марта 1761 года о созыве “выборных от дворян
и купечества к слушанию проекта Уложения”. План Комиссии
предусматривал делегирование 2-х депутатов от дворян и 1-го от купечества
от каждой провинции."
      />
      <Typography
        title="К концу царствования Елизаветы Петровны Комиссия закончила почти все
Уложение и остановилась только на главе “о дворянских преимуществах”.
Возможно, что Воронцов сознательно задерживал обсуждение этой очень
важной для дворянства главы. Ведь в проекте фундаментальных законов,
составленном фаворитом Елизаветы Петровны И.И. Шуваловым, было
сказано: “дворянству служить 26 лет”. В новом проекте Уложения
предусматривалось освобождение дворян от обязательной службы. Поэтому
Р.И. Воронцов не знакомил императрицу с этим разделом, зная, что она не
поддержит его, а связывал получение “вольности дворянской” с новым
императором."
      />
      <Typography
        title="25 декабря 1761 года Елизавета Петровна умерла, и уже 20 февраля 1762 года
был опубликован манифест Императора Петра III “О вольности дворянской”,
который и был полностью включен в проект III главы Уложения. Кроме
освобождения от дворянской службы в новом Уложении был большой раздел
об экономических правах и преимуществах дворян: устанавливалось право
дворян на владение землей, право торговать продуктами сельского хозяйства,
устройство заводов, объявлялась монополия дворян на заведение
винокуренных заводов, гарантировался свободный выезд за границу для
получения образования. Программа, разработанная Комиссией под
руководством Воронцова служила интересам многих дворян, но она не
получила поддержку в Сенате. Но Р.И. Воронцов не отказался от борьбы за
свою программу. Определенные Сенатом сроки для проведения выборов и
явки депутатов для обсуждения нового Уложения, были явно недостаточны,
и все же выборы состоялись. Особую активность проявили депутаты от
купечества. В архиве Воронцовых сохранились две записки, в одной
содержался подробный разбор и критика главы Уложения о “купеческом
праве”, вторая записка была направлена против слишком широких
дворянских привилегий и требовала уравнения в некоторых вопросах прав
купечества и дворянства. Обсуждение нового законодательства проходило в
первое полугодие 1762 года. В постановлении Комиссии от 18 июня
отмечено отсутствие многих депутатов от дворянства. Через 10 дней 28 июня
произошел дворцовый переворот в пользу Екатерины Алексеевны. А уже 25
июля последовал указ о переводе Комиссии в Москву по случаю коронации
Императрицы. Указом 13 января 1763 года под предлогом, что “Уложение
еще к совершенству не приведено”, депутаты были распущены.
Он был одним из учредителей Вольного экономического общества,
основанного в России в 1765 г. В «Трудах Вольного экономического
общества» Р. Воронцов публиковал статьи, в которых предлагал держать в
селах специальные запасы хлеба для избежания голода в неурожайные годы,
создать крестьянский суд «из лучших людей», а господ призывал
«обходиться с ласкою» с крестьянами. Манифест о созыве новых депутатов,
представляющих все сословия, кроме крепостных крестьян и духовенства,
был издан в декабре 1766 года. Выборы проходили по всей России в течение
первого полугодия 1767 года. Было избрано 572 депутата: от дворян - 165, от
городов - 208, от правительственных учреждений - 28, 42 - от однодворцев,
45 - от казаков, 29 - от государственных крестьян, 54 - от нерусских народов
Сибири, Севера и Поволжья. При выборах депутаты снабжались
письменными наказами своих избирателей. Роман Илларионович был
представителем Шлиссельбургского дворянства, а также поверенным для
выбора головы и депутата г. Москвы. Его подпись стоит в числе первых под
текстом наказа московского дворянства своему депутату П. И.Панину. В нем
говорится о пользе “совершенного согласования мудрости, естественной
справедливости, и глубокого познания моральных и физических свойств
жителей земли”."
      />
      <Typography
        title="Московское дворянство просило разрешить свободу циркуляции дворянских
имений, “чтобы дворянству позволено было продавать продукты, заводить и
содержать фабрики и мануфактуры, предпринимать всякие промыслы...”.
Открытие съезда депутатов для рассмотрения Уложения состоялось в Москве
30 июля 1767 года. После торжественной литургии, проходившей в
Успенском соборе Московского Кремля, пятьсот депутатов подписали
присягу, обещая добросовестно выполнять свои обязанности. "
      />
      <Typography
        title="На следующий день в 7 часов утра депутаты собирались на свое первое
заседание. “Все явились с довольно радостным видом и в разговорах между
собой высказывали ревность свою к тому делу, для которого они были
созваны”. Английский дипломат писал в августе 1767 года: “Русские не
говорят и не думают ни о чем другом, как о собрании депутатов, и
заключают, что теперь они составляют мудрейшую, счастливейшую и
могущественнейшую нацию во всей вселенной”. На съезде Р.И. Воронцов
был избран в Комиссию для разбора депутатских наказов. В октябре он
обращается к сыну с просьбой “потрудиться о переводе сочинения
Монтескеева о Законе или Разуме законов”. Великому французскому
писателю-энциклопедисту Монтескье принадлежит заслуга глубокой
разработки вопросов права, в его трудах отразились все черты эпохи
Просвещения. Сама Екатерина Вторая считала его сочинения “О духе
законов” “молитвенником монархов со здравым смыслом”. Руководствуясь
идеями Монтескье, Екатерина и решила собрать выборных представителей
разных сословий, которые бы выработали законы, удовлетворяющие
интересам всего народа, а не только дворян. Екатерина пишет “Наказ” -
своеобразное руководство для депутатов. “Наказ” был издан тиражом 600
экземпляров, и каждый депутат должен был с ним ознакомиться. Главная
статья “Наказа” и сейчас звучит современно: “Равенство всех граждан
состоит в том, чтобы все подвержены были тем же законам”. Работа съезда
депутатов закончилась в декабре 1768 года. Депутаты не имели
политического опыта, погрязли в мелочных дискуссиях, часто повторяли
друг друга, много времени тратили впустую. Начавшаяся война с Турцией
отвлекала многих депутатов-дворян от работы по Уложению. 18 декабря им
был объявлен указ о прекращении заседаний. Звание депутата сохранялось за
членами комиссии пожизненно, в указе говорилось, что Комиссия
распускается на время, но она была расформирована навсегда."
      />
      <PageTitle title="ВЕЛИКИЙ НАМЕСТНЫЙ МАСТЕР" />
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/1/1-3.jpg)",
        }}
      />
      <Typography
        title="Представляя себе политическую фигуру Романа Илларионовича Воронцова
нельзя забывать, что он, вообще, играл заметную роль в жизни тогдашнего
общества и много лет являлся одним из главных деятелей русского
масонства."
      />
      <Typography
        title="Масонские идеи начали проникать в Россию еще при Петре I, но
свидетельство о существовании масонских лож относится к 1731 г., а в 1747
г. было предпринято первое правительственное расследование о сущности и
цели масонского учения. В 1756 г. графом А.И. Шуваловым было
представлено императрице Елизавете Петровне показание Михаила
Олсуфьева о масонской ложе в Петербурге. В списке “грантметрам и
масонам” перечислено 35 лиц, среди которых первым стоит Р.И. Воронцов,
вторым - драматург и поэт Александр Сумароков. Затем следуют имена
будущих историков и литераторов того времени: князя М.М. Щербатова, И.
Болтина, Ф. Мамонова и П. Свистунова. В списке значатся офицеры
кадетского корпуса, аристократы самых знатных фамилий, местом службы
которых были Преображенский, Семеновский и Измайловский полки: князь
Михаил Дашков, трое князей Голицыных, Сергей Трубецкой, П. Бутурлин и
др. "
      />
      <Typography
        title="Заканчивается список именами двух музыкантов, танцмейстера, купца
Миллера. Р.И. Воронцов был одним из первых активных деятелей русского
масонства.  По преданию, Петр III учредил масонскую ложу в Ораниенбауме
и подарил петербургской ложе, называвшейся “Постоянство”, дом, в котором
обязанности великого мастера исполнял Р.И. Воронцов, а членами ее были
преимущественно молодые гвардейские офицеры.
Позже, уже при Екатерине II, из диплома, данного при учреждении ложи
“Муз” в 1772 г., видно, что великим наместным мастером был Р.И. Воронцов,
а провинциальным великим секретарем - Василий Майков, известный поэт.
В протоколах ложи “Урания” (1гг.) личный состав провинциальной ложи
представлен так: великий провинциальный мастер - Е.П. Елагин, великий
провинциальный наместный мастер - Р.И. Воронцов. В 1774 году в
Петербурге открывается ложа “Скромности”, великим наместным мастером
которой является Р.И. Воронцов. С открытием во Владимире наместничества
в 1778 г. утверждается масонская ложа, которой управлял секретарь
Воронцова  П.И.Берг. Идеи масонов о человеческом достоинстве, о любви к
людям, о свободе совести, о всеобщем равенстве, об уважении к самому себе
и другим, бесспорно, оказывали благотворное влияние на передовое русское
общество. Масоны занимались благотворительностью, устройством больниц
и аптек, школ и типографий, изданием учебников и книг духовного
содержания и распространением их, а также покровительствовали
литературным и научным трудам."
      />
      <PageTitle title="ПРИ ДВОРЕ ПЕТРА ТРЕТЬЕГО" />
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/1/1-4.jpg)",
        }}
      />
      <Typography
        title="С восшествием на престол Петра III, Воронцовы получают новые почести и
награды. Вот как описывает это Роман Илларионович в письме к сыну
Александру: '…что до моей фамилии касается, то я всемилостивейше
        пожалован генерал-аншефом и орденом Андрея Первозванного, брат Иван
        Ларионович, генерал-поручиком и сенатором, ты камергером, сестра графиня
        Елизавета Романовна камер-фрейлиною, брат граф Семен Романович в
        поручики гвардии… Кредитная грамота на твой министерский чин
        подписана Его Императорским Величеством'. Петр III частый гость и в доме
        канцлера, и в доме Романа Илларионовича. Он крестный отец его младшей
        дочери Екатерины Романовны, со старшей дочерью Елизаветой Романовной
        – его фавориткой он собирается обвенчаться и отправить свою жену
        Екатерину Алексеевну в монастырь. В день торжества мира с Пруссией Петр
        награждает Елизавету Романовну Воронцову орденом Святой Екатерины:
        такой чести удостаивались только члены царской фамилии. Первыми шагами
        Императора Всероссийского Петра III было уничтожение тайной канцелярии
        и издание манифеста о вольности дворянской. Все это было встречено
        прогрессивным дворянством с ликованием. Вот что пишет граф Чернышев в
        письме 2 марта 1762 года: 'Милостям Государя Императора нет конца.
        Продли Господь столь славнее и народу благоприятное государствование…
        Какие несказанные и неожиданные милости: вольность дворянству, рушение
        тайной канцелярии, сложение 25 коп. с пуда соли, и все это в шесть недель'.
        При дворе Петра III были различные группировки, пытавшиеся каждая по-
        своему воздействовать на императора. Одну из них составляли русские
        сановники – братья Михаил и Роман Воронцовы, генерал-прокурор А.И.
        Глебов и секретарь императора Д.В. Волков. Другую – голштинская партия, к
        которой примыкал и любимец Петра III его адъютант А.В. Гудович.
        У Петра III не было самостоятельной программы экономических реформ. Его
        действия были хаотичны и непоследовательны. Он руководствовался в
        основном эмоциями и чужими законодательными идеями. Поэтому
        Воронцовы и постарались использовать свое влияние в первую очередь для
        реализации тех замыслов и проектов, которые разрабатывались еще в
        царствование Елизаветы Петровны. Этим во многом объясняется появление
        первых манифестов и указов, так горячо встреченных дворянами. Гораздо
        больше самостоятельности проявил Петр в области внешней политики. Но
        его действия диктовались собственными пристрастиями и амбициями, и не
        отражали национальные интересы страны. Петр III подписывает мирный
        контракт с Пруссией, спасая ее от полного разгрома, отпускает всех пленных,
        возвращает все занятые русскими войсками земли. В феврале 1762 года всем
        иностранным послам была вручена декларация, призывавшая к
        установлению в Европе всеобщего мира и отказу от всех завоеваний
        Семилетней войны. На самом деле Петр III собирался начать войну с Данией,
        таким образом, развязать новую войну в Европе. Сама идея войны в союзе со
        вчерашним противником Пруссией была очень непопулярна, особенно в
        гвардии, которую Петр собирался вывести из Петербурга. Петр Федорович
        открыто выражал свое преклонение перед Фридрихом II, носил прусский
        мундир со знаком прусского ордена Черного Орла и гордился званием
        генерал-майора прусской армии. Кроме гвардии, другой влиятельной частью
        русского общества недовольной Петром III, было духовенство. Громко
        восхваляя достоинства прусской армии во время богослужения, Петр III
        оскорблял национальное достоинство православных прихожан. Поведение
        Петра, опьяненного самодержавной властью, который подчинялся только
        своим эмоциям, оскорбляло патриотическое и религиозное чувства русских
        людей. Переворот, произошедший 28 июня 1762 года и возведший на
        престол Екатерину II, был закономерен и неизбежен. Всем своим поведением
        Екатерина Алексеевна показывала свое несогласие с политикой и поведением
        мужа. "
      />
      <Typography
        title="Она отказалась участвовать в торжественном обеде по случаю подписания
мира с Пруссией 24 апреля 1762 года.  В своем узком кругу Екатерина
критически отзывалась о “преобразованиях” Петра и о его отношении к
Русской православной церкви. 9 июня на обеде, Петр III публично оскорбил
Екатерину и отдал распоряжение об ее аресте, и только вмешательство дяди,
принца Георга Голштинского, спасло ее. Вся история заговора и переворота
свидетельствует о незаурядной выдержке, мужестве и уме будущей
императрицы, о блестящем знании людей и умении использовать их таланты
и способности в свою пользу. Во время переворота Воронцовы вместе с
другими придворными Петра III находились в Ораниенбауме. Утром 28 июня
все придворное общество отправилось для празднования дня святых Петра и
Павла в Петергоф, где должна была находиться Императрица. Но Екатерины
Алексеевны там не оказалось. Это вызвало тревогу. Завидев в Петербурге
дым со стороны крепости, вероятно от пушечной стрельбы, Никита
Трубецкой, канцлер Воронцов и А.И. Шувалов отправились в Петербург с
целью узнать, что там делается и привезти точные сведения. “...Граф Р.И.
Воронцов и Волков диктуют и пишут именные указы, и государь
подписывает их на поручне канального шлюза. Адъютанты отправляются с
этими указами в разные полки и команды”, - писал в своих мемуарах Якоб
Штелин. "
      />
      <Typography
        title="А в это время в Петербурге Екатерина, сопровождаемая гвардейцами,
прибыла к Казанскому собору. Сюда же привезли великого князя Павла.
Состоялось торжественное провозглашение Екатерины самодержавной
императрицей, а Павла - наследником. Но и среди гвардейцев нашлись
офицеры, отказавшиеся нарушить присягу, данную Петру III. Среди них
оказался Семен Романович Воронцов, который был арестован. Петр III, узнав
о событиях в Петербурге, после долгих колебаний по совету фельдмаршала
Миниха решил отплыть в Кронштадт, но было уже поздно, кронштадский
гарнизон перешел на сторону Екатерины. Яхта и галеры вернулись в
Петергоф, куда в 5 часов утра прибыл из Петербурга первый отряд под
началом поручика Алексея Орлова. В 11 часов появилась Екатерина верхом,
в мундире Преображенского полка в сопровождении Екатерины Романовны
Дашковой, и конногвардейского полка. Петр III подписал отречение от
престола. Он под конвоем был отправлен в Ропшинский дворец и вскоре был
убит. Елизавета Романовна Воронцова была также под конвоем отправлена
сначала в петербургский дом своего отца, а затем выслана в Москву с
негласным советом Императрицы, “чтобы она в Москве жила в тишине, не
подавая людям много причин о себе говорить...”. Надо сказать, что в
дальнейшем Екатерина II проявила заботу о своей бывшей сопернице. Через
своего секретаря И.П. Елагина она купила для нее за свой счет дом в Москве,
а спустя много лет дочь Елизаветы Романовны была принята ко двору
фрейлиной.Об участии ее сестры Екатерины Романовны в перевороте сама
Императрица сказала: “Кто бы мог подумать, что дочь Романа Воронцова
поможет мне сесть на престол”. Опала Воронцовых длилась недолго. Уже 7
июля 1762 года канцлер Михаил Воронцов пишет своему племяннику
Александру Романовичу: “Я получил от твоего отца приятное уведомление,
что Ея Императорское Величество ему повелеть изволила завтра ко двору
быть, где мы оба будем иметь радость к стопам Ея Императорского
Величества прибегнуть... Ея Императорское Величество милостиво повелеть
изволила отправить брата твоего Семена Романовича дворянином посольства
в Вену”. Воронцовы были прощены. В бумагах Екатерины II сохранился
собственноручный список сенаторов, которые должны присутствовать на ее
коронации в Москве 22 сентября 1762 года. Среди прочих знатных особ -
трое братьев Воронцовых: Михаил, Роман и Иван."
      />
      <PageTitle title="В ПРАВИТЕЛЬСТВУЮЩЕМ СЕНАТЕ" />
      <img
        style={{
          width: "100%",
          alignSelf: "center",
          content: "url(/articles/1/1-5.jpg)",
        }}
      />
      <Typography
        title="В 1763 г. была проведена реформа Сената. Правительствующий Сенат,
основанный Петром I в 1711 г. перед прусским походом во время Русско-
турецкой войны, должен был заменить самого государя в его отсутствие.
После смерти Петра I значение Сената значительно уменьшилось, особенно с
учреждением Верховного Тайного совета, который был распущен при Анне
Иоанновне. Она создала кабинет министров, на смену которому при
Елизавете Петровне пришла Конференция при высочайшем дворе.
Ко времени Екатерины II Сенат превратился в чисто бюрократическое
учреждение. Дело доходило до того, что указы Сената на местах практически
не выполнялись. Ознакомившись с состоянием дел в Сенате, Екатерина
убедилась в необходимости его реформы. По ее указу Сенат был разделен на
шесть департаментов - четыре в Петербурге и два в Москве. Первый
департамент ведал делами государственной внешней и внутренней политики;
второй - судебными; третий - делами Малороссии, Лифляндии, Эстляндии,
Выборгской губернии и Нарвы; четвертый - военными и морскими; пятый и
шестой - административными и судебными делами в Москве.
Сохранилась собственноручная запись указа Екатерины II, где она
определила Михаила Воронцова в первый департамент, Романа – в
четвертый, а Ивана - в пятый департамент Сената. В начале царствования
Екатерины II братья Воронцовы еще занимали ключевые посты в Сенате, но
в дальнейшем, хотя они продолжали занимать высокие посты, их влияние
при дворе уменьшилось."
      />
    </FlexColumn>
  ),
};
