import React, { PropsWithChildren } from "react";
import { IHeaderProps } from "./HeaderTypes";
import { HeaderWrapper, Logo } from "./HeaderStyle";
import { Link } from "react-router-dom";

export const Header: React.FC<PropsWithChildren<IHeaderProps>> = ({
  children,
}) => {
  return (
    <HeaderWrapper>
      <Link to="/">
        <Logo />
      </Link>
      {children}
    </HeaderWrapper>
  );
};
