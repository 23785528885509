import styled from "styled-components";
import { getItemSecondaryBackground } from "selectors/theme";

export const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-height: 120px;
  max-height: 140px;
  background-color: ${getItemSecondaryBackground};
  padding: 20px 40px;

  @media screen and (max-width: 600px) {
    padding: 20px 10px;
  }
  @media screen and (max-width: 600px) {
    padding: 20px 2px;
  }
`;
