import styled from "styled-components";
import { ReactComponent as LogoLionSvg } from "assets/images/logo-huge.svg";
import { ReactComponent as LogoAcaciaSvg } from "assets/images/acacia.svg";
import { getItemPrimaryText } from "../../selectors/theme";

export const EmailLink = styled.a`
  align-self: center;
  text-decoration-color: ${getItemPrimaryText};
`;

export const LogoLion = styled(LogoLionSvg)`
  min-width: 300px;
  max-width: 300px;
  min-height: 300px;
  max-height: 300px;

  @media screen and (max-width: 1120px) {
    align-self: center;
  }
`;

export const LogoAcacia = styled(LogoAcaciaSvg)`
  min-width: 300px;
  max-width: 300px;
  min-height: 300px;
  max-height: 300px;

  @media screen and (max-width: 1120px) {
    align-self: center;
  }
`;
