import React from "react";
import { Typography } from "../../components/shared/Typography/Typography";
import { PageWrapper } from "../../components/shared/PageWrapper/PageWrapper";
import { FlexColumn } from "../../components/shared/SharedStyle";

export const Library = () => {
  return (
    <div>
      <img
        style={{
          width: "100vw",
          content: "url(/library/lib.jpg)",
        }}
      />
      <PageWrapper
        title="О МАСОНСТВЕ : ЕГО СМЫСЛЕ И СИМВОЛАХ"
        hasHugePadding={false}
      >
        <FlexColumn>
          <Typography align="center" title="Джон Робинсон, «Тропа пилигрима»" />
          <Typography
            align="center"
            title="Р. Смоули, «Гностики, катары, масоны или запретная вера»"
          />
          <Typography
            align="center"
            title="Е. Щукин, «Коса и камень. Зелот о масонстве»"
          />
          <Typography
            align="center"
            title="У. Л. Уилмхерст, «Масонское посвящение»"
          />
          <Typography
            align="center"
            title="У. Л. Уилмхерст, «Смысл масонства»"
          />
          <Typography align="center" title="П. Нодон, «Масонство»" />
          <Typography
            align="center"
            title="М. Бейджент, Р. Ли, «Храм и Ложа»"
          />
          <Typography
            align="center"
            title="С.В. Аржанухин, «Философские взгляды русского масонства»"
          />
          <Typography
            align="center"
            title="А. Пятигорский, «Кто боится вольных каменщиков?»"
          />
          <Typography
            align="center"
            title="Р. Генон, «Символы священной науки»"
          />
          <Typography
            align="center"
            title="Д.М. Грир, «Скрытые истории тайных обществ»"
          />
          <Typography
            align="center"
            title="Губерт Бокс, «Природа Франкмасонства»"
          />
          <Typography align="center" title="Ф. Бейли, «Дух Масонства»" />
          <Typography
            align="center"
            title="Альберт Пайк, «Мораль и Догма Древнего и Принятого Шотландского
Устава»"
          />
          <Typography align="center" title="Альберт Пайк, «Смысл Масонства»" />
          <Typography align="center" title="Карл Клауди, «Книга Ученика»" />
          <Typography align="center" title="Освальд Вирт, «Книга Ученика»" />
          <Typography
            align="center"
            title="Мэнли Палмер Холл, «Энциклопедическое изложение масонской,
герметической, каббалистической и розенкрейцеровской символической
философии»"
          />
          <Typography
            align="center"
            title="Мэнли Палмер Холл, «Утерянные ключи Масонства или секрет Хирама
Абиффа»"
          />
          <Typography
            align="center"
            title="Мэнли Палмер Холл, «Оккультное масонство»"
          />
          <Typography
            align="center"
            title="Папюс, «Генезис и развитие масонских символов»"
          />
          <Typography
            align="center"
            title="Микеле Морамарко, «Масонство в прошлом и настоящем»"
          />
        </FlexColumn>
      </PageWrapper>
      <PageWrapper title="ИЗ ИСТОРИИ МАСОНСТВА">
        <FlexColumn>
          <Typography
            align="center"
            title="Т. Соколовская, «Русское масонство и его значение в истории
общественного движения»"
          />
          <Typography align="center" title="Н. Берберова, «Люди и ложи»" />
          <Typography
            align="center"
            title="Е. Глаголева, «Повседневная жизнь масонов в эпоху Просвещения»"
          />
          <Typography
            align="center"
            title="Дуглас Смит, «Работа над диким камнем. Масонский орден и русское
общество в XVIII в.»"
          />
          <Typography
            align="center"
            title="Г. Вернадский, «Русское масонство в царствование Екатерины II»"
          />
          <Typography
            align="center"
            title="А. Л. Никитин, «Мистики, розенкрейцеры, тамплиеры в Советской
России»"
          />
          <Typography
            align="center"
            title="А. Л. Никитин, «Эзотерическое масонство в Советской России»"
          />
          <Typography
            align="center"
            title="«Премудрость Астреи: памятники масонства XVIII-XIX вв. в собрании
Эрмитажа»"
          />
          <Typography
            align="center"
            title="Т. Соколовская, Д. Лотарева, «Тайные архивы русских масонов»"
          />
          <Typography
            align="center"
            title="Т. Бакунина, «Русские Вольные Каменщики»"
          />
          <Typography
            align="center"
            title="В.И. Новиков,  «Масонство и русская культура»"
          />
          <Typography
            align="center"
            title="Т. Соколовская,  «Статьи по истории русского масонства»"
          />
        </FlexColumn>
      </PageWrapper>
    </div>
  );
};
