import { gallery0 } from "./gallery0";
import { gallery1 } from "./gallery1";

export type GalleryContent = {
  id: number;
  title: string;
  imageURL: string;
  currentMaxValue: number;
};

export const galleryContent: GalleryContent[] = [gallery1, gallery0];
