import React from "react";

export enum TabIcon {
  CALENDAR = "CALENDAR",
  EYE_CLOSED = "EYE_CLOSED",
  EYE_OPENED = "EYE_OPENED",
  MOON = "MOON",
  PILL = "PILL",
  WAND = "WAND",
}

export interface ITabStateProps {
  title: string;
  icon?: TabIcon;
  isActive: boolean;
}

export interface ITabDispatchProps {
  onClick(event: React.MouseEvent): void;
}

export interface ITabProps extends ITabStateProps, ITabDispatchProps {}
