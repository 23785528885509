import React from 'react'

export const useClickAway = (
  ref: React.RefObject<HTMLElement>,
  e: (...args: any) => void,
) => {
  React.useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    const handleClickAway = (event: any) => {
      if (ref.current && !ref.current.contains(event.target)) {
        e()
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickAway)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickAway)
    }
  }, [ref, e])
  return null
}
