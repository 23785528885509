import React, { PropsWithChildren, useRef, useState } from "react";
import { useClickAway } from "lib/hooks/useClickAway";
import { IListDropdownProps } from "./ListDropdownTypes";
import { ListDropdownWrapper, ListItemsWrapper } from "./ListDropdownStyle";

export const ListDropdown: React.FC<PropsWithChildren<IListDropdownProps>> = ({
  className,
  state,
  trigger,
  size = "l",
  children,
}) => {
  const [isOpen, setOpen] = state;
  const ref = useRef(null);
  useClickAway(ref, () => setOpen(false));
  return (
    <ListDropdownWrapper className={className} ref={ref}>
      {isOpen && (
        <ListItemsWrapper className={`list-dropdown-size-${size}`}>
          {children}
        </ListItemsWrapper>
      )}
      <div onClick={() => setOpen((state) => !state)}>{trigger}</div>
    </ListDropdownWrapper>
  );
};
