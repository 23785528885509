import React from "react";
import { IPageTitleProps } from "./PageTitleTypes";
import { PageTitleWrapper } from "./PageTitleStyle";
import { Typography } from "../Typography/Typography";
import {
  TypographyColor,
  TypographyVariant,
} from "../Typography/TypographyTypes";

export const PageTitle: React.FC<IPageTitleProps> = ({ title, onClick }) => {
  return (
    <PageTitleWrapper onClick={onClick}>
      <Typography
        title={title}
        align="center"
        variant={TypographyVariant.HEADER}
        color={TypographyColor.PRIMARY}
      />
    </PageTitleWrapper>
  );
};
