import styled from "styled-components";
import {
  getItemCorrectText,
  getItemDangerText,
  getItemDisabledText,
  getItemPrimaryText,
  getItemSecondaryText,
} from "selectors/theme";

export const TypographyInner = styled.span`
  color: ${getItemPrimaryText};
  font-style: normal;

  &.typography-justify {
    text-align: justify;
  }
  &.typography-center {
    text-align: center;
  }
  &.typography-start {
    text-align: start;
  }
  &.typography-end {
    text-align: end;
  }

  &.typography-variant-header {
    font-size: 24px;
    line-height: 36px;
    font-weight: 700;
  }

  &.typography-variant-basic-text,
  &.typography-variant-basic-italic-text,
  &.typography-variant-basic-bold-text {
    font-size: 18px;
    line-height: 27px;
  }

  &.typography-variant-basic-text,
  &.typography-variant-basic-italic-text,
  &.typography-variant-small-text {
    font-weight: 400;
  }

  &.typography-variant-basic-bold-text,
  &.typography-variant-small-bold-text {
    font-weight: 700;
  }

  &.typography-variant-basic-italic-text {
    font-style: italic;
  }

  &.typography-variant-small-text,
  &.typography-variant-small-bold-text {
    font-size: 13px;
    line-height: 19.5px;
  }

  &.typography-color-secondary {
    color: ${getItemSecondaryText};
  }

  &.typography-color-correct {
    color: ${getItemCorrectText};
  }

  &.typography-color-danger {
    color: ${getItemDangerText};
  }

  &.typography-color-disabled {
    color: ${getItemDisabledText};
  }
`;
