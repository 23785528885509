import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { rebrandingAPI } from 'services/RebrandingService'
import theme from './theme'

export const rootReducer = combineReducers({
  theme,
  [rebrandingAPI.reducerPath]: rebrandingAPI.reducer,
})

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rebrandingAPI.middleware),
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
