import React from "react";
import { useNavigate } from "react-router-dom";
import { PageTitle } from "../../components/shared/PageTitle/PageTitle";
import { Card } from "../../components/shared/Card/Card";
import { newsContent } from "./content/newsContent";
import { newsPath } from "../AppRoutes";
import { NewsWrapper, Wrapper } from "./NewsStyle";

export type NewsProps = {
  isPage?: boolean;
};

export const News = ({ isPage = false }: NewsProps) => {
  const navigate = useNavigate();

  return (
    <>
      <PageTitle title="НОВОСТИ СИМВОЛИЧЕСКОГО ТРЕУГОЛЬНИКА" />
      <Wrapper className={isPage ? "" : "no-wrap"}>
        <NewsWrapper className={isPage ? "wrap" : ""}>
          {newsContent.map((news) => {
            const { title, id, imageURL, preview } = news;
            return (
              <Card
                key={`news-${id}`}
                title={title}
                content={preview}
                imageURL={imageURL}
                onClick={() => navigate(isPage ? `${id}` : `${newsPath}/${id}`)}
              />
            );
          })}
        </NewsWrapper>
      </Wrapper>
    </>
  );
};
