import React from "react";
import { Typography } from "../../components/shared/Typography/Typography";
import { PageWrapper } from "../../components/shared/PageWrapper/PageWrapper";
import { FlexColumn, FlexRow } from "../../components/shared/SharedStyle";
import { Logo } from "./GODFStyle";

export const GODF = () => {
  return (
    <PageWrapper title="ВЕЛИКИЙ ВОСТОК ФРАНЦИИ">
      <FlexColumn>
        <FlexRow>
          <Typography
            title="Стремление к прогрессу, самосовершенствованию и просвещению, всегда
было для масонов Великого Востока Франции движущей силой в их
размышлениях и действиях. Этот принцип стал движущей силой и
неотъемлемой частью Традиции нашего послушания. Вольные каменщики-
духовные и нравственные приемники мужчин и женщин, каждый из которых
по-своему трудился над улучшением и постоянным совершенствованием
рода человеческого: Вольтер, Лафайет, Гарибальди, Огюст Бланки, Виктор
Шельхер, Эмир Абд Эль-Кадер, Луиза Мишель, Бакунин, Жан Зай, Феликс.
Эбуэ, Пьер Броссолетт и многие другие. Мы искренне гордимся тем, что они
обогатили и наши ложи своим присутствием. Масонский орден Grand
Orient de France, является  защитником принципов, содержащихся в его
девизе: «Свобода, Равенство, Братство». Великий Восток Франции привязан
к абсолютной свободе совести и волеизъявления, которая гарантируется
секуляризмом наших институтов."
          />
          <Logo />
        </FlexRow>
        <Typography
          title="«Коронованный Лев» , является единственным  в России символическим
треугольником Великого Востока Франции, работающем в смешанном
режиме - то есть его членами могут наравне являться  ,как мужчины так и
женщины."
        />
      </FlexColumn>
    </PageWrapper>
  );
};
